import React, { useEffect, useState, MouseEvent } from 'react';
import { useQueryParam } from 'use-query-params';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FioAccount } from './FioAccount';
import { BankAccount } from './BankAccount';
import { Button, Visible } from '@components';
import { FioTokenModal } from './FioTokenModal';
import { getDateFormat } from '@helpers/datetime';
import { useAuctioneersApi } from '@api/auctioneers';
import { Auctioneer, PermissionGroup, UserPermission } from '@types';

import plusIco from '@assets/images/plus-ico.svg';
import editIco from '@assets/images/edit-ico.svg';
import eyeIco from '@assets/images/color-ico-eye.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import editIcoWhite from '@assets/images/edit-ico-white.svg';
import icoCheckGreen from '@assets/images/ico-check-green.svg';

type Props = {
  id: string;
  showBankAccount: boolean;
  showFioAccount: boolean;
  onNotFound: () => void;
  setShowBankAccount: (val: boolean) => void;
  setShowFioAccount: (val: boolean) => void;
};

export const BankList: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [csasParam, setCsasParam] = useQueryParam('csas');
  const [auctioneer, setAuctioneer] = useState<Auctioneer | undefined>();
  const [fioToken, setFioToken] = useState<string | undefined>();
  const [showFioTokenModal, setShowFioTokenModal] = useState(false);

  useEffect(() => {
    if (csasParam === 'true') {
      setCsasParam(undefined);
      props.setShowBankAccount(true);
    }
    loadAuctioneer();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuctioneer = async () => {
    try {
      const res = await auctioneersApi.detail(props.id);
      setAuctioneer(res.data.data);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      props.onNotFound();
    }
  };

  const handleSave = () => {
    props.setShowBankAccount(false);
    props.setShowFioAccount(false);
    setFioToken(undefined);
    setAuctioneer(undefined);
    loadAuctioneer();
  };

  const handleShowFioTokenClick = (e: MouseEvent) => {
    e.preventDefault();
    setShowFioTokenModal(true);
  };

  const handleDeleteFioToken = async () => {
    if (!auctioneer) {
      return;
    }
    try {
      await auctioneersApi.deleteFioToken(auctioneer.id);
      setAuctioneer(undefined);
      loadAuctioneer();
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  if (!auctioneer) {
    return null;
  }

  if (props.showBankAccount) {
    return (
      <BankAccount
        id={props.id}
        auctioneer={auctioneer}
        onSave={handleSave}
        setShowBankAccount={props.setShowBankAccount}
      />
    );
  }

  if (props.showFioAccount) {
    return (
      <FioAccount
        id={props.id}
        auctioneer={auctioneer}
        onSave={handleSave}
        setShowFioAccount={props.setShowFioAccount}
      />
    );
  }

  return (
    <div>
      <h2>Automatické párování dražebních jistot</h2>
      <Visible permissionGroupName={PermissionGroup.auctioneerInformation} permissionName={UserPermission.canCreate}>
        {/* # TODO: CSAS */}
        {!true && (
          <div>
            <Button className="f-size-12 f-weight-400 mt-3" onClick={() => props.setShowBankAccount(true)}>
              {!auctioneer.auctioneerAccessCsas?.accountId ? (
                <>
                  <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                  <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                  Přidat bankovní účet české spořitelny
                </>
              ) : (
                <>
                  <img src={editIco} alt="ico" className="mr-2 hover-hide" />
                  <img src={editIcoWhite} alt="ico" className="mr-2 hover-show" />
                  Upravit bankovní účet české spořitelny
                </>
              )}
            </Button>
            {!!auctioneer.auctioneerAccessCsas?.accountId && (
              <div className="d-flex mt-4 align-items-center">
                <img src={icoCheckGreen} alt="check-icon" className="mr-3" />
                <span>
                  Bankovní účet české spořitelny propojen
                  <br />
                  Expirace: {getDateFormat(auctioneer.auctioneerAccessCsas?.refreshTokenExpirationDt)}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="mt-3">
          <Button className="f-size-12 f-weight-400 mt-3" onClick={() => props.setShowFioAccount(true)}>
            {!auctioneer.auctioneerAccessFio ? (
              <>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat bankovní účet FIO
              </>
            ) : (
              <>
                <img src={editIco} alt="ico" className="mr-2 hover-hide" />
                <img src={editIcoWhite} alt="ico" className="mr-2 hover-show" />
                Upravit bankovní účet FIO
              </>
            )}
          </Button>

          {!!auctioneer.auctioneerAccessFio && (
            <>
              <div className="d-flex mt-3 align-items-center">
                <img src={icoCheckGreen} alt="check-icon" className="mr-3" />
                <span>
                  Bankovní účet FIO propojen
                  <br />
                  Token: {fioToken || '********'}
                  {!fioToken && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="table-permission">Zobrazit token</Tooltip>}>
                      <a href="/" className="ml-2" onClick={handleShowFioTokenClick}>
                        <img src={eyeIco} alt="fio-token" />{' '}
                      </a>
                    </OverlayTrigger>
                  )}
                </span>
              </div>

              <Button className="btn-md mt-3" onClick={handleDeleteFioToken}>
                Odebrat token
              </Button>
            </>
          )}
        </div>

        {showFioTokenModal && (
          <FioTokenModal
            title="Zobrazení tokenu FIO"
            auctioneer={auctioneer}
            isOpen={showFioTokenModal}
            onRequestClose={() => setShowFioTokenModal(false)}
            onSuccess={(accessToken) => {
              setShowFioTokenModal(false);
              setFioToken(accessToken);
            }}
          />
        )}
      </Visible>
    </div>
  );
};
