import React from 'react';
import classNames from 'classnames';
import { BsPrefixProps } from 'react-bootstrap/esm/helpers';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import icoInfo from '@assets/images/ico-info.svg';

import ControlFeedback from '../ControlFeedback/ControlFeedback';

interface Props extends BsPrefixProps {
  name: string;
  id?: string;
  label?: string;
  error?: string;
  type?: string;
  max?: number;
  min?: number;
  rows?: number;
  isInvalid?: boolean;
  helpText?: React.ReactElement;
  helpIcon?: string;
  thousandSeparator?: boolean;
  value?: string | number | string[];
  required?: boolean;
  inputRequired?: boolean;
  readOnly?: boolean;
  controlOnly?: boolean;
  placeholder?: string;
  autoComplete?: string;
  hideErrorMessage?: boolean;
  className?: string;
  inlineControlClass?: string;
  labelClassName?: string;
  controlClassName?: string;
  dataTestId?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  onValueChange?: (values: NumberFormatValues) => void;
  onChange?: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onFocus?: (event: any) => any;
  onBlur?: (event: any) => any;
}

const FormGroup: React.FC<Props> = (props) => {
  const formControl = props.thousandSeparator ? (
    <>
      <NumberFormat
        id={props.id}
        isNumericString
        thousandSeparator=" "
        decimalSeparator=","
        name={props.name}
        readOnly={props.readOnly}
        required={props.inputRequired}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        value={(props.value !== undefined && props.value !== null ? props.value : '').toString()}
        className={classNames([
          'form-control',
          { 'is-invalid': !!props.error || props.isInvalid },
          props.controlClassName || 'w-max-500',
        ])}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onValueChange={props.onValueChange}
        data-test-id={props.dataTestId}
      />
      {!!props.error && (
        <ControlFeedback type="invalid" dataTestId={`form-input-error-${props.name}`}>
          {props.error}
        </ControlFeedback>
      )}
    </>
  ) : (
    <>
      <Form.Control
        id={props.id}
        required={props.inputRequired}
        as={props.as}
        max={props.max}
        min={props.min}
        rows={props.rows}
        name={props.name}
        isInvalid={!!props.error || props.isInvalid}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        value={props.value !== null ? props.value : ''}
        className={classNames([props.controlClassName || 'w-max-500', { 'help-icon-control': !!props.helpIcon }])}
        onChange={props.onChange}
        readOnly={props.readOnly}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        data-test-id={props.dataTestId}
      />
      {!!props.error && !props.hideErrorMessage && (
        <ControlFeedback type="invalid" dataTestId={`form-input-error-${props.name}`}>
          {props.error}
        </ControlFeedback>
      )}
    </>
  );

  if (props.controlOnly) {
    return formControl;
  }

  return (
    <Form.Group className={classNames(['f-inline-group', props.className])}>
      {props.label && (
        <Form.Label className={classNames(['f-inline-label', props.labelClassName || 'text-right'])}>
          {props.label}
          {props.required ? '\u00A0*' : ''}
          {props.helpText && (
            <OverlayTrigger
              key={`${props.name}-help-text`}
              placement="top"
              overlay={<Tooltip id="table-permission">{props.helpText}</Tooltip>}
            >
              <img src={icoInfo} alt="ico" className="ml-2" />
            </OverlayTrigger>
          )}
        </Form.Label>
      )}
      <div
        className={classNames([
          'f-inline-control',
          { 'help-icon-content': !!props.helpIcon },
          props.inlineControlClass,
        ])}
      >
        {formControl}
        {!!props.helpIcon && <div className="help-icon-value">{props.helpIcon}</div>}
      </div>
    </Form.Group>
  );
};

export default FormGroup;
