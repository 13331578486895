import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  link: string;
  active?: boolean;
  icon: string;
  testId: string;
  iconActive: string;
}

const SidebarMenuItem: React.FC<Props> = (props) => (
  <li className={classNames({ 'item-active': props.active })}>
    <Link to={props.link} data-test-id={props.testId}>
      <span className="icon-content">
        <img src={props.icon} alt="icon" />
      </span>
      <span className="icon-content item-hover">
        <img src={props.iconActive} alt="icon" />
      </span>
      <span className="item-text">{props.children}</span>
    </Link>
  </li>
);

export default SidebarMenuItem;
