import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { useBuyerApi } from '@api/buyer';
import { BuyerAuctionResponse } from '@types';
import { BasePreloader, Button } from '@components';

interface Props {
  auctionId: string;
  buyer: BuyerAuctionResponse;
  onSuccess: (value: boolean) => void;
  getTranslation: (key: string) => string;
}

const PreEmptionPanel: React.FC<Props> = (props) => {
  const buyerApi = useBuyerApi();
  const [saved, setSaved] = useState<boolean>(true);
  const [preEmption, setPreEmption] = useState<boolean>(props.buyer.preEmption);

  useEffect(() => {
    return () => buyerApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setSaved(false);
    try {
      await buyerApi.changePreEmption(props.buyer.user.id || '', props.auctionId, preEmption);
      setSaved(true);
      props.onSuccess(preEmption);
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      setSaved(true);
    }
  };

  return (
    <div className="auction-panel-content pb-3">
      <div>
        <div className="mt-3">
          <Form.Check
            custom
            checked={preEmption}
            type="checkbox"
            id="pre-emption"
            name="pre-emption"
            className="radio-point"
            label={props.getTranslation('tab_pre_emption_label')}
            onChange={(e) => setPreEmption(e.target.checked)}
          />
        </div>
      </div>
      <div className="mt-40 d-flex flex-wrap align-items-center">
        {saved ? (
          <>
            <Button type="button" className="f-size-12 mr-4" onClick={handleSubmit}>
              {props.getTranslation('tab_pre_emption_btn')}
            </Button>
            {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
          </>
        ) : (
          <BasePreloader size={34} className="m-0" />
        )}
      </div>
    </div>
  );
};

export default PreEmptionPanel;
