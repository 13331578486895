import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { AdminProgressFormMenu, BasePage } from '@components';
import { SectionDocuments, SectionSecurity } from './components';
import { urlSearchStore, usePageStore, useUrlSearchStore } from '@stores';

const DocumentsAndSecurityPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const pageState = usePageStore();
  const urlSearchState = useUrlSearchStore();
  const [isLoaded, setIsLoaded] = useState(false);
  const page = pageState.getPage(routes.admin.DOCUMENTS_AND_SECURITY);
  const [query, setQuery] = useQueryParams({
    s: withDefault(NumberParam, 1),
  });

  const navItems = [
    {
      label: 'Dražitelé',
      isActive: query.s === 1,
    },
    {
      label: 'Vrácení jistot',
      isActive: query.s === 2,
    },
  ];

  useEffect(() => {
    if (query.s === 1 && urlSearchState.adminAuctionsDocuments) {
      history.push(`${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}${urlSearchState.adminAuctionsDocuments}`)
    }

    if (query.s === 2 && urlSearchState.adminAuctionsSecurity) {
      history.push(`${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}${urlSearchState.adminAuctionsSecurity}`)
    }

    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.s])

  useEffect(() => {
    if (query.s === 1) {
      urlSearchStore.setState({adminAuctionsDocuments: location.search})
    }
    if (query.s === 2) {
      urlSearchStore.setState({adminAuctionsSecurity: location.search})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const renderContent = () => {
    switch (query.s) {
      case 1:
        return <SectionDocuments />;
      case 2:
        return <SectionSecurity />;
      default:
        return null;
    }
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <BasePage page={routes.admin.DOCUMENTS_AND_SECURITY} title={page?.name}>
      <AdminProgressFormMenu
        items={navItems}
        onClick={(value: any) => {
          setQuery({ s: value }, 'push');
        }}
      />
      {renderContent()}
    </BasePage>
  );
};

export default DocumentsAndSecurityPage;
