import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { StringParam, useQueryParams } from 'use-query-params';

import * as routes from '@routes';
import { BasePreloader } from '@components';
import { useAuthStore, usePageStore } from '@stores';
import { useAuctioneersApi } from '@api/auctioneers';

const OAuthCSAS: React.FC = () => {
  const { addToast } = useToasts();
  const pageState = usePageStore();
  const auctioneersApi = useAuctioneersApi();
  const history = useHistory();
  const authState = useAuthStore();
  const [query] = useQueryParams({
    code: StringParam,
    state: StringParam,
    error: StringParam,
  });

  useEffect(() => {
    if (!!query.error) {
      addToast(query.error, { appearance: 'error', autoDismiss: true });
    }

    if (authState.isLogged && !!query.code && !!query.state) {
      process();
    } else {
      history.push('/');
    }
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const process = async () => {
    try {
      const res = await auctioneersApi.getCSASToken(query.code || '', query.state || '');
      history.push(
        `${pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, {
          ':id': res.data.data.auctioneerId,
        })}?step=7&csas=true`
      );
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      history.push('/');
    }
  };

  return (
    <div className="d-flex vw-100 vh-100 align-items-center justify-content-center">
      <BasePreloader size={50} />
    </div>
  );
};

export default OAuthCSAS;
