import React, { useEffect, useState } from 'react';

import {
  getDaysTranslation,
  getHoursTranslation,
  getMinutesTranslation,
  getSecondsTranslation,
} from '@helpers/translation';
import { useApplicationStore } from '@stores';
import { BasePreloader, Countdown } from '@components';

interface Props {
  date: Date;
  ongoing?: boolean;
  completeText: string;
  onlySeconds?: boolean;
  onComplete?: () => void;
}

const AuctionCountdown: React.FC<Props> = (props) => {
  const [complete, setComplete] = useState<boolean>(false);
  const applicationState = useApplicationStore({ onTick: (time) => handleTimeTick(time) });
  const [currentTime, setCurrentTime] = useState<number | undefined>(applicationState.getTime());

  useEffect(() => {
    if (!!currentTime && props.date.getTime() > currentTime) {
      setComplete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  const handleTimeTick = (time?: number) => setCurrentTime(time);

  if (!currentTime) {
    return null;
  }

  return (
    <Countdown
      date={props.date}
      now={() => currentTime || Date.now()}
      onComplete={() => {
        setComplete(true);
        if (typeof props.onComplete === 'function') {
          props.onComplete();
        }
      }}
      renderer={({ days, hours, minutes, seconds }) => (
        <>
          {!complete ? (
            <>
              {props.onlySeconds ? <>{seconds} {getSecondsTranslation(seconds)}</> : <>{days} {getDaysTranslation(days)}, {hours} {getHoursTranslation(hours)}, {minutes}{' '}
              {getMinutesTranslation(minutes)} a {seconds} {getSecondsTranslation(seconds)}</>}
            </>
          ) : (
            <span className="f-weight-400 d-inline-flex align-items-center ml-2">
              {props.completeText}
              <BasePreloader size={20} className="d-inline-block ml-3" />
            </span>
          )}
        </>
      )}
    />
  );
};

export default AuctionCountdown;
