import React from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useHelpRequestApi } from '@api/helpRequest';

import { BasePreloader, Button, FormGroup } from '@components';

interface Props {
  auctionId: string | number;
}

const AuctionQuestionForm: React.FC<Props> = (props) => {
  const helpRequestApi = useHelpRequestApi();


  const formik = useFormik({
    initialValues: {
      to: 'auctioneer',
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    onSubmit: () => {
      send(props.auctionId);
    },
  });

  const send = async (auctionId: string | number) => {
    try {
      let values = { ...formik.values };
      await helpRequestApi.sendForUser(auctionId, values);
      formik.setSubmitting(false);
      formik.setValues({
        to: formik.values.to,
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (err) {
      if (helpRequestApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <div className="item-question">
      <Form onSubmit={formik.handleSubmit}>
        <p className="destination-label">Kam poslat</p>
        <div className="input-destination">
          <div className="item-input-option">
            <Form.Check
              custom
              name="to"
              type="radio"
              value="auctioneer"
              label="Přímo pořadateli dražby/aukce"
              id="inputDestination2"
              checked={formik.values.to === 'auctioneer'}
              onChange={formik.handleChange}
            />
          </div>
          <div className="item-input-option">
            <Form.Check
              custom
              name="to"
              type="radio"
              value="exdrazby"
              label="Nám - exdražby.cz"
              id="inputDestination1"
              checked={formik.values.to === 'exdrazby'}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className={classNames({ 'mb-30': !!formik.errors.name })}>
          <FormGroup
            required
            type="text"
            name="name"
            label="Jméno a přijmení"
            className="form-group-item"
            error={formik.errors.name}
            controlClassName="input-md"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classNames({ 'mb-30': !!formik.errors.name })}>
          <FormGroup
            required
            type="email"
            name="email"
            label="E-mail"
            className="form-group-item"
            controlClassName="input-md"
            error={formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classNames({ 'mb-30': !!formik.errors.name })}>
          <FormGroup
            required
            type="text"
            name="phone"
            label="Tel.:"
            className="form-group-item"
            controlClassName="input-md"
            error={formik.errors.phone}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classNames({ 'mb-30': !!formik.errors.name })}>
          <FormGroup
            required
            as="textarea"
            type="text"
            name="message"
            label="Vaše zpráva"
            className="form-group-item"
            controlClassName="input-md"
            error={formik.errors.message}
            value={formik.values.message}
            onChange={formik.handleChange}
          />
        </div>
        {!formik.isSubmitting ? <Button type="submit">Odeslat</Button> : <BasePreloader />}
      </Form>
    </div>
  );
};

export default AuctionQuestionForm;
