import React, { useEffect, useState } from 'react';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { useHistory, useParams } from 'react-router-dom';

import icoCloseRed from '@assets/images/ico-close-red.svg';

import * as routes from '@routes';
import { AuctionInformedConsent, AuctionResponse } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { usePageStore, useUrlSearchStore } from '@stores';
import { closeMessage, confirmMessage } from '@helpers/messages';
import OngoingAuctionTab from '@components/OngoingAuctionTab/OngoingAuctionTab';
import {
  BaseAccountPage,
  BasePreloader,
  AuctionTile,
  FrontAccountBox,
  Button,
  InformedConsentsModal,
} from '@components';

import Tabs from './components/Tabs';
import TermsTab from './components/TermsTab';

import alertIco from '@assets/images/front/account/alert.svg';
import AuctionResult from '@components/OngoingAuctionTab/AuctionResult';
import { useAuctionDetailWebsocket } from '@websocket';
import { useWindowFocus } from '@hooks';

export enum AuctionInformationsSections {
  terms,
  process,
  result,
}

interface ParamsType {
  id: string;
}

const AccountAuctionInformations: React.FC = () => {
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const urlSearchState = useUrlSearchStore();
  const auctionsApi = useAuctionsApi();
  const pageState = usePageStore();
  const [query, setQuery] = useQueryParams({ s: withDefault(NumberParam, AuctionInformationsSections.terms) });
  const [auction, setAuction] = React.useState<AuctionResponse>();
  const [authorizationState, setAuthorizationState] = React.useState<string>('');
  const [authorizationStateTranslation, setAuthorizationStateTranslation] = React.useState<string>('');
  const [auctionSecurity, setAuctionSecurity] = React.useState<string>('');
  const [auctionSecurityTranslation, setAuctionSecurityTranslation] = React.useState<string>('');
  const [admitted, setAdmitted] = React.useState<boolean>(false);
  const [preEmption, setPreEmption] = React.useState<boolean>(false);
  const [canLogout, setCanLogoout] = React.useState<boolean>(false);
  const [uniqueCode, setUniqueCode] = React.useState('');
  const [canDraw, setCanDraw] = React.useState<boolean>(false);
  const [hasActiveProtest, setHasActiveProtest] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState(false);
  const [currentStatus, setCurrentStatus] = useState<string | undefined>();
  const [showInformedConsents, setShowInformedConsents] = useState<AuctionInformedConsent[] | undefined>();
  useAuctionDetailWebsocket({
    id,
    onMessage: () => loadAuction(currentStatus),
  });

  useWindowFocus({
    onFocus: () => {
      loadAuction(currentStatus);
    },
  });

  const tabs = [
    {
      text: auction?.translations?.participation_conditions || 'participation_conditions',
      value: AuctionInformationsSections.terms,
    },
    {
      text: auction?.translations?.auction_process_bidding || 'auction_process_bidding',
      value: AuctionInformationsSections.process,
    },
    {
      text: auction?.translations?.auction_result || 'auction_result',
      value: AuctionInformationsSections.result,
    },
  ];

  useEffect(() => {
    window?.scrollTo(0, 0);
    loadAuction();
    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadAuction = async (oldStatus?: string) => {
    try {
      const response = await auctionsApi.getUserAuction(id);
      const data = response.data.data;
      if (!!oldStatus && data.auction.parentStatus === 'ongoing' && oldStatus !== data.auction.parentStatus) {
        setQuery({ ...query, s: AuctionInformationsSections.process }, 'push');
      }

      // Show informed consents
      const informedConsents = data.auctionInformedConsents;
      if (data.auction.parentStatus === 'ongoing' && data.admitted && informedConsents.some((ic) => !ic.approved)) {
        if (!showInformedConsents) {
          setShowInformedConsents(informedConsents);
        }
      } else {
        setShowInformedConsents(undefined);
      }

      setPreEmption(data.preEmption);
      setAuction(data.auction);
      setUniqueCode(data.uniqueCode.toString());
      setAuthorizationState(data.authorizationState);
      setAuthorizationStateTranslation(data.authorizationStateTranslation);
      setAuctionSecurity(data.auctionSecurity);
      setAuctionSecurityTranslation(data.auctionSecurityTranslation);
      setAdmitted(data.admitted);
      setCanDraw(data.canDraw);
      setCanLogoout(data.canLogout);
      setHasActiveProtest(data.hasActiveProtest);
      setCurrentStatus(data.auction.parentStatus);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleItemDelete = () => {
    confirmMessage({
      title: 'Potvrzení',
      confirmButtonText: 'Opustit',
      text: 'Opravdu si přejete opustit tuto dražbu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctionsApi.leaveAuction(auction?.id || '').catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        history.push(`${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}${urlSearchState.frontAccountAuctions}`);
      }
    });
  };

  return (
    <BaseAccountPage
      disableScrollToTop
      page={routes.front.ACCOUNT_AUCTIONS}
      className="page-account-auction-informations"
    >
      {!!showInformedConsents && auction?.id && (
        <InformedConsentsModal
          auctionId={auction.id}
          informedConsents={showInformedConsents}
          onSuccess={() => loadAuction(auction?.parentStatus)}
        />
      )}

      <div className="component-front-auctions-list mt-0">
        <div className="list-items">
          {loaded ? (
            <AuctionTile
              auction={auction as AuctionResponse}
              isPersonalAccount
              primaryActionText={
                canLogout && (
                  <>
                    <img src={icoCloseRed} alt="delete" />
                    <span style={{ marginLeft: '12px' }}>
                      {auction?.translations?.auction_logout_btn || 'auction_logout_btn'}
                    </span>
                  </>
                )
              }
              primaryActionVariant="btn-none"
              onPrimaryActionClick={handleItemDelete}
              reload={() => setTimeout(() => loadAuction(auction?.parentStatus), 1000)}
              disableFooter
              isVerbose
            />
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </div>
      </div>

      {loaded && (
        <>
          <div className="informations-content">
            <Tabs
              items={tabs}
              value={query.s}
              onClick={(tab) =>
                setQuery(
                  { ...query, s: tab.value === AuctionInformationsSections.terms ? undefined : tab.value },
                  'push'
                )
              }
            />
            {query.s === AuctionInformationsSections.terms && (
              <FrontAccountBox>
                <TermsTab
                  auction={auction}
                  auctionSecurity={auctionSecurity}
                  auctionSecurityTranslation={auctionSecurityTranslation}
                  authorizationState={authorizationState}
                  authorizationStateTranslation={authorizationStateTranslation}
                />
              </FrontAccountBox>
            )}
            {query.s === AuctionInformationsSections.process && !!auction && (
              <OngoingAuctionTab
                auction={auction}
                admitted={admitted}
                preEmption={preEmption}
                canDraw={canDraw}
                uniqueCode={uniqueCode}
                hasActiveProtest={hasActiveProtest}
                authorizationState={authorizationState}
                auctionReload={() => loadAuction(auction?.parentStatus)}
              />
            )}
            {query.s === AuctionInformationsSections.result && !!auction && (
              <>
                {auction.parentStatus === 'ended' || auction.parentStatus === 'overcharge' ? (
                  <AuctionResult auction={auction} />
                ) : (
                  <FrontAccountBox>
                    <div className="component-auction-process-tab">
                      <div className="prepared-auction-alert">
                        <img src={alertIco} alt="ico" className="item-ico" />
                        {auction.translations?.auction_not_ended || 'auction_not_ended'}
                      </div>
                    </div>
                  </FrontAccountBox>
                )}
              </>
            )}
          </div>
          <div className="mt-4">
            <Button
              grayBg
              to={`${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}${urlSearchState.frontAccountAuctions}`}
            >
              Vrátit se zpět
            </Button>
          </div>
        </>
      )}
    </BaseAccountPage>
  );
};

export default AccountAuctionInformations;
