import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import { PermissionGroup, User, UserPermission } from '@types';
import { useAdministratorApi } from '@api/administrator';
import { usePageStore } from '@stores';
import * as routes from '@routes';
import { Pagination, BasePreloader, Button, Visible } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import ItemsTable from './AdministratorsTable';

const Administrators: React.FC = () => {
  const administratorApi = useAdministratorApi();
  const pageState = usePageStore();
  const location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<Array<User>>([]);
  const [dataLen, setDataLen] = React.useState(0);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
  });

  React.useEffect(() => {
    loadData();
    return () => administratorApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const response = await administratorApi.list(query);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err: any) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleDeleteClick = (item: User) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tohoto administrátora?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return administratorApi.delete(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleRestoreClick = (item: User) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete obnovit tohoto administrátora?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return administratorApi.restore(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleReinviteClick = (item: User) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete tomuto administrátorovi znovu zaslat aktivačí odkaz?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return administratorApi.restore(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  return (
    <div>
      <div>
        <h2>Administrátoři</h2>
      </div>
      <Row className="align-items-center">
        <Col xs={12} lg={6}>
          <Visible permissionGroupName={PermissionGroup.administrator} permissionName={UserPermission.canCreate}>
            <Button to={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS_CREATE)} className="mt-3" dataTestId="admin-settings-administrators-create-btn">
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat administrátora
            </Button>
          </Visible>
        </Col>
        <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
          {loaded && (
            <p className="m-0">
              Celkem <strong>{dataLen}</strong> uživatelů
            </p>
          )}
        </Col>
      </Row>
      <Row className="mt-3 pt-3">
        <Col xs={12}>
          {loaded ? (
            <div>
              <div>
                <ItemsTable
                  data={data}
                  onDeleteClick={handleDeleteClick}
                  onRestoreClick={handleRestoreClick}
                  onReinviteClick={handleReinviteClick}
                />
              </div>
              {data.length > 0 && (
                <div className="mt-2">
                  <Pagination
                    page={query.page}
                    perPage={query.perPage}
                    totalRows={dataLen}
                    onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Administrators;
