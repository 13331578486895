import React, { useEffect, useState } from 'react';
import { BasePreloader, Button } from '@components';

import { AuctionResponse } from '@types';
import { useAuctionProtestApi } from '@api/auctionProtest';
import { useFormik } from 'formik';

type Props = {
  auction: AuctionResponse;
  auctionReload: () => void;
};

export const AuctionProtest: React.FC<Props> = (props) => {
  const auctionProtestApi = useAuctionProtestApi();
  const [showPreloader, setShowPreloader] = useState(false);
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    initialValues: {
      message: '',
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => auctionProtestApi.cancelAllRequests(), []);

  const handleSubmit = async () => {
    if (showPreloader) {
      return;
    }
    setShowPreloader(true);
    try {
      await auctionProtestApi.saveProtest(props.auction.id, { ...formik.values });
      props.auctionReload();
    } catch (err: any) {
      if (auctionProtestApi.isCancel(err)) {
        return;
      }
      setShowPreloader(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h3 className="tab-title">{props.auction.translations?.auction_protest_title || 'auction_protest_title'}</h3>
      <div className="w-max-500 mt-3">
        <textarea
          rows={5}
          name="message"
          value={formik.values.message}
          className="form-control noresize"
          placeholder={props.auction.translations?.auction_protest_placeholder || 'auction_protest_placeholder'}
          onChange={formik.handleChange}
        ></textarea>
        {showPreloader ? (
          <div className="mt-4">
            <BasePreloader size={25} />
          </div>
        ) : (
          <Button type="submit" className="mt-4">{props.auction.translations?.auction_protest_btn || 'auction_protest_btn'}</Button>
        )}
      </div>
    </form>
  );
};
