import { Col, Row } from 'react-bootstrap';

// import { OurTeam } from './OurTeam';
import { ContactUs } from './ContactUs';
import { ContactCard } from './ContactCard';

import iconCompany from '@assets/images/front/contact/ico-contact-company.svg';
import iconEnvelope from '@assets/images/front/contact/ico-contact-envelope.svg';
import iconFollow from '@assets/images/front/contact/ico-contact-follow.svg';
import iconLocation from '@assets/images/front/contact/ico-contact-location.svg';
import iconPhone from '@assets/images/front/contact/ico-contact-phone.svg';
import MapComponent from '@components/MapComponent/MapComponent';

export const Contact = () => (
  <div className="component-front-contact">
    <h1>Kontakt</h1>
    <Row>
      <Col lg={6} sm={12}>
        <Row className="h-100">
          <ContactCard icon={iconEnvelope}>
            <p>Napište nám</p>
            <a href="mailto:info@exdrazby.cz">info@exdrazby.cz</a>
          </ContactCard>
          <ContactCard icon={iconPhone}>
            <p>Zavolejte nám</p>
            <a href="tel:+420774740636">+420 774 740 636</a>
            <p className="span-text">K dispozici Po-Čt 9:00-16:00 Pá 9:00-12:00</p>
          </ContactCard>
          <ContactCard icon={iconLocation}>
            <p>Navšivte nás</p>
            <p className="strong-text">
              Šrobárova 2100/49,
              <br /> 130 00 Praha 3 - Vinohrady
            </p>
          </ContactCard>
          <ContactCard icon={iconFollow}>
            <p>Sledujte nás</p>
            <div>
              <a href="https://www.facebook.com/Exdrazby.cz" target="_blank" rel="noreferrer">
                Facebook
              </a>
              <span>, </span>
              <a href="https://www.instagram.com/exdrazby.cz/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </div>
          </ContactCard>
        </Row>
      </Col>
      <Col lg={6} sm={12}>
        <Row className="h-100">
          <ContactCard icon={iconCompany} prDisabled>
            <p>Firma</p>
            <p className="strong-text">JURIS REAL Dražby, a.s.</p>
            <p className="mt-4">IČ: 24140384</p>
            <p className="mt-4">vedená u rejstříkového soudu v Praze pod spisovou značkou B 17354</p>
            <p className="mt-4">Portál je registrován u ÚOOÚ pod č. 00042980</p>
            <p className="strong-text mb-0 mt-4">Datová schránka</p>
            <p>zsi4qxv</p>
          </ContactCard>
          <Col md={6} sm={12} className="mb-4 pl-disabled">
            <MapComponent colors pin={{ lat: 50.076444794037435, lng: 14.473456569429182 }} zoom={20} />
          </Col>
        </Row>
      </Col>
    </Row>

    {/*TODO: Our team*/}
    {/*<OurTeam />*/}

    <ContactUs />
  </div>
);
