import { useGridFilter } from '@stores';
import { API, APIResponse, APIResponseCustom, useApi } from '@api/api';
import {
  BaseResponse,
  HistoryType,
  InvoicePDFResponse,
  InvoiceResponse,
  InvoicesFilters,
  PaymentResponse,
  PaymentsFilters,
  PaymentTypesResponse,
  PaymentUpdateData,
  Supplier,
} from '@types';

export const supplierPath = () => `/admin/invoice/supplier`;
export const invoicesPath = () => '/admin/invoice/auction';
export const invoiceEditPath = (auctionInvoiceId: number | string) => `/admin/invoice/auction/${auctionInvoiceId}`;
export const invoicePDFPath = (auctionInvoiceId: number | string) => `/admin/invoice/auction/${auctionInvoiceId}/pdf`;
export const invoiceHistoryPath = (auctionInvoiceId: number | string) =>
  `/admin/invoice/auction/${auctionInvoiceId}/history`;
export const exportByInvoiceNumber = (format: string) => `/admin/invoice/auction/invoice-number/${format}`;
export const exportByInvoiceDate = (format: string) => `/admin/invoice/auction/year-month/${format}`;
export const exportByInvoiceDateRange = (format: string) => `/admin/invoice/auction/date/${format}`;
export const getPaymentsPath = () => `/admin/payment`;
export const getPaymentPath = (id: string) => `/admin/payment/${id}`;
export const getPaymentTypesPath = () => `/enum/auction/payment-type`;

type InvoicesResponseBase = BaseResponse<InvoiceResponse[]> & {
  invoicePaid: string;
  invoiceNotPaid: string;
};

export interface InvoiceAPI extends API {
  getInvoices(params: InvoicesFilters): APIResponseCustom<InvoicesResponseBase>;
  getHistory(auctionInvoiceId: number | string, perPage?: number, page?: number): APIResponse<HistoryType[]>;
  updateInvoice(auctionInvoiceId: string | number, data: Partial<InvoiceResponse>): APIResponse;
  getSupplier(): APIResponse<Supplier>;
  updateSupplier(data: Partial<Supplier>): APIResponse<Supplier>;
  getInvoicePDF(auctionInvoiceId: string | number): APIResponse<InvoicePDFResponse>;
  detailInvoice(auctionInvoiceId: string | number): APIResponse<InvoiceResponse>;
  getPayments(params: PaymentsFilters): APIResponse<PaymentResponse[]>;
  getPaymentTypes(): APIResponse<PaymentTypesResponse[]>;
  updatePayment(id: string, data: PaymentUpdateData): APIResponse;
  exportByInvoiceNumber(
    format: string,
    invoiceNumberMin: number,
    invoiceNumberMax: number,
    auctioneerId?: number
  ): APIResponse<InvoicePDFResponse>;
  exportByInvoiceDate(
    format: string,
    year: number,
    month: number,
    auctioneerId?: number
  ): APIResponse<InvoicePDFResponse>;
  exportByInvoiceDateRange(
    format: string,
    ymdMin: string,
    ymdMax: string,
    auctioneerId?: number
  ): APIResponse<InvoicePDFResponse>;
}

export const useInvoiceApi = (): InvoiceAPI => {
  const api = useApi();

  const invoicesFilter = useGridFilter<InvoicesFilters>([
    {
      key: 'paid',
      type: 'boolean',
      property: 'auctionInvoice.paid',
    },
    {
      key: 'invoiceNumber',
      operator: 'like',
      property: 'auctionInvoice.invoiceNumber',
    },
    {
      type: 'int',
      key: 'auctioneer',
      operator: 'eq',
      property: 'auctioneer.id',
    },
    {
      type: 'int',
      key: 'auction',
      operator: 'eq',
      property: 'auction.id',
    },
    {
      key: 'enforcement',
      type: 'boolean',
      property: 'auctionInvoice.enforcement',
    },
    {
      type: 'string',
      key: 'auctioneerType',
      property: 'auctioneer.type',
    },
    {
      type: 'date',
      operator: 'gte',
      key: 'dateOfIssueFrom',
      property: 'auctionInvoice.dateOfIssue',
    },
    {
      type: 'date',
      operator: 'lte',
      key: 'dateOfIssueTo',
      property: 'auctionInvoice.dateOfIssue',
    },
  ]);

  const paymentsFilter = useGridFilter<InvoicesFilters>([
    {
      key: 'senderName',
      type: 'string',
      operator: 'like',
      property: 'payment.senderName',
    },
    {
      key: 'paymentType',
      type: 'string',
      property: 'payment.paymentType',
    },
    {
      group: 'variableSymbol',
      key: 'variableSymbol',
      type: 'string',
      operator: 'like',
      property: 'payment.variableSymbol',
    },
    {
      group: 'variableSymbol',
      key: 'variableSymbol',
      type: 'string',
      operator: 'like',
      property: 'payment.variableSymbolOwn',
    },
    {
      type: 'string',
      operator: 'like',
      key: 'senderAccountNumber',
      property: 'payment.senderAccountNumber',
    },
  ]);

  return {
    ...api,
    getInvoices(
      params: InvoicesFilters,
      sortField: string = 'auctionInvoice.id',
      sortDirection: 'asc' | 'desc' = 'desc'
    ) {
      const cancelToken = api.prepareCancelToken('getLogs');
      return api.authRequest.get(invoicesPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          filter: invoicesFilter.isEmpty(params) ? undefined : invoicesFilter.toString(params),
          sort: JSON.stringify({
            property: sortField,
            direction: sortDirection,
          }),
        },
      });
    },
    getHistory(auctionInvoiceId: number | string, perPage?: number, page?: number) {
      const cancelToken = api.prepareCancelToken('getHistory');
      return api.authRequest.get(invoiceHistoryPath(auctionInvoiceId), {
        cancelToken,
        params: {
          page: page,
          perPage: perPage,
          sort: JSON.stringify({ property: 'id', direction: 'DESC' }),
        },
      });
    },
    updateInvoice(auctionInvoiceId: number | string, data: Partial<InvoiceResponse>) {
      const cancelToken = api.prepareCancelToken('updateInvoice');
      return api.authRequest.put(invoiceEditPath(auctionInvoiceId), data, {
        cancelToken,
      });
    },
    getSupplier() {
      const cancelToken = api.prepareCancelToken('getSupplier');
      return api.authRequest.get(supplierPath(), { cancelToken });
    },
    updateSupplier(data: Partial<Supplier>) {
      const cancelToken = api.prepareCancelToken('updateSupplier');
      return api.authRequest.put(supplierPath(), data, { cancelToken });
    },
    getInvoicePDF(auctionInvoiceId: number | string) {
      const cancelToken = api.prepareCancelToken('getInvoicePDF');
      return api.authRequest.get(invoicePDFPath(auctionInvoiceId), { cancelToken });
    },
    exportByInvoiceNumber(format: string, invoiceNumberMin: number, invoiceNumberMax: number, auctioneerId: number) {
      const cancelToken = api.prepareCancelToken('exportByInvoiceNumber');
      return api.authRequest.get(exportByInvoiceNumber(format), {
        cancelToken,
        params: {
          invoiceNumberMin,
          invoiceNumberMax,
          auctioneerId: auctioneerId || undefined,
        },
      });
    },
    exportByInvoiceDate(format: string, year: number, month: number, auctioneerId: number) {
      const cancelToken = api.prepareCancelToken('exportByInvoiceDate');
      return api.authRequest.get(exportByInvoiceDate(format), {
        cancelToken,
        params: {
          year,
          month,
          auctioneerId: auctioneerId || undefined,
        },
      });
    },
    exportByInvoiceDateRange(format: string, ymdMin: string, ymdMax: string, auctioneerId: number) {
      const cancelToken = api.prepareCancelToken('exportByInvoiceDateRange');
      return api.authRequest.get(exportByInvoiceDateRange(format), {
        cancelToken,
        params: {
          ymdMin,
          ymdMax,
          auctioneerId: auctioneerId || undefined,
        },
      });
    },
    detailInvoice(auctionInvoiceId: number | string) {
      const cancelToken = api.prepareCancelToken('detailInvoice');
      return api.authRequest.get(invoiceEditPath(auctionInvoiceId), {
        cancelToken,
      });
    },
    getPayments(params: PaymentsFilters) {
      const cancelToken = api.prepareCancelToken('getPayments');
      return api.authRequest.get(getPaymentsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          filter: paymentsFilter.isEmpty(params) ? undefined : paymentsFilter.toString(params),
        },
      });
    },
    getPaymentTypes() {
      const cancelToken = api.prepareCancelToken('getPaymentTypes');
      return api.authRequest.get(getPaymentTypesPath(), { cancelToken });
    },
    updatePayment(id: string, data: PaymentUpdateData) {
      const cancelToken = api.prepareCancelToken('updatePayment');
      return api.authRequest.put(getPaymentPath(id), data, { cancelToken });
    },
  };
};
