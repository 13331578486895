import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { BasePreloader, Button, FormGroup } from '@components';
import { useAuctioneersApi } from '@api/auctioneers';
import { useFormik } from 'formik';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Licitator: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [isLoaded, setIsLoaded] = useState(false);
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    validateOnChange: false,
    initialValues: {
      name: '',
      address: '',
    },
  });

  useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const res = await auctioneersApi.licitatorDetail(props.id);
      await formik.setValues({
        name: res.data.data?.name || '',
        address: res.data.data?.address || '',
      });
      setIsLoaded(true);
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await auctioneersApi.licitatorUpdate(props.id, formik.values);
      formik.setSubmitting(false);
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  if (!isLoaded) {
    return (
      <div>
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>Licitátor</h2>
      </div>
      <Row>
        <Col xs={12}>
          {isLoaded ? (
            <div>
              <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
                <div className="responsive-table-content">
                  <div>
                    <FormGroup
                      dataTestId="licatorName"
                      type="text"
                      name="name"
                      label="Jméno:"
                      labelClassName="text-left"
                      value={formik.values.name}
                      error={formik.errors.name}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      dataTestId="licitatorAddress"
                      type="text"
                      name="address"
                      label="Adresa:"
                      labelClassName="text-left"
                      value={formik.values.address}
                      error={formik.errors.address}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <Row>
                  <Col xs={12} className="mt-4 text-right">
                    {!formik.isSubmitting ? (
                      <Button dataTestId="licitatorSubmit" variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                        Uložit
                      </Button>
                    ) : (
                      <BasePreloader size={29} className="d-inline-block" />
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Licitator;
