import { Country, Region, District } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const locationCountriesPath = () => `/location/country`;
export const locationRegionsPath = () => `/location/region`;
export const locationDistrictsPath = () => `/location/district`;

export interface LocationAPI extends API {
  getCountries(): APIResponse<Array<Country>>;
  getRegions(countryId?: number | string): APIResponse<Array<Region>>;
  getDistricts(regionId?: number | string): APIResponse<Array<District>>;
}

export const useLocationApi = (): LocationAPI => {
  const api = useApi();

  return {
    ...api,
    getCountries() {
      const cancelToken = api.prepareCancelToken('getLocationCountries');
      return api.request.get(locationCountriesPath(), { cancelToken });
    },
    getRegions(countryId?: number | string) {
      const cancelToken = api.prepareCancelToken('getLocationRegions');
      return api.request.get(locationRegionsPath(), { cancelToken, params: { 'country-id': countryId } });
    },
    getDistricts(regionId?: number | string) {
      const cancelToken = api.prepareCancelToken('getLocationDistricts');
      return api.request.get(locationDistrictsPath(), { cancelToken, params: { 'region-id': regionId } });
    },
  };
};
