import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';
import _ from 'lodash';

import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, FormGroup, Button } from '@components';
import { AuctioneerTemplate, BaseObjectType } from '@types';
import { useTranslatorApi } from '@api/translator';
import TemplatesSettings from './TemplateSettings';
import TemplatesAddress from './TemplatesAddress';
import TemplatesInformations from './TemplatesInformations';
import { useSettingsInformationsApi } from '@api/settingsInformations';
import { useInformedConsentApi } from '@api/informedConsent';
import TemplatesInformedConsents from './TemplatesInformedConsents';

interface Props {
  id: string;
  auctionTemplateId?: string;
  default?: boolean;
  name?: string;
  auctionType?: string;
  updatedItem?: AuctioneerTemplate;
  setUpdateItem: React.Dispatch<React.SetStateAction<AuctioneerTemplate | undefined>>;
  onSubmit: () => void;
  onClose: () => void;
}

const TemplatesForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const translationsApi = useTranslatorApi();
  const informedConsentApi = useInformedConsentApi();
  const settingsInformationsApi = useSettingsInformationsApi();
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false);
  const [translations, setTranslations] = React.useState({
    auction: {},
    public: {},
  });
  const [domainTranslations, setDomainTranslations] = React.useState<BaseObjectType<string>>(translations.auction);

  const data = props.updatedItem?.data ? JSON.parse(props.updatedItem.data) : {};
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      name: props.name || '',
      isDefault: props.default || false,
      auctionType: props.auctionType || 'auction',
      data: {
        auctionAuthorizationsType: data.auctionAuthorizationsType || ([] as string[]),
        auctionCautionCash: { enabled: data.auctionCautionCash?.enabled || false },
        auctionCautionBankGuarantee: { enabled: data.auctionCautionBankGuarantee?.enabled || false },
        auctionCautionBankAccount: {
          buyerIdentifier: data.auctionCautionBankAccount?.buyerIdentifier || 'vs',
          enabled: data.auctionCautionBankAccount?.enabled || false,
          bankAccountNumber: data.auctionCautionBankAccount?.bankAccountNumber || '',
          bankAccountCode: data.auctionCautionBankAccount?.bankAccountCode || '',
          variableSymbolType: data.auctionCautionBankAccount?.variableSymbolType || '',
          variableSymbol: data.auctionCautionBankAccount?.variableSymbol || '',
          specificSymbolType: data.auctionCautionBankAccount?.specificSymbolType || '',
          specificSymbol: data.auctionCautionBankAccount?.specificSymbol || '',
        },
        addressCountry: data.addressCountry || '',
        addressRegion: data.addressRegion || '',
        addressDistrict: data.addressDistrict || '',
        addressCity: data.addressCity || '',
        addressZipCode: data.addressZipCode || '',
        addressStreet: data.addressStreet || '',
        addressHouseNumber: data.addressHouseNumber || '',
        addressCadastralArea: data.addressCadastralArea || '',
        addressLandNumber: data.addressLandNumber || '',
        addressRuian: data.addressRuian || '',
        addressGps: data.addressGps || '',
        informations: data.informations?.length > 0 ? data.informations : [],
        informedConsents: data.informedConsents?.length > 0 ? data.informedConsents : [],
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    if (!props.auctionTemplateId) {
      loadData();
    }
    return () => {
      auctioneersApi.cancelAllRequests();
      informedConsentApi.cancelAllRequests();
      settingsInformationsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    formik.values.auctionType === 'auction'
      ? setDomainTranslations(translations.auction)
      : setDomainTranslations(translations.public);
  }, [formik.values.auctionType, translations]);

  React.useEffect(() => {
    loadTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const resInformation = await settingsInformationsApi.list();
      const resInformedConsents = await informedConsentApi.getDefaults();
      await formik.setFieldValue('data.informations', resInformation.data.data);
      await formik.setFieldValue(
        'data.informedConsents',
        resInformedConsents.data.data.map((v) => ({
          ...v,
          enabled: true,
        }))
      );
    } catch (err) {
      if (settingsInformationsApi.isCancel(err) || informedConsentApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadTranslations = async () => {
    try {
      setTranslations({
        public: (await translationsApi.domainTranslations('admin_auction_public_form')).data.data,
        auction: (await translationsApi.domainTranslations('admin_auction_form')).data.data,
      });
      setIsTranslationsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const inputs = { ...values };
    let response: any;
    try {
      !!props.auctionTemplateId
        ? (response = await auctioneersApi.updateTemplate(
            inputs.name,
            inputs.auctionType,
            inputs.isDefault,
            inputs.data,
            props.id,
            props.auctionTemplateId
          ))
        : (response = await auctioneersApi.createTemplate(inputs.name, inputs.auctionType, inputs.data, props.id));

      formik.setSubmitting(false);
      props.setUpdateItem(response.data.data);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  function handleAuctionType(value: string) {
    formik.setFieldValue('auctionType', value);
  }

  const getTranslation = (key: string) => _.get(domainTranslations, key, key);

  const renderFormContent = () => {
    if (!props.updatedItem) {
      return (
        <div className="responsive-table-content">
          <div className="pt-1">
            <FormGroup
              required
              type="text"
              name="name"
              label="Zadejte název šablony"
              labelClassName="text-left nowrap mr-3"
              error={formik.errors.name}
              value={formik.values.name || ''}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zpět
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit a pokračovat v editaci šablony
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="responsive-table-content">
          <div className="pt-1">
            <FormGroup
              required
              type="text"
              name="name"
              label="Název"
              labelClassName="text-left mr-0 "
              error={formik.errors.name}
              value={formik.values.name || ''}
              onChange={formik.handleChange}
            />
          </div>
          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label mb-0">Typ aukce/dražby</Form.Label>
            <div className="f-inline-control d-flex align-items-center settings-group">
              <Form.Check
                custom
                type="radio"
                label="Aukce"
                className="mr-4 radio-point"
                id="auctionTypeAuction"
                name="auctionType"
                checked={formik.values.auctionType === 'auction'}
                onChange={() => handleAuctionType('auction')}
              />
              <Form.Check
                custom
                type="radio"
                label="Dražba"
                className="mr-4 radio-point"
                id="auctionTypePublic"
                name="auctionType"
                checked={formik.values.auctionType === 'auction_public'}
                onChange={() => handleAuctionType('auction_public')}
              />
            </div>
          </Form.Group>
          <TemplatesSettings
            id={props.id}
            values={formik.values}
            errors={formik.errors}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            isSubmitting={formik.isSubmitting}
          />
          <TemplatesAddress
            id={props.id}
            values={formik.values}
            errors={formik.errors}
            getTranslation={getTranslation}
            setValues={formik.setValues}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            isSubmitting={formik.isSubmitting}
          />
        </div>
        <div className="mt-5">
          {!props.auctionTemplateId && (
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zpět
            </Button>
          )}
          {!formik.isSubmitting ? (
            <Button type="submit" variant="btn-outline-primary" className="float-right" disabled={formik.isSubmitting}>
              Uložit šablonu
            </Button>
          ) : (
            <BasePreloader size={29} className="d-inline-block float-right" />
          )}
          <div className="clearfix" />
        </div>
        {props.auctionTemplateId && (
          <TemplatesInformations
            id={props.id}
            values={formik.values}
            errors={formik.errors}
            updatedItem={props.updatedItem}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            isSubmitting={formik.isSubmitting}
            auctionTemplateId={props.auctionTemplateId}
          />
        )}
        {props.auctionTemplateId && (
          <TemplatesInformedConsents
            id={props.id}
            values={formik.values}
            errors={formik.errors}
            updatedItem={props.updatedItem}
            getTranslation={getTranslation}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            isSubmitting={formik.isSubmitting}
            auctionTemplateId={props.auctionTemplateId}
          />
        )}
        {props.auctionTemplateId && (
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zpět
            </Button>
            <div className="clearfix" />
          </div>
        )}
      </>
    );
  };

  if (!isTranslationsLoaded) {
    return null;
  }

  return (
    <div>
      <h2>{!!props.auctionTemplateId ? 'Upravit šablonu' : 'Přidat šablonu'}</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          {renderFormContent()}
        </Form>
      </div>
    </div>
  );
};

export default TemplatesForm;
