import { EnumType } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const getStatesPath = () => `/enum/general/states`;
export const getCitizenshipPath = () => `/enum/user/citizenship`;
export const getPaymentTypesPath = () => `/enum/auction/payment-type`;
export const getExportPreviewImagesPath = () => `/enum/auction-export/auction-export-preview-images`;
export const getAuctionAuthorizationStatePath = () => `/enum/user/auction-authorization-state`;

export interface EnumAPI extends API {
  getStates(): APIResponse<EnumType[]>;
  getPaymentTypes(): APIResponse<EnumType[]>;
  getExportPreviewImages(): APIResponse<EnumType[]>;
  getCitizenship(): APIResponse<EnumType[]>;
  getAuctionAuthorizationState(): APIResponse<EnumType[]>;
}

export const useEnumApi = (): EnumAPI => {
  const api = useApi();

  return {
    ...api,
    getStates() {
      const cancelToken = api.prepareCancelToken('getStates');
      return api.request.get(getStatesPath(), { cancelToken });
    },
    getPaymentTypes() {
      const cancelToken = api.prepareCancelToken('getPaymentTypes');
      return api.request.get(getPaymentTypesPath(), { cancelToken });
    },
    getExportPreviewImages() {
      const cancelToken = api.prepareCancelToken('getExportPreviewImages');
      return api.request.get(getExportPreviewImagesPath(), { cancelToken });
    },
    getCitizenship() {
      const cancelToken = api.prepareCancelToken('getCitizenship');
      return api.request.get(getCitizenshipPath(), { cancelToken });
    },
    getAuctionAuthorizationState() {
      const cancelToken = api.prepareCancelToken('getAuctionAuthorizationState');
      return api.request.get(getAuctionAuthorizationStatePath(), { cancelToken });
    },
  };
};
