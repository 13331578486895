import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { AuctionImageCreate, AuctionImageResponse, NewsType, PaginationParams } from '@types';

export const newsPath = () => `/admin/news`;

export const webNewsPath = () => `/web/news`;

export const webNewPath = (slug: string) => `/web/news/${slug}`;

export const webNewsInterestedPath = (newsId: number | string) => `/web/news/${newsId}/interested`;

export const newsCreatePath = () => `/admin/news/new`;

export const newPath = (newsId: number | string) => `/admin/news/${newsId}`;

export const newsArchivePath = (newsId: number | string) => `/admin/news/${newsId}/archive`;

export const newsActivatePath = (newsId: number | string) => `/admin/news/${newsId}/activate`;

export const newsMediaPath = (newsId: number | string) => `/admin/news/${newsId}/media`;

export const newsMediaPositionPath = (newsId: number | string) => `/admin/news/${newsId}/media/position`;

export const newsMediaRemovePath = (newsId: number | string, newsMediaId: number | string) =>
  `/admin/news/${newsId}/media/${newsMediaId}`;

export interface NewsAPI extends API {
  newsList(params: PaginationParams): APIResponse<Array<NewsType>>;
  createNews(data: Partial<NewsType>): APIResponse;
  updateNews(newsId: number | string, data: Partial<NewsType>): APIResponse;
  detailNews(newsId: number | string): APIResponse<NewsType>;
  archiveNews(newsId: number | string): APIResponse;
  activateNews(newsId: number | string): APIResponse;
  newsMediaList(newsId: number | string): BaseAPIResponse<AuctionImageResponse[]>;
  createNewsImage(newsId: string | number, data: AuctionImageCreate): APIResponse;
  reorderNewsImages(
    newsId: string | number,
    imagesOrder: Array<{ newsMediaId: string | number; position: number }>
  ): APIResponse;
  deleteNewsImage(newsId: string | number, newsMediaId: string | number): APIResponse;
  webNewsList(params: PaginationParams): APIResponse<Array<NewsType>>;
  webDetailNews(slug: string): APIResponse<NewsType>;
  webNewsInterested(newsId: number | string): APIResponse<Array<NewsType>>;
}

export const useNewsApi = (): NewsAPI => {
  const api = useApi();

  return {
    ...api,
    newsList(params: PaginationParams) {
      const cancelToken = api.prepareCancelToken('newsList');
      return api.authRequest.get(newsPath(), { params, cancelToken });
    },
    createNews(data: Partial<NewsType>) {
      const cancelToken = api.prepareCancelToken('createNews');
      return api.authRequest.post(newsCreatePath(), data, { cancelToken });
    },
    updateNews(newsId: number | string, data: Partial<NewsType>) {
      const cancelToken = api.prepareCancelToken('updateNews');
      return api.authRequest.put(newPath(newsId), data, { cancelToken });
    },
    detailNews(newsId: number | string) {
      const cancelToken = api.prepareCancelToken('detailNews');
      return api.authRequest.get(newPath(newsId), { cancelToken });
    },
    archiveNews(newsId: number | string) {
      const cancelToken = api.prepareCancelToken('archiveNews');
      return api.authRequest.delete(newsArchivePath(newsId), { cancelToken });
    },
    activateNews(newsId: number | string) {
      const cancelToken = api.prepareCancelToken('activateNews');
      return api.authRequest.put(newsActivatePath(newsId), { cancelToken });
    },
    newsMediaList(newsId: number | string) {
      const cancelToken = api.prepareCancelToken('newsMediaList');
      return api.authRequest.get(newsMediaPath(newsId), { cancelToken });
    },
    createNewsImage(newsId: string | number, data: AuctionImageCreate) {
      const cancelToken = api.prepareCancelToken('createNewsImage');
      return api.authRequest.post(newsMediaPath(newsId), data, { cancelToken });
    },
    reorderNewsImages(newsId: string | number, imagesOrder: Array<{ newsMediaId: string | number; position: number }>) {
      const cancelToken = api.prepareCancelToken('reorderNewsImages');
      return api.authRequest.put(newsMediaPositionPath(newsId), imagesOrder, { cancelToken });
    },
    deleteNewsImage(newsId: string | number, imageId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteNewsImage');
      return api.authRequest.delete(newsMediaRemovePath(newsId, imageId), { cancelToken });
    },
    webNewsList(params: PaginationParams) {
      const cancelToken = api.prepareCancelToken('webNewsList');
      return api.authRequest.get(webNewsPath(), { params, cancelToken });
    },
    webDetailNews(slug: string) {
      const cancelToken = api.prepareCancelToken('webDetailNews');
      return api.authRequest.get(webNewPath(slug), { cancelToken });
    },
    webNewsInterested(newsId: number | string) {
      const cancelToken = api.prepareCancelToken('webNewsInterested');
      return api.authRequest.get(webNewsInterestedPath(newsId), { cancelToken });
    },
  };
};
