import React from 'react';
import cx from 'classnames';
import { FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';

import { BuyerFormValues } from '@types';
import Contact from '@components/RegistrationSteps/Contact';
import CompanyDetails from '@components/RegistrationSteps/CompanyDetails';
import PersonalDetails from '@components/RegistrationSteps/PersonalDetails';

interface Props {
  noResponsive?: boolean;
  readOnly?: boolean;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const FormControls: React.FC<Props> = (props) => {
  const renderSection = (title: string, content: React.ReactNode, noBorder: boolean = false) => {
    return (
      <div key={title} className="step-buyer-section">
        <div className={cx('section-title', { noBorder })}>{title}</div>
        <div className="responsive-table-content">{content}</div>
      </div>
    );
  };
  return (
    <>
      {props.values.type === 'legal' && (
        <CompanyDetails
          values={props.values}
          errors={props.errors}
          readOnly={props.readOnly}
          setFieldValue={props.setFieldValue}
          handleChange={props.handleChange}
        />
      )}
      <PersonalDetails
        noResponsive={props.noResponsive}
        readOnly={props.readOnly}
        values={props.values}
        errors={props.errors}
        handleChange={props.handleChange}
        setFieldValue={props.setFieldValue}
      />
      <Contact
        readOnly={props.readOnly}
        values={props.values}
        errors={props.errors}
        handleChange={props.handleChange}
        setFieldValue={props.setFieldValue}
      />
      {renderSection(
        'Ostatní',
        <>
          <Form.Group className="f-inline-group align-items-start">
            <Form.Label className="f-inline-label text-right">Poznámka</Form.Label>
            <div className="f-inline-control">
              <Form.Control
                rows={10}
                as="textarea"
                name="note"
                className="w-max-500 resize-none"
                readOnly={props.readOnly}
                isInvalid={!!props.errors.note}
                value={props.values.note || ''}
                onChange={props.handleChange}
              />
            </div>
          </Form.Group>
        </>
      )}
    </>
  );
};

export default FormControls;
