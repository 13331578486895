import React from 'react';
import { Form, Table } from 'react-bootstrap';

import { Button, Visible } from '@components';
import { stripeHtml } from '@helpers/stripeHtml';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { AuctionInformedConsent, PermissionGroup, UserPermission } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  title?: string;
  data: AuctionInformedConsent[];
  onChangeEnabled: (index: number, enabled: boolean) => void;
  onEditClick?: (index: number, item: AuctionInformedConsent) => void;
  onDeleteClick?: (index: number) => void;
  onCreateClick: () => void;
}

const TemplatesInformedConsentsTable: React.FC<Props> = (props) => {
  const handleEditClick = (e: React.MouseEvent, index: number, item: AuctionInformedConsent) => {
    e.preventDefault();
    if (!!props.onEditClick) {
      props.onEditClick(index, item);
    }
  };

  const handleDeleteClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        closeMessage();
        if (!!props.onDeleteClick) {
          props.onDeleteClick(index);
        }
      },
    });
  };

  return (
    <div className="mt-4">
      {!!props.title && (
        <h3 className="f-size-19 mb-4">
          <span className="mr-3">{props.title}</span>
        </h3>
      )}
      <Button className="mb-3" onClick={() => props.onCreateClick()}>Přidat informovaný souhlas</Button>
      <div className="responsive-table-content">
        <Table className="text-left table-middle border-bottom-0" striped>
          <thead>
            <tr>
              <th className="text-left" style={{ width: '30%' }}>
                Název
              </th>
              <th className="text-left" style={{ width: '70%' }}>
                Text
              </th>
              <th className="text-center" style={{ width: '10%' }}>
                Aktivováno
              </th>
              <th className="text-right" style={{ width: '10%' }}>
                Akce
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
            {props.data.map((item, key) => (
              <tr key={`information-item-${key}`}>
                <td>{item.title}</td>
                <td>{stripeHtml(item.text, 100)}</td>
                <td className="text-center">
                  <Form.Check
                    custom
                    type="checkbox"
                    className="mt-checkbox"
                    style={{ marginRight: -10 }}
                    checked={item.enabled === true}
                    id={`informed-consent-enabled-${key}`}
                    onChange={(e) => props.onChangeEnabled(key, e.target.checked)}
                  />
                </td>
                <td className="text-right">
                  <Visible
                    permissionName={UserPermission.canEdit}
                    permissionGroupName={PermissionGroup.auctionInformation}
                  >
                    <a
                      href="/"
                      className="d-inline-flex align-items-center ml-4"
                      onClick={(e) => handleEditClick(e, key, item)}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </a>
                  </Visible>
                  {!item.id && (
                    <Visible
                      permissionName={UserPermission.canEdit}
                      permissionGroupName={PermissionGroup.auctionInformation}
                    >
                      <a
                        href="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleDeleteClick(e, key)}
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </a>
                    </Visible>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TemplatesInformedConsentsTable;
