import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  to?: string;
  target?: string;
  externalTarget?: boolean;
  type?: 'button' | 'submit';
  variant?: 'btn-outline-primary' | 'btn-outline-default' | 'btn-light' | 'btn-outline-grey' | 'btn-none';
  grayBg?: boolean;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

const Button: React.FC<Props> = (props) => {
  const classes = classNames([
    'btn',
    'btn-custom',
    props.variant || 'btn-outline-primary',
    { 'btn-gray-bg': props.grayBg },
    props.className,
  ]);

  if (!!props.to && !props.externalTarget) {
    return (
      <Link to={props.to} className={classes} style={props.style} target={props.target} data-test-id={props.dataTestId}>
        {props.children}
      </Link>
    );
  }

  if (!!props.to && props.externalTarget) {
    return (
      <a href={props.to} rel="noopener noreferrer" className={classes} style={props.style} target={props.target} data-test-id={props.dataTestId}>
        {props.children}
      </a>
    );
  }

  return (
    <button
      type={props.type || 'button'}
      className={classes}
      disabled={props.disabled}
      style={props.style}
      onClick={props.onClick}
      data-test-id={props.dataTestId}
    >
      {props.children}
    </button>
  );
};

export default Button;
