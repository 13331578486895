import React from 'react';
import moment from 'moment';

import { getDateTimeFormat } from '@helpers/datetime';
import { AuctionResponse } from '@types';

import icoAlertBell from '@assets/images/front/auction-detail/ico-alert-bell.svg';
import icoAlertBellRed from '@assets/images/front/auction-detail/ico-alert-bell-red.svg';
import AuctionCountdown from '@components/AuctionCountdown/AuctionCountdown';

interface Props {
  auction: AuctionResponse;
  reload?: () => void;
}

const AuctionAlertBox: React.FC<Props> = (props) => {

  const getOngoingText = () => {
    if (props.auction.status !== "ongoing" && props.auction.canProtest && props.auction.protestEndDatetime) {
      return props.auction.translations?.auction_protest_processing || 'auction_protest_processing';
    }

    if (props.auction.canProtest && props.auction.protestEndDatetime) {
      return `${props.auction.translations?.auction_can_protest_end || 'auction_can_protest_end'}: `;
    } else {
      return `${props.auction.translations?.auction_until_end || 'auction_until_end'}: `;
    }
  };

  return (
    <>
      {props.auction.parentStatus === 'prepared' && props.auction.status !== "adjourned" && (
        <div className="block-alert">
          <p className="w-100 text-center">
            <img src={icoAlertBell} alt="icon" />
            {props.auction.startDt ? (
              <>
                {props.auction.translations?.auction_until_start || 'auction_until_start'}:{' '}
                <span className="notranslate">
                <AuctionCountdown
                  completeText="Probíhá zahajování"
                  date={moment(props.auction.startDt).toDate()}
                  onComplete={props.reload}
                />
              </span>
              </>
            ) : (
              'Začátek dražby není stanoven'
            )}
          </p>
        </div>
      )}
      {props.auction.parentStatus === 'ongoing' &&
        (props.auction.draw && props.auction.drawToDt ? (
          <div className="block-alert alert-tab mb-4">
            <p>
              Probíhá losování:{' '}
              <span className="notranslate">
              <AuctionCountdown
                completeText="Čekám výsledek"
                date={moment(props.auction.drawToDt).toDate()}
                onlySeconds
              />
            </span>
              <br />
              Vyhrává nejvyšší vylosované číslo.
            </p>
          </div>
        ) : (
          <div className="block-alert">
            <p className="w-100 text-center">
              <img src={icoAlertBell} alt="icon" />
              {getOngoingText()}
              <span className="notranslate">
              {props.auction.canProtest && props.auction.protestEndDatetime ? (
                <>
                  {props.auction.status === "ongoing" && (
                    <AuctionCountdown
                      ongoing
                      completeText="Probíhá ukončování"
                      date={moment(props.auction.protestEndDatetime).toDate()}
                    />
                  )}
                </>
              ) : (
                <AuctionCountdown
                  ongoing
                  completeText="Probíhá ukončování"
                  date={moment(props.auction.realEndDt || props.auction.endDt).toDate()}
                />
              )}
            </span>
            </p>
          </div>
        ))}
      {props.auction.status === 'ended' && (
        <div className="block-alert info-wrapper">
          <div className="info-wrapper">
            <p className="start-date ">
              <img src={icoAlertBell} alt="icon" />
              {props.auction.translations?.auction_started || 'auction_started'}{' '}
              {getDateTimeFormat(props.auction.startDt)}
            </p>
            <p className="end-date ">
              <img src={icoAlertBellRed} alt="icon" />
              {props.auction.translations?.auction_ended || 'auction_ended'} {getDateTimeFormat(props.auction.endDt)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionAlertBox;
