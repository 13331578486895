import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { useUsersApi } from '@api/users';
import { User, UserAuctionResponse } from '@types';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Pagination } from '@components';

interface Props {
  user?: User;
}

export const FavouriteAuctions: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const location = useLocation();
  const [data, setData] = useState<UserAuctionResponse[]>([]);
  const [total, setTotal] = React.useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [query, setQuery] = useQueryParams({
    section: withDefault(NumberParam, 1),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
  });

  useEffect(() => {
    loadData();
    return () => usersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadData = async () => {
    setIsLoaded(false);
    try {
      const res = await usersApi.getFavouriteAuctions(props.user?.id || '', { ...query });
      setTotal(res.data.total || 0);
      setData(res.data.data);
      setIsLoaded(true);
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
    }
  };

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center py-5">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>Oblíbené dražby/aukce</h2>
      </div>

      <div className="component-admin-auctions-table mt-4">
        <Table className="border-bottom-0">
          <thead>
          <tr>
            <th className="text-nowrap">Název dražby/aukce</th>
            <th className="text-nowrap text-center">Jednací číslo</th>
            <th className="text-nowrap text-center">Začátek dražby/aukce</th>
            <th className="text-nowrap text-center">Konec dražby/aukce</th>
            <th className="text-nowrap text-right">Datum přidání do oblíbených</th>
          </tr>
          </thead>
          <tbody>
          {data.map((item, index) => (
            <tr key={`list-item-${index}`} className="border-top">
              <td className="auction-column-title">
                {item.auction.title}
              </td>
              <td className="text-center">{item.auction.number}</td>
              <td className="text-center">
                {getDateTimeFormat(item.auction.startDt)}
              </td>
              <td className="text-center">
                {getDateTimeFormat(item.auction.endDt)}
              </td>
              <td className="text-right">
                {getDateTimeFormat(item.createdDt)}
              </td>
            </tr>
          ))}
          {data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
          </tbody>
        </Table>
      </div>

      <div>
        {total > query.perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={total}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
