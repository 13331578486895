import React from 'react';
import { getName } from '@helpers/string';
import { useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useUsersApi } from '@api/users';
import UserForm from './components/UserForm';
import UserEmails from './components/UserEmails';
import UserHistory from './components/UserHistory';
import UserDocuments from './components/UserDocuments';
import { HistoryAuthorType, User, UserRole } from '@types';
import { useCurrentUserRoleChecks, usePageStore } from '@stores';
import { BasePage, AdminBoxContent, AdminProgressFormMenu, BasePreloader, Button, useVisibility } from '@components';

import { JoinedAuctions } from './components/JoinedAuctions';
import { FavouriteAuctions } from './components/FavouriteAuctions';
import { Consents } from './components/Consents';
import { Activity } from './components/Activity';

export enum UserSections {
  informations,
  documents,
  history,
  emails,
  favouriteAuctions,
  auctions,
  consents,
  activity,
}

interface Props {
  readOnly?: boolean;
}

interface ParamsType {
  id: string;
}

const UserUpdatePage: React.FC<Props> = (props) => {
  const { id } = useParams<ParamsType>();
  const { shouldBeVisible } = useVisibility();
  const usersApi = useUsersApi();
  const pageState = usePageStore();
  const [user, setUser] = React.useState<User>();
  const [loaded, setLoaded] = React.useState(!id);
  const [notFound, setNotFound] = React.useState(false);
  const [author, setAuthor] = React.useState<HistoryAuthorType | undefined>(undefined);
  const [dateCreated, setDateCreated] = React.useState<string | undefined>(undefined);
  const [query, setQuery] = useQueryParams({
    back: withDefault(StringParam, undefined),
    section: withDefault(NumberParam, UserSections.informations),
  });
  const { isOfRoleSuperAdmin } = useCurrentUserRoleChecks();

  const page = pageState.getPage(
    !id ? routes.admin.USER_CREATE : props.readOnly ? routes.admin.USER_DETAIL : routes.admin.USER_UPDATE
  );

  React.useEffect(() => {
    if (!!id) {
      loadUser(id);
    }
    return () => {
      usersApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadUser = (id: string, callback?: () => void) => {
    setLoaded(false);
    usersApi
      .getUser(id)
      .then((res) => {
        setUser(res.data.data);
        setDateCreated(res.data.data.timeCreated);
        setAuthor(res.data.data.createdUser);
        setLoaded(true);
        if (!!callback) {
          callback();
        }
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }

        if (err?.response?.status === 404 || err?.response?.status === 403) {
          setLoaded(true);
          setNotFound(true);
        }
      });
  };

  const handleSectionChange = (url: number | string) => {
    setQuery({ ...query, section: url as number }, 'push');
  };

  const getActiveSection = (section: number): UserSections => {
    return section in UserSections ? section : UserSections.informations;
  };

  const getPageTitle = (user?: User): string => {
    if (!id) {
      return page?.name || '';
    }
    let title = `${page?.name}: `;
    return `${title}${getName(user) || user?.email || ``}`;
  };

  const getBackLabel = () => {
    switch (query.back) {
      case 'admin-buyers':
        return 'Zpět na doklady a jistoty';
      case 'admin-security':
        return 'Zpět na doklady a jistoty';
      default:
        return 'Zpět na dražitele';
    }
  };

  const getBackUrl = () => {
    switch (query.back) {
      case 'admin-buyers':
        return pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY);
      case 'admin-security':
        return `${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}?s=2`;
      default:
        return pageState.getPagePath(routes.admin.USER_LIST);
    }
  };

  const menuItems: Array<{
    label: string;
    url: string;
    isActive: boolean;
  }> = [];

  if (shouldBeVisible({ permissionGroupName: 'admin-buyers', permissionName: ['canView', 'canEdit'] })) {
    menuItems.push({
      label: 'Informace o dražiteli',
      url: UserSections.informations.toString(),
      isActive: getActiveSection(query.section) === UserSections.informations,
    });
  }

  if (user && user.role === UserRole.buyer) {
    if (shouldBeVisible({ permissionGroupName: 'admin-buyer-documents', permissionName: ['canView', 'canEdit'] })) {
      menuItems.push({
        label: 'Dokumenty',
        url: UserSections.documents.toString(),
        isActive: getActiveSection(query.section) === UserSections.documents,
      });
    }
  }

  if (!!user) {
    if (shouldBeVisible({ permissionGroupName: 'admin-buyer-history', permissionName: ['canView', 'canEdit'] })) {
      menuItems.push({
        label: 'Historie',
        url: UserSections.history.toString(),
        isActive: getActiveSection(query.section) === UserSections.history,
      });
    }
    if (shouldBeVisible({ permissionGroupName: 'admin-buyer-send-mail', permissionName: ['canView', 'canEdit'] })) {
      menuItems.push({
        label: 'Odeslané emaily',
        url: UserSections.emails.toString(),
        isActive: getActiveSection(query.section) === UserSections.emails,
      });
    }

    if (user.role === UserRole.buyer && isOfRoleSuperAdmin()) {
      menuItems.push({
        label: 'Oblíbené dražby/aukce',
        url: UserSections.favouriteAuctions.toString(),
        isActive: getActiveSection(query.section) === UserSections.favouriteAuctions,
      });

      menuItems.push({
        label: 'Dražební/aukční proces dražitele',
        url: UserSections.auctions.toString(),
        isActive: getActiveSection(query.section) === UserSections.auctions,
      });
    }
  }

  if (isOfRoleSuperAdmin()) {
    menuItems.push({
      label: 'Souhlasy',
      url: UserSections.consents.toString(),
      isActive: getActiveSection(query.section) === UserSections.consents,
    });
  }

  if (isOfRoleSuperAdmin()) {
    menuItems.push({
      label: 'Aktivita',
      url: UserSections.activity.toString(),
      isActive: getActiveSection(query.section) === UserSections.activity,
    });
  }

  if (loaded && notFound) {
    return (
      <BasePage page={routes.admin.USER_LIST} className="page-admin-buyer">
        <div className="component-auction-404">
          <div className="component-auction-title">Chyba</div>
          <div className="w-min-500 bg-white">
            <div className="component-admin-box-content">
              Pro zobrazení této stránky anebo její části nemáte oprávnění.
            </div>
          </div>
        </div>
      </BasePage>
    );
  }

  return (
    <BasePage page={routes.admin.USER_LIST} title={loaded ? getPageTitle(user) : ''} className="page-admin-buyer">
      {loaded && <AdminProgressFormMenu onClick={handleSectionChange} items={menuItems} />}
      <AdminBoxContent className="p-0">
        {loaded ? (
          <div className="w-min-500">
            <div className="component-admin-box-content overlow-x-auto">
              <>
                {getActiveSection(query.section) === UserSections.informations && (
                  <UserForm
                    section={getActiveSection(query.section)}
                    user={user}
                    readOnly={props.readOnly}
                    onUpdate={() => loadUser(id)}
                  />
                )}
                {getActiveSection(query.section) === UserSections.documents && (
                  <UserDocuments user={user} userId={id} readOnly={props.readOnly} />
                )}
                {isOfRoleSuperAdmin() && getActiveSection(query.section) === UserSections.history && (
                  <UserHistory user={user} dateCreated={dateCreated} author={author} />
                )}
                {shouldBeVisible({
                  permissionGroupName: 'admin-buyer-send-mail',
                  permissionName: ['canView', 'canEdit'],
                }) &&
                  getActiveSection(query.section) === UserSections.emails && <UserEmails user={user} />}
                {isOfRoleSuperAdmin() && getActiveSection(query.section) === UserSections.favouriteAuctions && (
                  <FavouriteAuctions user={user} />
                )}
                {isOfRoleSuperAdmin() && getActiveSection(query.section) === UserSections.auctions && (
                  <JoinedAuctions user={user} />
                )}
                {isOfRoleSuperAdmin() && getActiveSection(query.section) === UserSections.consents && (
                  <Consents user={user} />
                )}
                {isOfRoleSuperAdmin() && getActiveSection(query.section) === UserSections.activity && (
                  <Activity user={user} />
                )}
              </>
            </div>
          </div>
        ) : (
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        )}
      </AdminBoxContent>
      <Button grayBg to={getBackUrl()}>
        {getBackLabel()}
      </Button>
    </BasePage>
  );
};

export default UserUpdatePage;
