import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';

import colorIcoCheck from '@assets/images/color-ico-check.svg';

import { useAuthApi } from '@api/auth';
import { useAuthFrontStore } from '@stores';
import { Button, FormGroup, FrontAccountBox } from '@components';

const ChangePassword: React.FC = () => {
  const authApi = useAuthApi();
  const authFrontState = useAuthFrontStore();
  const [showErrors, setShowErrors] = React.useState(false);
  const [showSuccess, setShowSucces] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.currentPassword) {
        errors.currentPassword = 'Vyplňte staré heslo';
      }

      if (!values.newPassword || /^\s*$/.test(values.newPassword)) {
        errors.newPassword = 'Vyplňte nové heslo';
      }

      if (values.newPasswordRepeat !== values.newPassword) {
        errors.newPasswordRepeat = 'Zadaná hesla se neshodují';
      }

      return errors;
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values: FormikValues) => {
    try {
      setLoading(true);
      await authApi.changePassword(values.currentPassword, values.newPassword, values.newPasswordRepeat);
      setShowSucces(true);
    } catch (err) {
      setShowSucces(false);
    }
    setLoading(false);
  };

  return (
    <FrontAccountBox>
      <h2 className="box-title mb-3">Uživatelský účet</h2>
      <p className="f-size-14 mb-5">
        <b>Email:</b> {authFrontState.user?.email}
      </p>
      <h2 className="box-title mb-3">Změna hesla</h2>
      <Form
        onSubmit={(event: any) => {
          setShowErrors(true);
          formik.handleSubmit(event);
        }}
      >
        <FormGroup
          name="currentPassword"
          label="Staré heslo"
          type="password"
          error={showErrors ? formik.errors.currentPassword : undefined}
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          required
        />
        <FormGroup
          name="newPassword"
          label="Nové heslo"
          type="password"
          error={showErrors ? formik.errors.newPassword : undefined}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          required
        />
        <FormGroup
          name="newPasswordRepeat"
          label="Nové heslo znovu"
          type="password"
          error={showErrors ? formik.errors.newPasswordRepeat : undefined}
          value={formik.values.newPasswordRepeat}
          onChange={formik.handleChange}
          required
        />
        <Button disabled={loading} type="submit" variant="btn-outline-primary" className="mt-3">
          Změnit heslo
        </Button>
      </Form>
      {showSuccess && (
        <p className="change-password-success">
          <img src={colorIcoCheck} alt="ico" className="mr-2" />
          Vaše heslo bylo změněno
        </p>
      )}
    </FrontAccountBox>
  );
};

export default ChangePassword;
