import * as React from 'react';

import { AuctionResponse } from '@types';
import BuyersSection from './BuyersSection';

interface Props {
  auction: AuctionResponse;
  isAuctionSecurity?: boolean;
}

const AuctionSection: React.FC<Props> = (props) => {
  return (
    <div className="w-min-500 active-auction-panels">
      <BuyersSection isAuctionSecurity={props.isAuctionSecurity} auction={props.auction} />
    </div>
  );
};

export default AuctionSection;
