import { AxiosResponse } from 'axios';

import { useGridFilter } from '@stores/gridFilters';
import { useApi, API, APIResponse, BaseAPIResponse } from '@api/api';
import {
  User,
  UserType,
  UserFilters,
  BuyerDocument,
  DocumentCreate,
  Document,
  HistoryType,
  UserEmailType,
  UserDocument,
  UserDocumentType,
  AuthenticationFormFormat,
  AuthenticationFormType,
  PaginationParams,
  UserAuctionResponse,
  UserConsent, LogResponse,
} from '@types';

export const userDeletePath = (userId: string | number) => `/admin/user/${userId}`;
export const userRestorePath = (userId: string | number) => `/admin/user/${userId}/restore`;
export const createUserPath = () => `/admin/user/new`;
export const userEmailValidationPath = () => `/user/validate-email-address`;
export const userRegistrationPath = () => `/user/new`;
export const documentTypesPath = () => `enum/user/document-types`;
export const myDocumentsPath = () => `/user/document`;
export const userDocumentPath = (documentId: string | number) => `/user/document/${documentId}`;
export const adminDocumentPath = (userId: string | number, documentId: string | number) =>
  `/admin/user/${userId}/document/${documentId}`;
export const authenticationFormPath = (format: AuthenticationFormFormat) => `/user/authentication-form/${format}`;
export const usersPath = () => `/admin/user`;
export const userPath = (userId: number | string) => `/admin/user/${userId}`;
export const userDocumentsPath = (userId: number | string) => `/admin/user/${userId}/document`;
export const userHistoryPath = (userId: number | string) => `/admin/user/${userId}/history`;
export const userAddressHistoryPath = (userId: number | string) => `/admin/user/${userId}/address/history`;
export const userDocumentsHistoryPath = (userId: number | string) => `/admin/user/${userId}/document-history`;
export const userEmailsPath = (userId: number | string) => `/admin/user/${userId}/email`;
export const userEmailPath = (userId: number | string, emailId: number | string) =>
  `/admin/user/${userId}/email/${emailId}`;
export const myEmailsPath = () => `/user/emails`;
export const myEmailPath = (emailId: number | string) => `/user/email/${emailId}`;
export const emailAttachmentPath = (emailId: number | string, attachmentId: number | string) =>
  `/user/email/${emailId}/attachment/${attachmentId}`;
export const adminEmailAttachmentPath = (
  userId: number | string,
  emailId: number | string,
  attachmentId: number | string
) => `admin/user/${userId}/email/${emailId}/attachment/${attachmentId}`;
export const userTypesPath = () => `/enum/user/types`;
export const getFavouriteAuctionsPath = (userId: string | number) => `/admin/user/auction/favorite/${userId}`;
export const getJoinedAuctionsPath = (userId: string | number) => `/admin/user/auction/joined/${userId}`;
export const getUserConsentsPath = (userId: string | number) => `/admin/consent/user/${userId}`;
export const getUserActivityPath = (userId: string | number) => `/admin/log/user/${userId}`;

export interface UsersAPI extends API {
  delete(userId: string | number): APIResponse;
  restore(userId: string | number): APIResponse;
  createUser(email: string): APIResponse<User>;
  emailValidation(email: string, portalConsent?: boolean, gdprConsent?: boolean): Promise<APIResponse>;
  userRegistration(
    email: string,
    newsletter: boolean,
    portalConsent?: boolean,
    gdprConsent?: boolean
  ): Promise<APIResponse>;
  getMyDocumentsFront(): APIResponse<Array<UserDocument>>;
  getAdminDocument(userId: string | number, documentId: string | number): Promise<AxiosResponse<any>>;
  getAuthenticationForm(format: AuthenticationFormFormat): APIResponse<AuthenticationFormType>;
  uploadMyDocumentFront(data: Partial<DocumentCreate>): APIResponse<Array<UserDocument>>;
  getUsers(params: UserFilters): APIResponse<Array<User>>;
  getUser(userId: number | string): APIResponse<User>;
  getDocumentTypes(): BaseAPIResponse<UserDocumentType[]>;
  getDocuments(userId: number | string): APIResponse<Array<BuyerDocument>>;
  getUserDocument(documentId: string | number): Promise<AxiosResponse<any>>;
  deleteUserDocument(documentId: string | number): APIResponse;
  getHistory(userId: number | string, perPage?: number, page?: number): APIResponse<Array<HistoryType>>;
  getAddressHistory(userId: number | string, perPage?: number, page?: number): APIResponse<Array<HistoryType>>;
  getDocumentsHistory(userId: number | string, perPage?: number, page?: number): APIResponse<Array<HistoryType>>;
  getEmails(userId: number | string): APIResponse<Array<UserEmailType>>;
  getEmail(userId: number | string, emailId: number | string): BaseAPIResponse<string>;
  getMyEmails(page: number, perPage: number): APIResponse<UserEmailType[]>;
  getMyEmail(emailId: number | string): BaseAPIResponse<string>;
  uploadDocument(auctioneerId: number | string, data: Partial<DocumentCreate>): APIResponse<Array<Document>>;
  getUserTypes(): BaseAPIResponse<UserType[]>;
  getEmailAttachment(emailId: number | string, attachmentId: number | string): BaseAPIResponse<File>;
  getAdminEmailAttachment(
    userId: number | string,
    emailId: number | string,
    attachmentId: number | string
  ): BaseAPIResponse<File>;
  getFavouriteAuctions(userId: string | number, params?: PaginationParams): APIResponse<UserAuctionResponse[]>;
  getAuctionsJoined(userId: string | number, params?: PaginationParams): APIResponse<UserAuctionResponse[]>;
  getUserConsents(userId: string | number, params?: PaginationParams): APIResponse<UserConsent[]>;
  getUserActivity(userId: string | number, params?: PaginationParams): APIResponse<LogResponse[]>;
}

export const useUsersApi = (): UsersAPI => {
  const api = useApi();

  const usersFilter = useGridFilter<UserFilters>([
    {
      key: 'type',
      property: 'user.buyerType',
    },
    {
      group: 'alpha',
      key: 'name',
      operator: 'like',
      property: 'user.firstName',
      splitKeyWords: true,
    },
    {
      group: 'alpha',
      key: 'name',
      operator: 'like',
      property: 'user.lastName',
      splitKeyWords: true,
    },
    {
      group: 'alpha',
      key: 'name',
      operator: 'like',
      property: 'user.company',
      splitKeyWords: true,
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      group: 'beta',
      key: 'ic',
      operator: 'like',
      property: 'user.ico',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      group: 'beta',
      key: 'ic',
      operator: 'like',
      property: 'user.personalIdentificationNumber',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      key: 'phone',
      operator: 'like',
      property: 'user.phone',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      key: 'email',
      operator: 'like',
      property: 'user.email',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      key: 'role',
      property: 'user.role',
    },
    {
      key: 'state',
      property: 'user.state',
    },
    {
      type: 'boolean',
      key: 'verificationState',
      property: 'userDocuments.isActiveDocument',
      value: (v) => (v !== undefined ? v === 'verified' : undefined),
    },
  ]);

  return {
    ...api,
    delete(id: string | number) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(userDeletePath(id), { cancelToken });
    },
    restore(id: string | number) {
      const cancelToken = api.prepareCancelToken('restore');
      return api.authRequest.get(userRestorePath(id), { cancelToken });
    },
    createUser(email: string) {
      const cancelToken = api.prepareCancelToken('createUser');
      return api.authRequest.post(createUserPath(), { email }, { cancelToken });
    },
    emailValidation(email: string, portalConsent?: boolean, gdprConsent?: boolean) {
      const cancelToken = api.prepareCancelToken('userEmailValidation');
      return api.authRequest.post(userEmailValidationPath(), { email, portalConsent, gdprConsent }, { cancelToken });
    },
    userRegistration(email: string, newsletter: boolean, portalConsent?: boolean, gdprConsent?: boolean) {
      const cancelToken = api.prepareCancelToken('userRegistration');
      return api.authRequest.post(
        userRegistrationPath(),
        {
          email,
          newsletter,
          portalConsent,
          gdprConsent,
        },
        { cancelToken }
      );
    },
    getMyDocumentsFront() {
      const cancelToken = api.prepareCancelToken('myDocuments');
      return api.authFrontRequest.get(myDocumentsPath(), { cancelToken });
    },
    getAuthenticationForm(format: AuthenticationFormFormat) {
      const cancelToken = api.prepareCancelToken('authenticationForm');
      return api.authFrontRequest.get(authenticationFormPath(format), { cancelToken });
    },
    uploadMyDocumentFront(data: Partial<DocumentCreate>) {
      const cancelToken = api.prepareCancelToken('myDocumentsCreate');
      return api.authFrontRequest.post(myDocumentsPath(), data, { cancelToken });
    },
    getDocumentTypes() {
      const cancelToken = api.prepareCancelToken('getDocumentTypes');
      return api.authFrontRequest.get(documentTypesPath(), { cancelToken });
    },
    getUserDocument(documentId: string | number) {
      return api.authFrontRequest.get(userDocumentPath(documentId), {
        params: { view: true },
        responseType: 'blob',
      });
    },
    deleteUserDocument(documentId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteUserDocument');
      return api.authFrontRequest.delete(userDocumentPath(documentId), { cancelToken });
    },
    getUsers(params: UserFilters) {
      const cancelToken = api.prepareCancelToken('getUsers');
      return api.authRequest.get(usersPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: 'user.id', direction: 'DESC' }),
          filter: usersFilter.isEmpty(params) ? undefined : usersFilter.toString(params),
        },
      });
    },
    getUser(userId: number | string) {
      const cancelToken = api.prepareCancelToken('getUser');
      return api.authRequest.get(userPath(userId), { cancelToken });
    },
    getDocuments(userId: number | string) {
      const cancelToken = api.prepareCancelToken('getUserDocuments');
      return api.authRequest.get(userDocumentsPath(userId), { cancelToken });
    },
    getAdminDocument(userId: string | number, documentId: string | number) {
      return api.authRequest.get(adminDocumentPath(userId, documentId), {
        params: { view: true },
        responseType: 'blob',
      });
    },
    getHistory(userId: number | string, perPage?: number, page?: number) {
      const cancelToken = api.prepareCancelToken('getHistory');
      return api.authRequest.get(userHistoryPath(userId), {
        cancelToken,
        params: {
          page: page,
          perPage: perPage,
          sort: JSON.stringify({ property: 'user.id', direction: 'DESC' }),
        },
      });
    },
    getAddressHistory(userId: number | string, perPage?: number, page?: number) {
      const cancelToken = api.prepareCancelToken('getAddressHistory');
      return api.authRequest.get(userAddressHistoryPath(userId), {
        cancelToken,
        params: {
          page: page,
          perPage: perPage,
          sort: JSON.stringify({ property: 'userAddressHistory.id', direction: 'DESC' }),
        },
      });
    },
    getDocumentsHistory(userId: number | string, perPage?: number, page?: number) {
      const cancelToken = api.prepareCancelToken('getDocumentsHistory');
      return api.authRequest.get(userDocumentsHistoryPath(userId), {
        cancelToken,
        params: {
          page: page,
          perPage: perPage,
          sort: JSON.stringify({ property: 'userDocumentHistory.id', direction: 'DESC' }),
        },
      });
    },
    getEmails(userId: number | string) {
      const cancelToken = api.prepareCancelToken('getEmails');
      return api.authRequest.get(userEmailsPath(userId), { cancelToken });
    },
    getEmail(userId: number | string, emailId: number | string) {
      const cancelToken = api.prepareCancelToken('getEmail');
      return api.authRequest.get(userEmailPath(userId, emailId), { cancelToken });
    },
    getMyEmails(page: number, perPage: number) {
      const cancelToken = api.prepareCancelToken('getMyEmails');
      return api.authFrontRequest.get(myEmailsPath(), {
        cancelToken,
        params: { page, perPage, sort: JSON.stringify({ property: 'sentEmail.id', direction: 'DESC' }) },
      });
    },
    getMyEmail(emailId: number | string) {
      const cancelToken = api.prepareCancelToken('getMyEmail');
      return api.authFrontRequest.get(myEmailPath(emailId), { cancelToken });
    },
    getEmailAttachment(emailId: number | string, attachmentId: number | string) {
      const cancelToken = api.prepareCancelToken('getEmailAttachment');
      return api.authFrontRequest.get(emailAttachmentPath(emailId, attachmentId), {
        cancelToken,
        responseType: 'blob',
      });
    },
    getAdminEmailAttachment(userId: number | string, emailId: number | string, attachmentId: number | string) {
      const cancelToken = api.prepareCancelToken('getAdminEmailAttachment');
      return api.authRequest.get(adminEmailAttachmentPath(userId, emailId, attachmentId), {
        cancelToken,
        responseType: 'blob',
      });
    },
    uploadDocument(userId: number | string, data: Partial<DocumentCreate>) {
      const cancelToken = api.prepareCancelToken('documentsCreate');
      return api.authRequest.post(userDocumentsPath(userId), data, { cancelToken });
    },
    getUserTypes() {
      const cancelToken = api.prepareCancelToken('getUserTypes');
      return api.request.get(userTypesPath(), { cancelToken });
    },
    getFavouriteAuctions(userId: string | number, params?: PaginationParams) {
      const cancelToken = api.prepareCancelToken('getFavouriteAuctions');
      return api.authRequest.get(getFavouriteAuctionsPath(userId), { cancelToken, params });
    },
    getAuctionsJoined(userId: string | number, params?: PaginationParams) {
      const cancelToken = api.prepareCancelToken('getAuctionsJoined');
      return api.authRequest.get(getJoinedAuctionsPath(userId), { cancelToken, params });
    },
    getUserConsents(userId: string | number, params?: PaginationParams) {
      const cancelToken = api.prepareCancelToken('getUserConsents');
      return api.authRequest.get(getUserConsentsPath(userId), { cancelToken, params });
    },
    getUserActivity(userId: string | number, params?: PaginationParams) {
      const cancelToken = api.prepareCancelToken('getUserActivity');
      return api.authRequest.get(getUserActivityPath(userId), { cancelToken, params });
    },
  };
};
