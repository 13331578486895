import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FrontBannerNavigationItemType } from '@types';
import NavigationItem from './components/NavigationItem';

interface Props {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  items: FrontBannerNavigationItemType[];
}

const FrontBannerNavigation: React.FC<Props> = (props) => {
  const handleClick = (item: FrontBannerNavigationItemType) => {
    if (typeof item.onClick === 'function') {
      item.onClick();
    }
  };

  return (
    <div className="component-front-banner-navigation">
      <Row className='justify-content-center'>
        {props.items.map((item, index) => (
          <Col
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            key={`navigation-item-${index}`}
            className="cursor-pointer py-3"
            onClick={() => handleClick(item)}
          >
            <NavigationItem {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FrontBannerNavigation;
