import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form, Table } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import * as routes from '@routes';
import { getName } from '@helpers/string';
import { useAuctionsApi } from '@api/auctions';
import { getFormatBytes } from '@helpers/formats';
import { applicationStore, usePageStore } from '@stores';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { BasePreloader, Button, ControlFeedback, FormGroup } from '@components';
import { AuctionMessagesResponse, AuctionResponse, DocumentMedia, UserAuctionResponse } from '@types';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import { fileToBase64 } from '@helpers/files';
import icoDelRed from '@assets/images/ico-del-red.svg';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';

interface Props {
  auction: AuctionResponse;
}

const AuctioneerMessageSection: React.FC<Props> = (props) => {
  const { addToast } = useToasts();
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const pageState = usePageStore();
  const inputRef = React.createRef<HTMLInputElement>();
  const [saved, setSaved] = React.useState(true);
  const [messageTypes, setMessageTypes] = useState<AuctionMessagesResponse>({});
  const [messageTypesLoaded, setMessageTypesLoaded] = useState(true);
  const [buyers, setBuyers] = React.useState<Array<UserAuctionResponse>>([]);
  const [buyersLoaded, setBuyersLoaded] = useState(true);
  const [sending, setSending] = useState(false);
  const [attachment, setAttachment] = useState<DocumentMedia[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auctionMessageType: '',
      subject: '',
      content: '',
      auctionMessageBuyers: [] as string[],
    },
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    loadMessagesTypes();
    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.auctionMessageType === 'buyer_own_choice') {
      loadBuyers();
      return () => {
        auctionsApi.cancelAllRequests();
        auctionCategoriesApi.cancelAllRequests();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.auctionMessageType]);

  const loadMessagesTypes = async () => {
    setMessageTypesLoaded(false);
    try {
      const response = await auctionsApi.getAuctionMessagesType();
      setMessageTypes(response.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
    setMessageTypesLoaded(true);
  };

  const loadBuyers = async () => {
    setBuyersLoaded(false);
    try {
      const response = await auctionsApi.getAuctionBuyers(props.auction.id);
      setBuyers(response.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
    setBuyersLoaded(true);
  };

  const handleSubmit = async () => {
    const formValues = {
      ...formik.values,
      auctionMessageBuyers: formik.values.auctionMessageBuyers.map((v) => ({ user: v })),
      auctionMessageMedias: attachment.map((v) => ({ media: v.hash })),
    };
    setSending(true);
    try {
      await auctionsApi.sendAuctionMessage(props.auction.id, formValues);
      formik.setSubmitting(false);
      formik.setValues(formik.initialValues);
      setAttachment([]);
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
    setSending(false);
  };

  const saveFile = async (file: File) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file);
      const response = await auctionsApi.uploadMessageAttachment({
        mime: file.type,
        type: 'file',
        document_type: 'identity',
        data: fileBase64,
        original_name: file.name,
      });
      attachment.push(response.data.data);
      setAttachment(attachment);
    } catch (err) {
      console.error(err);
      setSaved(true);
    }
  };

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }
    setSaved(false);
    for (let i = 0; i < e.target.files.length; i++) {
      await saveFile(e.target.files[i]);
    }
    setSaved(true);
  };

  const getAuctionSecurity = (item: UserAuctionResponse) => {
    if (parseFloat(props.auction.cautionDeposit || '0') <= 0) {
      return 'Není vyžadována';
    }
    return item.auctionSecurity === 'none' || item.auctionSecurity === 'rejected' ? 'Ne' : 'Ano';
  };

  const handleRemoveFile = (id: number) => {
    const newArray = attachment.filter((item) => item.id !== id);
    setAttachment(newArray);
  };

  return (
    <div className="component-admin-box-content">
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
        <h2 className="f-weight-300 f-size-25 mb-4">Komu zaslat</h2>
        <div>
          {messageTypesLoaded ? (
            <>
              {Object.entries(messageTypes).map((messageType, index) => (
                <Form.Check
                  custom
                  type="radio"
                  label={messageType[1]}
                  key={`message-type-${index}`}
                  className="radio-point mr-4 mb-3"
                  id={messageType[0]}
                  value={messageType[0]}
                  name="auctionMessageType"
                  checked={formik.values.auctionMessageType === messageType[0]}
                  onChange={formik.handleChange}
                />
              ))}
              {!!formik.errors.auctionMessageType && (
                <ControlFeedback type="invalid">{formik.errors.auctionMessageType}</ControlFeedback>
              )}
            </>
          ) : (
            <BasePreloader size={20} className="d-inline-block ml-3" />
          )}
        </div>
        {formik.values.auctionMessageType === 'buyer_own_choice' && (
          <>
            <h2 className="f-weight-300 f-size-25 mt-40">Dražitelé</h2>
            {buyersLoaded ? (
              <Table striped style={{ borderBottom: 'none' }} className="mb-30">
                <thead>
                  <tr>
                    <th></th>
                    <th>No.</th>
                    <th>Jméno a příjmení</th>
                    <th>Specif. symbol</th>
                    <th>Stav autorizace</th>
                    <th>Jistota</th>
                    <th>PP</th>
                  </tr>
                </thead>
                <tbody>
                  {buyers.map((item, index) => (
                    <tr key={`list-item-${index}`} className="border-top">
                      <td className="text-center">
                        <Form.Check
                          custom
                          type="checkbox"
                          className="mt-checkbox"
                          id={`auctioneer-${index}`}
                          name="[auctionMessageBuyers]"
                          value={item.user.id}
                          checked={formik.values.auctionMessageBuyers.includes(item.user.id.toString())}
                          onChange={formik.handleChange}
                        />
                      </td>
                      <td>{item.uniqueCode}</td>
                      <td>{getName(item.user) || ''}</td>
                      <td>{item.specificSymbol || '-'}</td>
                      <td>{item.authorizationStateTranslation}</td>
                      <td>{getAuctionSecurity(item)}</td>
                      <td>{item.preEmption ? 'Ano' : 'Ne'}</td>
                    </tr>
                  ))}
                  {buyers.length < 1 && (
                    <tr>
                      <td className="text-left" colSpan={100}>
                        Nejsou dražitelé
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <BasePreloader size={20} className="d-inline-block ml-3" />
            )}
          </>
        )}
        <h2 className="f-weight-300 f-size-25 mb-4 mt-70">Zpráva</h2>
        <div>
          <FormGroup
            required
            name="subject"
            label="Předmět zprávy"
            className="mb-3"
            labelClassName="text-left"
            error={formik.errors.subject as string}
            value={formik.values.subject}
            onChange={formik.handleChange}
          />
          <Wysiwyg
            required
            type="normal"
            name="content"
            label="Obsah zprávy"
            value={formik.values.content}
            error={formik.errors.content as string}
            onChange={(val) => formik.setFieldValue('content', val)}
          />

          <div className="d-flex align-items-center">
            <Button
              onClick={() => inputRef.current?.click()}
              variant="btn-outline-primary"
              className="f-size-12 f-weight-400 mt-3"
            >
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat přílohy
            </Button>
            {!saved && <BasePreloader className="ml-4 mt-2" size={25} />}
            {saved && (
              <input
                type="file"
                multiple
                ref={inputRef}
                className="d-none"
                accept="image/png,image/jpeg,image/gif,.pdf,.doc,.docx,.xls,.xlsx,image/jpg"
                onChange={handleSelectFile}
              />
            )}
          </div>
          {attachment.length > 0 && (
            <div className="d-flex">
              {attachment.map((item) => (
                <div className="mr-4 mt-2 mb-4">
                  <span>{item.originalName}</span>
                  <img
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                    src={icoDelRed}
                    alt="refused"
                    onClick={() => handleRemoveFile(item.id)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="bottom-action-holder">
          <Link
            className="d-inline-flex align-items-center f-size-14 back-link"
            to={pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': props.auction.id })}
          >
            Vrátit se zpět
          </Link>
          <Button disabled={sending} type="submit">
            Odeslat zprávu
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AuctioneerMessageSection;
