import React from 'react';
import { Table } from 'react-bootstrap';

import { Modal, Visible } from '@components';
import { stripeHtml } from '@helpers/stripeHtml';
import { AuctionInformationResponse, PermissionGroup, UserPermission } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoEye from '@assets/images/color-ico-eye.svg';

interface Props {
  type: string;
  title?: string;
  data: AuctionInformationResponse[];
  onDeleteClick?: (item: AuctionInformationResponse) => void;
  onEditClick?: (item: AuctionInformationResponse) => void;
}

const InformationTable: React.FC<Props> = (props) => {
  const [showHtml, setShowHtml] = React.useState<string>();

  const handleEditClick = (e: React.MouseEvent, item: AuctionInformationResponse) => {
    e.preventDefault();
    if (typeof props.onEditClick === 'function') {
      props.onEditClick(item);
    }
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctionInformationResponse) => {
    e.preventDefault();
    if (typeof props.onDeleteClick === 'function') {
      props.onDeleteClick(item);
    }
  };

  const handleShowHTML = (e: React.MouseEvent<HTMLAnchorElement>, value: string) => {
    e.preventDefault();
    setShowHtml(value);
  };

  return (
    <div className="mt-4">
      {!!props.title && <h3 className="f-size-19 mb-20">{props.title}</h3>}
      <div className="responsive-table-content">
        <Table className="text-left table-middle border-bottom-0" striped>
          <thead>
            <tr>
              <th className="text-left" style={{ width: '5%' }}>
                #
              </th>
              <th className="text-left" style={{ width: props.type === 'a' ? '60%' : props.type === "c"? '35%' : '90%' }}>
                Název
              </th>
              {props.type === "c" && (
                <th className="text-left" style={{ width: '55%' }}>
                  Text
                </th>
              )}
              {props.type === 'a' && (
                <>
                  <th className="text-left" style={{ width: '20%' }}>
                    Typ
                  </th>
                  <th className="text-center" style={{ width: '10%' }}>
                    Pořadí
                  </th>
                </>
              )}
              <th style={{ width: '5%' }} />
            </tr>
          </thead>
          <tbody>
            {props.data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
            {props.data.map((item, key) => (
              <tr key={`information-item-${key}`}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                {props.type === 'c' && (
                  <td>
                    {stripeHtml(item.content, 100)}
                    <a href="/" onClick={(e) => handleShowHTML(e, item.content)}>
                      <img src={colorIcoEye} alt="show" className="ml-2" />
                    </a>
                  </td>
                )}
                {props.type === 'a' && (
                  <>
                    <td>{item.showTimeTypeTranslation}</td>
                    <td className="text-center">{item.position}</td>
                  </>
                )}
                <td className="text-right">
                  <Visible
                    permissionName={UserPermission.canEdit}
                    permissionGroupName={PermissionGroup.auctionInformation}
                  >
                    <a
                      href="/"
                      className="d-inline-flex align-items-center mr-4"
                      onClick={(e) => handleEditClick(e, item)}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </a>
                    {props.type !== 'c' && (
                      <a
                        href="/"
                        className="d-inline-flex align-items-center"
                        onClick={(e) => handleDeleteClick(e, item)}
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </a>
                    )}
                  </Visible>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal title="Text" isOpen={!!showHtml} className="w-max-500" onRequestClose={() => setShowHtml(undefined)}>
        <div dangerouslySetInnerHTML={{ __html: showHtml || '' }} />
      </Modal>
    </div>
  );
};

export default InformationTable;
