import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';

import { getRequestDateTimeFormat } from '@helpers/datetime';
import { AuctionParametersResponse, BaseType } from '@types';
import { AuctionParameterForm } from './AuctionParameterForm';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';

interface SelectOptionType {
  value: string;
  label: string;
}

export const RealEstateFlatForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [flatTypes, setFlatTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [ownershipTypes, setOwnershipTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingTypes, setHeatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [flatClass, setflatClass] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);

  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);

  const yesNoOptions = [
    { value: 'no', label: 'NE' },
    { value: 'yes', label: 'ANO' },
  ];

  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const flatTypeOptions = flatTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const ownershipTypeOptions = ownershipTypes.map((item) => ({
    value: item.id,
    label: item.title,
    annuity: item.annuity,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingTypeOptions = heatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const flatClassOptions = flatClass.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const val = getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership);
    const annuity = showAnnuity(val);
    if (annuity === false) {
      props.setFieldValue('auctionParameterRealEstateFlat.annuity', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership]);

  const loadData = async () => {
    try {
      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setOwnershipTypes(realEstateRes.data.data.parameterRealEstateOwnership);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingTypes(realEstateRes.data.data.parameterRealEstateHeating);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);

      const realEstateFlatRes = await auctionParameterApi.getRealEstateFlatAll();
      setFlatTypes(realEstateFlatRes.data.data.parameterRealEstateType);
      setflatClass(realEstateFlatRes.data.data.parameterRealEstateFlatClass);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleFlatTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFlatType', itemValue?.value);
  };

  const handleFlatClassChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFlatClass', itemValue?.value);
  };

  const handlePlacementChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleFlatOwnershipChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateOwnership', itemValue?.value);
  };

  const handleFlatBuildingConstructionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateBuildingConstruction', itemValue?.value);
  };

  const handleFlatObjectStatusChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRealEstateHeatingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatHeatings || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatings',
      itemValue.map((i) => ({
        parameterRealEstateHeating: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeating.id || f.parameterRealEstateHeating) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleFlatParkingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateParking', itemValue?.value);
  };

  const handleFurnishedChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateFurnished', itemValue?.value);
  };

  const handleProtectionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleTransportChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWastes',
      itemValue.map((i) => {
        return {
          parameterRealEstateWaste: i.value,
          id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
            ?.id,
        };
      })
    );
  };

  const handleRealEstateWaterChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      }))
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateFlat.parameterRealEstateEnergyEfficiencyRating', itemValue?.value);
  };

  const handleTelecommucationChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateFlat?.auctionParameterRealEstateFlatTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  const showAnnuity = (val?: string): boolean | undefined => {
    return ownershipTypeOptions.find((o) => o.value === val)?.annuity;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Dispozice *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFlatType">
            <Select
              isClearable
              isDisabled={props.readOnly}
              required
              size="md"
              name="parameterRealEstateFlatType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType}
              options={flatTypeOptions}
              onChange={handleFlatTypeChange}
              value={
                flatTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFlatType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Budova *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleFlatBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateBuildingConstruction)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateBuildingConstruction}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stav *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleFlatObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateObjectStatus)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateObjectStatus}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Typ bytu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFlatClass">
            <Select
              isClearable
              isDisabled={props.readOnly}
              required
              size="md"
              name="parameterRealEstateFlatClass"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass}
              options={flatClassOptions}
              onChange={handleFlatClassChange}
              value={
                flatClassOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFlatClass)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFlatClass}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Počet podlaží"
        error={props.errors.auctionParameterRealEstateFlat_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.numberOfFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.numberOfFloors}
        dataTestId="auctionParameterRealEstateFlat.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateFlat_undergroundFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateFlat.undergroundFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.undergroundFloors}
        dataTestId="auctionParameterRealEstateFlat.undergroundFloors"
      />

      <FormGroup
        required
        readOnly={props.readOnly}
        type="number"
        label="Umístění podlaží"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_floor as string}
        name="auctionParameterRealEstateFlat.floor"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.floor}
        dataTestId="auctionParameterRealEstateFlat.floor"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Vlastnictví *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateOwnership">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateOwnership"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership}
              options={ownershipTypeOptions}
              onChange={handleFlatOwnershipChange}
              value={
                ownershipTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateOwnership}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Výtah</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlat.elevator">
            <Select
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateFlat.elevator"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_elevator}
              options={yesNoOptions}
              value={yesNoOptions.find(
                (v) => v.value === (props.values.auctionParameterRealEstateFlat?.elevator ? 'yes' : 'no')
              )}
              onChange={(val) => {
                props.setFieldValue('auctionParameterRealEstateFlat.elevator', val?.value === 'yes');
              }}
            />
            {!!props.errors.auctionParameterRealEstateFlat_elevator && (
              <ControlFeedback type="invalid">{props.errors.auctionParameterRealEstateFlat_elevator}</ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Bezbariérový přístup</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlat.easyAccess">
            <Select
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateFlat.easyAccess"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_easyAccess}
              options={yesNoOptions}
              value={yesNoOptions.find(
                (v) => v.value === (props.values.auctionParameterRealEstateFlat?.easyAccess ? 'yes' : 'no')
              )}
              onChange={(val) => {
                props.setFieldValue('auctionParameterRealEstateFlat.easyAccess', val?.value === 'yes');
              }}
            />
            {!!props.errors.auctionParameterRealEstateFlat_easyAccess && (
              <ControlFeedback type="invalid">{props.errors.auctionParameterRealEstateFlat_easyAccess}</ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Umístění</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstatePlacement}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Okolní zástavba</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateSurroundingsType)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateSurroundingsType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Ochrana</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateProtection)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateProtection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          required
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.usableArea"
          error={props.errors.auctionParameterRealEstateFlat_usableArea as string}
          thousandSeparator
          label="Plocha užitná"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.usableArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.usableArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.builtUpArea"
          error={props.errors.auctionParameterRealEstateFlat_builtUpArea as string}
          thousandSeparator
          label="Plocha zastavěná"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateFlat.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateFlat.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.gardenArea"
          error={props.errors.auctionParameterRealEstateFlat_gardenArea as string}
          thousandSeparator
          label="Plocha zahrady"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.gardenArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.gardenArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          name="auctionParameterRealEstateFlat.floorArea"
          error={props.errors.auctionParameterRealEstateFlat_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          value={props.values.auctionParameterRealEstateFlat.floorArea}
          onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.floorArea', parseInt(val.value))}
          dataTestId="auctionParameterRealEstateFlat.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.balcony">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Balkon"
            className="mr-4"
            id="balcony"
            name="auctionParameterRealEstateFlat.balcony"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.balcony', e.target.checked);
            }}
            checked={props.values.auctionParameterRealEstateFlat.balcony}
          />
          {props.values.auctionParameterRealEstateFlat.balcony && (
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.balconyArea"
                thousandSeparator
                label="Plocha balkonu"
                error={props.errors.auctionParameterRealEstateFlat_balconyArea as string}
                className="mt-2 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                value={props.values.auctionParameterRealEstateFlat.balconyArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.balconyArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.balconyArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          )}
        </div>
      </Form.Group>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.loggia">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Lodžie"
            className="mr-4"
            id="loggia"
            name="auctionParameterRealEstateFlat.loggia"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.loggia', e.target.checked);
            }}
            checked={props.values.auctionParameterRealEstateFlat.loggia}
          />
          {props.values.auctionParameterRealEstateFlat.loggia && (
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.loggiaArea"
                thousandSeparator
                label="Plocha lodžie"
                error={props.errors.auctionParameterRealEstateFlat_loggiaArea as string}
                className="mt-2 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                value={props.values.auctionParameterRealEstateFlat.loggiaArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.loggiaArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.loggiaArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          )}
        </div>
      </Form.Group>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.terrace">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Terasa"
            className="mr-4"
            id="terrace"
            name="auctionParameterRealEstateFlat.terrace"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.terrace', e.target.checked);
            }}
            checked={props.values.auctionParameterRealEstateFlat.terrace}
          />
          {props.values.auctionParameterRealEstateFlat.terrace && (
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.terraceArea"
                thousandSeparator
                label="Plocha terasy"
                error={props.errors.auctionParameterRealEstateFlat_terraceArea as string}
                className="mt-2 mb-2"
                labelClassName="text-left"
                controlClassName="w-max-205"
                type="number"
                value={props.values.auctionParameterRealEstateFlat.terraceArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.terraceArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.terraceArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          )}
        </div>
      </Form.Group>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.cellar">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Sklep"
            className="mr-4"
            id="cellar"
            name="auctionParameterRealEstateFlat.cellar"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.cellar', e.target.checked);
            }}
            checked={props.values.auctionParameterRealEstateFlat.cellar}
          />
          {props.values.auctionParameterRealEstateFlat.cellar && (
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.cellarArea"
                thousandSeparator
                label="Plocha sklepu"
                error={props.errors.auctionParameterRealEstateFlat_cellarArea as string}
                className="mt-2 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                type="number"
                value={props.values.auctionParameterRealEstateFlat.cellarArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.cellarArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.cellarArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          )}
        </div>
      </Form.Group>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.basin">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Bazén"
            className="mr-4"
            id="basin"
            name="auctionParameterRealEstateFlat.basin"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.basin', e.target.checked);
            }}
            checked={props.values.auctionParameterRealEstateFlat.basin}
          />
          {props.values.auctionParameterRealEstateFlat.basin && (
            <div className="d-flex align-items-center">
              <FormGroup
                readOnly={props.readOnly}
                name="auctionParameterRealEstateFlat.basinArea"
                thousandSeparator
                label="Plocha bazénu"
                error={props.errors.auctionParameterRealEstateFlat_basinArea as string}
                className="mt-2 mb-0"
                labelClassName="text-left"
                controlClassName="w-max-205"
                value={props.values.auctionParameterRealEstateFlat.basinArea}
                onValueChange={(val) =>
                  props.setFieldValue('auctionParameterRealEstateFlat.basinArea', parseInt(val.value))
                }
                dataTestId="auctionParameterRealEstateFlat.basinArea"
              />
              <span className="f-size-12 f-weight-400">
                m<sup>2</sup>
              </span>
            </div>
          )}
        </div>
      </Form.Group>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateFlat.parkingExists">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Parkování"
            className="mr-4"
            id="parkingExists"
            name="auctionParameterRealEstateFlat.parkingExists"
            checked={props.values.auctionParameterRealEstateFlat.parkingExists}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateFlat.parkingExists', e.target.checked);
            }}
          />
        </div>
      </Form.Group>

      {props.values.auctionParameterRealEstateFlat.parkingExists && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateParking">
              <Select
                isClearable
                isDisabled={props.readOnly}
                size="md"
                name="parameterRealEstateParking"
                isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking}
                options={parkingTypeOptions}
                onChange={handleFlatParkingChange}
                value={
                  parkingTypeOptions.find(
                    (i) =>
                      i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateParking)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateFlat_parameterRealEstateParking}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Termíny</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Nastěhování</Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.readyDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_readyDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.readyDate
                ? moment(props.values.auctionParameterRealEstateFlat.readyDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.readyDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.readyDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_readyDate && (
            <ControlFeedback type="invalid">{props.errors.auctionParameterRealEstateFlat_readyDate}</ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Zahájení výstavby</Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.beginningDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_beginningDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.beginningDate
                ? moment(props.values.auctionParameterRealEstateFlat.beginningDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.beginningDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.beginningDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_beginningDate && (
            <ControlFeedback type="invalid">
              {props.errors.auctionParameterRealEstateFlat_beginningDate}
            </ControlFeedback>
          )}
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Ukončení výstavby</Form.Label>
        <div className="f-inline-control">
          <DatePickerInput
            isNullable
            name="auctionParameterRealEstateFlat.finishDate"
            className={classNames([
              'form-control',
              'w-max-205',
              { 'is-invalid': !!props.errors.auctionParameterRealEstateFlat_finishDate },
            ])}
            value={
              !!props.values.auctionParameterRealEstateFlat.finishDate
                ? moment(props.values.auctionParameterRealEstateFlat.finishDate).toDate()
                : undefined
            }
            onChange={(val) =>
              props.setFieldValue(
                'auctionParameterRealEstateFlat.finishDate',
                val instanceof Date ? getRequestDateTimeFormat(val) : undefined
              )
            }
            data-test-id="auctionParameterRealEstateFlat.finishDate"
          />
          {!!props.errors.auctionParameterRealEstateFlat_finishDate && (
            <ControlFeedback type="invalid">{props.errors.auctionParameterRealEstateFlat_finishDate}</ControlFeedback>
          )}
        </div>
      </Form.Group>

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Rok výstavby"
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_objectYear as string}
        name="auctionParameterRealEstateFlat.objectYear"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.objectYear}
        dataTestId="auctionParameterRealEstateFlat.objectYear"
      />

      <FormGroup
        type="number"
        label="Rok kolaudace"
        labelClassName="text-left"
        controlClassName="w-max-205"
        name="auctionParameterRealEstateFlat.acceptanceYear"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateFlat_acceptanceYear as string}
        value={props.values.auctionParameterRealEstateFlat.acceptanceYear}
        onChange={props.handleChange}
        dataTestId="auctionParameterRealEstateFlat.acceptanceYear"
      />

      <FormGroup
        readOnly={props.readOnly}
        type="number"
        label="Rok rekonstrukce"
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_reconstructionYear as string}
        name="auctionParameterRealEstateFlat.reconstructionYear"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.reconstructionYear}
        dataTestId="auctionParameterRealEstateFlat.reconstructionYear"
      />

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Třída energetické náročnosti</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateFlat.parameterRealEstateEnergyEfficiencyRating
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateEnergyEfficiencyRating}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Zdroj vody</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatWaters">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWaters"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWaters || []).map(
                (v: { parameterRealEstateWater: string | number }) =>
                  waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWaters}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Rozvod topení</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatHeatings">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatHeatings"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatings}
              options={heatingTypeOptions}
              onChange={(val: BaseType) => handleRealEstateHeatingChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatHeatings || []).map(
                (v: { parameterRealEstateHeating: string | number }) =>
                  heatingTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeating))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatings && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatHeatings}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Rozvod plynu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatGases"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatGases || []).map(
                (v: { parameterRealEstateGas: string | number }) =>
                  gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatGases}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Typ odpadu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatWastes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatWastes"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatWastes || []).map(
                (v: { parameterRealEstateWaste: string | number }) =>
                  wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatWastes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Příjezdová komunikace</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateRoadType"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType}
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatRoadTypes || []).map(
                (v: { parameterRealEstateRoadType: string | number }) =>
                  roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateRoadType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Telekomunikace</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatTelecommunications">
            <Select
              isMulti
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateFlatTelecommunications"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications}
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTelecommunications}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Elektřina</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatElectricities">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatElectricities"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities}
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatElectricities || []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatElectricities}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Doprava</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlatTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateFlatTransports"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports}
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(props.values.auctionParameterRealEstateFlat.auctionParameterRealEstateFlatTransports || []).map(
                (v: { parameterRealEstateTransport: string | number }) =>
                  transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_auctionParameterRealEstateFlatTransports}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      {showAnnuity(getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateOwnership)) && (
        <div className="d-flex align-items-center mb-35">
          <FormGroup
            readOnly={props.readOnly}
            name="auctionParameterRealEstateFlat.annuity"
            thousandSeparator
            label="Anuita"
            error={props.errors.auctionParameterRealEstateFlat_annuity as string}
            className="mt-0 mb-0"
            labelClassName="text-left"
            controlClassName="w-max-205"
            value={props.values.auctionParameterRealEstateFlat.annuity}
            onValueChange={(val) => props.setFieldValue('auctionParameterRealEstateFlat.annuity', parseInt(val.value))}
            dataTestId="auctionParameterRealEstateFlat.annuity"
          />
        </div>
      )}

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          thousandSeparator
          name="auctionParameterRealEstateFlat.ceilingHeight"
          label="Výška stropu"
          error={props.errors.auctionParameterRealEstateFlat_ceilingHeight as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          value={props.values.auctionParameterRealEstateFlat.ceilingHeight}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateFlat.ceilingHeight', parseFloat(val.value))
          }
          dataTestId="auctionParameterRealEstateFlat.ceilingHeight"
        />
        <span className="f-size-12 f-weight-400">m</span>
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateFlat_costOfLiving as string}
        name="auctionParameterRealEstateFlat.costOfLiving"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateFlat.costOfLiving}
        dataTestId="auctionParameterRealEstateFlat.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Vybavení</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterRealEstateFlat.parameterRealEstateFurnished)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateFlat_parameterRealEstateFurnished}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Půdní vestavba</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateFlat.garret">
            <Select
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateFlat.garret"
              isInvalid={!!props.errors.auctionParameterRealEstateFlat_garret}
              options={yesNoOptions}
              value={yesNoOptions.find(
                (v) => v.value === (props.values.auctionParameterRealEstateFlat?.garret ? 'yes' : 'no')
              )}
              onChange={(val) => {
                props.setFieldValue('auctionParameterRealEstateFlat.garret', val?.value === 'yes');
              }}
            />
            {!!props.errors.auctionParameterRealEstateFlat_garret && (
              <ControlFeedback type="invalid">{props.errors.auctionParameterRealEstateFlat_garret}</ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
