import { ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AuctionParameterForm } from './AuctionParameterForm';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import { useAuctionParametersApi } from '@api/auctionParameters';
import {
  AuctionParametersResponse,
  AuctionParametersShortCutGroupResponse,
  AuctionParametersShortCutResponse,
} from '@types';
import { ValueType } from 'react-select';
import { getRequestDateFormat } from '@helpers/datetime';
import moment from 'moment';
import classNames from 'classnames';

interface SelectOptionType {
  value: string;
  label: string;
}

export const VehicleOtherForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [groupTypes, setGroupTypes] = useState<AuctionParametersResponse[]>([]);
  const [modelTypes, setModelTypes] = useState<AuctionParametersShortCutResponse[]>([]);
  const [brandTypes, setBrandTypes] = useState<AuctionParametersShortCutGroupResponse[]>([]);
  const [bodyTypes, setBodyTypes] = useState<AuctionParametersShortCutGroupResponse[]>([]);
  const [colorTypes, setColorTypes] = useState<AuctionParametersResponse[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<AuctionParametersResponse[]>([]);
  const [stateTypes, setStateTypes] = useState<AuctionParametersResponse[]>([]);
  const [fuelTypes, setFuelTypes] = useState<AuctionParametersResponse[]>([]);
  const [originCountryTypes, setOriginCountryTypes] = useState<AuctionParametersResponse[]>([]);

  const groupTypeOptions = groupTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const brandTypeOptions = brandTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const modelTypeOptions = modelTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const bodyTypeOptions = bodyTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const colorTypeOptions = colorTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const categoryTypeOptions = categoryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const stateTypeOptions = stateTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const fuelTypeOptions = fuelTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const originCountryTypeOptions = originCountryTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.values.auctionParameterVehicleOther.parameterVehicleCategory) {
      loadStates(props.values.auctionParameterVehicleOther.parameterVehicleCategory);
    } else {
      setStateTypes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.auctionParameterVehicleOther.parameterVehicleCategory]);

  const loadStates = async (category: string) => {
    try {
      const stateTypesResponse = await auctionParameterApi.getVehicleState(category);
      setStateTypes(stateTypesResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadData = async () => {
    try {
      const groupTypesResponse = await auctionParameterApi.getVehicleOtherGroup();
      setGroupTypes(groupTypesResponse.data.data);

      if (props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup) {
        loadBrandTypes(
          props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup.id ||
            props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup
        );
        loadBodyTypes(
          props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup.id ||
            props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup
        );
      }
      if (props.values.auctionParameterVehicleOther.parameterVehicleOtherBrand) {
        loadModelTypes(
          props.values.auctionParameterVehicleOther.parameterVehicleOtherBrand.id ||
            props.values.auctionParameterVehicleOther.parameterVehicleOtherBrand
        );
      }

      const vehicleAll = await auctionParameterApi.getVehicleAll();
      setColorTypes(vehicleAll.data.data.parameterVehicleColor);
      setCategoryTypes(vehicleAll.data.data.parameterVehicleCategory);
      setFuelTypes(vehicleAll.data.data.parameterVehicleFuel);
      setOriginCountryTypes(vehicleAll.data.data.parameterVehicleOriginCountry);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const loadBodyTypes = async (carGroup: string | number) => {
    try {
      const bodyTypesResponse = await auctionParameterApi.getVehicleOtherBodies(carGroup);
      setBodyTypes(bodyTypesResponse.data.data);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const loadBrandTypes = async (carGroup: string | number) => {
    try {
      const brandTypesResponse = await auctionParameterApi.getVehicleOtherBrand(carGroup);
      setBrandTypes(brandTypesResponse.data.data);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const loadModelTypes = async (carBrand: string | number) => {
    try {
      const modelTypesResponse = await auctionParameterApi.getVehicleOtherModels(carBrand);
      setModelTypes(modelTypesResponse.data.data);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleVehicleOtherGroupChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherGroup', itemValue?.value);
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherBrand', undefined);
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherModel', undefined);
    if (!!itemValue && itemValue.value) {
      loadBrandTypes(itemValue.value);
      loadBodyTypes(itemValue.value);
    } else {
      setBrandTypes([]);
      setModelTypes([]);
      setBodyTypes([]);
    }
  };

  const handleVehicleOtherBrandChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherBrand', itemValue?.value);
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherModel', undefined);
    if (!!itemValue && itemValue.value) {
      loadModelTypes(itemValue.value);
    } else {
      setModelTypes([]);
    }
  };

  const handleVehicleOtherBodyChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherBody', itemValue?.value);
  };

  const handleVehicleOtherColorChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleColor', itemValue?.value);
  };

  const handleVehicleOtherCategoryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleCategory', itemValue?.value);
    if (!itemValue?.value) {
      props.setFieldValue('auctionParameterVehicleOther.parameterVehicleState', undefined);
    }
  };

  const handleVehicleOtherStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleState', itemValue?.value);
  };

  const handleDateOfTechnicalInspectionChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(
        'auctionParameterVehicleOther.dateOfTechnicalInspection',
        !!inputValue ? getRequestDateFormat(inputValue) : ''
      );
    }
  };

  const handleVehicleOtherFuelChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleFuel', itemValue?.value);
  };

  const handleVehicleOtherOriginCountryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOriginCountry', itemValue?.value);
  };

  const handleVehicleOtherModelChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterVehicleOther.parameterVehicleOtherModel', itemValue?.value);
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Typ vozidla *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOtherGroup">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleOtherGroup"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherGroup}
              options={groupTypeOptions}
              onChange={handleVehicleOtherGroupChange}
              value={
                groupTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherGroup && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleOtherGroup}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Kategorie *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleCategory">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleCategory"
              options={categoryTypeOptions}
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleCategory}
              onChange={handleVehicleOtherCategoryChange}
              value={
                categoryTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleCategory)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleCategory && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleCategory}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Značka *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOtherBrand">
            <Select
              isClearable
              isDisabled={!props.values.auctionParameterVehicleOther.parameterVehicleOtherGroup || props.readOnly}
              size="md"
              name="parameterVehicleOtherBrand"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherBrand}
              options={brandTypeOptions}
              onChange={handleVehicleOtherBrandChange}
              value={
                brandTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleOtherBrand)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherBrand && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleOtherBrand}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      {!!modelTypeOptions && modelTypeOptions.length > 0 && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Model *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterVehicleOtherModel">
              <Select
                isClearable
                isDisabled={!props.values.auctionParameterVehicleOther.parameterVehicleOtherBrand || props.readOnly}
                size="md"
                name="parameterVehicleOtherModel"
                isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherModel}
                options={modelTypeOptions}
                onChange={handleVehicleOtherModelChange}
                value={
                  modelTypeOptions.find(
                    (i) =>
                      i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleOtherModel)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherModel && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterVehicleOther_parameterVehicleOtherModel}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Karoserie *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOtherBody">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleOtherBody"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherBody}
              options={bodyTypeOptions}
              onChange={handleVehicleOtherBodyChange}
              value={
                bodyTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleOtherBody)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleOtherBody && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleOtherBody}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <FormGroup
        readOnly={props.readOnly}
        label="Další charakteristiky"
        labelClassName="text-left"
        name="auctionParameterVehicleOther.description"
        error={props.errors.auctionParameterVehicleOther_description as string}
        onChange={props.handleChange}
        value={props.values.auctionParameterVehicleOther.description}
        dataTestId="auctionParameterVehicleOther.description"
      />
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Barva</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleColor">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleColor"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleColor}
              options={colorTypeOptions}
              onChange={handleVehicleOtherColorChange}
              value={
                colorTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleColor)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleColor && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleColor}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <FormGroup
        required={!!props.values.auctionParameterVehicleOther.vinPublic}
        readOnly={props.readOnly}
        label="VIN"
        labelClassName="text-left"
        name="auctionParameterVehicleOther.vin"
        error={props.errors.auctionParameterVehicleOther_vin as string}
        onChange={props.handleChange}
        value={props.values.auctionParameterVehicleOther.vin}
        dataTestId="auctionParameterVehicleOther.vin"
      />
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleOther.vinPublic">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="VIN veřejný (bude zobrazen v inzerátu)"
            className="mr-4"
            id="vinPublic"
            name="auctionParameterVehicleOther.vinPublic"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleOther.vinPublic', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleOther.vinPublic}
          />
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stav</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleState">
            <Select
              isClearable
              size="md"
              isDisabled={!props.values.auctionParameterVehicleOther.parameterVehicleCategory || props.readOnly}
              name="parameterVehicleState"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleState}
              options={stateTypeOptions}
              onChange={handleVehicleOtherStateChange}
              value={
                stateTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleState)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleState && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleState}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Stav tachometru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleOther.tachometer"
          error={props.errors.auctionParameterVehicleOther_tachometer as string}
          onChange={props.handleChange}
          value={props.values.auctionParameterVehicleOther.tachometer}
          dataTestId="auctionParameterVehicleOther.tachometer"
        />
        <span className="f-size-12 f-weight-400">km</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Rok výroby"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleOther.dateOfManufacture"
          value={props.values.auctionParameterVehicleOther.dateOfManufacture}
          error={props.errors.auctionParameterVehicleOther_dateOfManufacture as string}
          onChange={props.handleChange}
          dataTestId="auctionParameterVehicleOther.dateOfManufacture"
        />
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label">STK platná do</Form.Label>
        <div className="f-inline-control d-flex align-items-center">
          <div>
            <DatePickerInput
              readOnly={props.readOnly}
              className={classNames([
                'form-control',
                'w-max-140',
                { 'is-invalid': !!props.errors.auctionParameterVehicleOther_dateOfTechnicalInspection },
              ])}
              calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
              clearIcon={null}
              name="dateOfTechnicalInspection"
              onChange={(val) => handleDateOfTechnicalInspectionChange(val)}
              value={
                !!props.values.auctionParameterVehicleOther.dateOfTechnicalInspection
                  ? moment(props.values.auctionParameterVehicleOther.dateOfTechnicalInspection || '').toDate()
                  : undefined
              }
              data-test-id="dateOfTechnicalInspection"
            />
            {!!props.errors.auctionParameterVehicleOther_dateOfTechnicalInspection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_dateOfTechnicalInspection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Palivo</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleFuel">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleFuel"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleFuel}
              options={fuelTypeOptions}
              onChange={handleVehicleOtherFuelChange}
              value={
                fuelTypeOptions.find(
                  (i) => i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleFuel)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleFuel && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleFuel}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Výkon motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          name="auctionParameterVehicleOther.enginePower"
          error={props.errors.auctionParameterVehicleOther_enginePower as string}
          onChange={props.handleChange}
          value={props.values.auctionParameterVehicleOther.enginePower}
          dataTestId="auctionParameterVehicleOther.enginePower"
        />
        <span className="f-size-12 f-weight-400">kW</span>
      </div>
      <div className="d-flex align-items-center mb-35">
        <FormGroup
          readOnly={props.readOnly}
          type="number"
          label="Objem motoru"
          className="mt-0 mb-0"
          labelClassName="text-left"
          error={props.errors.auctionParameterVehicleOther_engineCapacity as string}
          name="auctionParameterVehicleOther.engineCapacity"
          onChange={props.handleChange}
          value={props.values.auctionParameterVehicleOther.engineCapacity}
          dataTestId="auctionParameterVehicleOther.engineCapacity"
        />
        <span className="f-size-12 f-weight-400">ccm</span>
      </div>
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stát původu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterVehicleOriginCountry">
            <Select
              isClearable
              isDisabled={props.readOnly}
              size="md"
              name="parameterVehicleOriginCountry"
              isInvalid={!!props.errors.auctionParameterVehicleOther_parameterVehicleOriginCountry}
              options={originCountryTypeOptions}
              onChange={handleVehicleOtherOriginCountryChange}
              value={
                originCountryTypeOptions.find(
                  (i) =>
                    i.value === getSelectValue(props.values.auctionParameterVehicleOther.parameterVehicleOriginCountry)
                ) || null
              }
            />
            {!!props.errors.auctionParameterVehicleOther_parameterVehicleOriginCountry && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterVehicleOther_parameterVehicleOriginCountry}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleOther.firstOwner">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="První majitel"
            className="mr-4"
            id="firstOwner"
            name="auctionParameterVehicleOther.firstOwner"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleOther.firstOwner', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleOther.firstOwner}
          />
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleOther.serviceBook">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Servisní knížka"
            className="mr-4"
            id="serviceBook"
            name="auctionParameterVehicleOther.serviceBook"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleOther.serviceBook', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleOther.serviceBook}
          />
        </div>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterVehicleOther.undamaged">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Nebourané"
            className="mr-4"
            id="undamaged"
            name="auctionParameterVehicleOther.undamaged"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterVehicleOther.undamaged', e.target.checked);
            }}
            checked={props.values.auctionParameterVehicleOther.undamaged}
          />
        </div>
      </Form.Group>
    </>
  );
};
