import { FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import { BasePreloader, Button, FormGroup } from '@components';
import { useHelpRequestApi } from '@api/helpRequest';

const initialFormValues = {
  to: 'exdrazby',
  name: '',
  email: '',
  phone: '',
  message: '',
};

export const ContactUs = () => {
  const helpRequestApi = useHelpRequestApi();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    try {
      await helpRequestApi.sendForWeb(formik.values);
      formik.setSubmitting(false);
      formik.setValues(initialFormValues);
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <div className="contact-us">
      <div className="contact-item">
        <Form onSubmit={(e: FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
          <h2 className="heading">Jak Vám můžeme pomoci?</h2>
          <div className="pt-2">
              <FormGroup
                required={true}
                name="name"
                label="Jméno a příjmení"
                labelClassName="text-left"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.errors.name as string}
              />
              <FormGroup
                required={true}
                name="email"
                label="E-mail"
                labelClassName="text-left"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email as string}
              />
              <FormGroup
                required={true}
                name="phone"
                label="Tel.:"
                labelClassName="text-left"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.errors.phone as string}
              />
            <FormGroup
              rows={4}
              as="textarea"
              name="message"
              label="Vaše zpráva"
              className="f-align-start"
              labelClassName="text-left"
              controlClassName="w-max-100p b-radius-20 noresize"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.errors.message as string}
            />
            <div className="mt-2">
              {!formik.isSubmitting ? (
                <Button type="submit" variant="btn-outline-primary" disabled={formik.isSubmitting}>
                  Odeslat
                </Button>
              ) : (
                <BasePreloader size={29} className="d-inline-block" />
              )}
              <div className="clearfix" />
            </div>
          </div>
        </Form>
      </div>
      <div className="contact-item contact-item--image" />
    </div>
  );
};
