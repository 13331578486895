import React from 'react';
import clx from 'classnames';

export interface TableColumnSortProps {
  property: string,
  direction: string,
}

export interface TableColumnProps {
  title: string | React.ReactNode;
  sort?: TableColumnSortProps;
  className?: string;
  sortField?: string;
  onSortChange?: (sort: TableColumnSortProps) => void;
}

const TableColumn: React.FC<TableColumnProps> = (props) => {
  const handleSortClick = (fieldName?: string) => {
    if (!fieldName || !props.sort || !props.onSortChange) {
      return;
    }

    if (props.sort.property === fieldName && props.sort.direction === 'ASC') {
      props.sort.direction = "DESC";
    } else {
      props.sort.direction = "ASC";
    }

    props.sort.property = fieldName;

    props.onSortChange(props.sort)
  };

  return (
    <th
      className={clx([
        props.className,
        {
          "stc": !!props.sortField,
          "ASC": props.sort?.property === props.sortField && props.sort?.direction === "ASC",
          "DESC": props.sort?.property === props.sortField && props.sort?.direction === "DESC"
        }
      ])}
      onClick={() => handleSortClick(props.sortField)}
    >
      {props.title}
    </th>
  );
};

export default TableColumn;
