import { API, APIResponse, useApi } from '@api/api';
import {
  AuctionParametersResponse,
  AuctionParametersShortCutResponse,
  AuctionParametersShortCutGroupResponse,
  AuctionRealEstateAllParams,
  AuctionRealEstateFlatParams,
  AuctionRealEstateHouseParams,
  AuctionVehicleCarParams,
  AuctionVehicleAllParams,
  AuctionVehicleMotorcycleParams,
} from '@types';

// Real Estate params
export const realEstateAll = () => `/admin/parameter/real-estate/all`;
export const realEstateFlatAll = () => `/admin/parameter/real-estate/flat/all`;
export const realEstateHouseAll = () => `/admin/parameter/real-estate/house/all`;
export const vehicleAll = () => `/admin/parameter/vehicle/all`;
export const vehicleCarAll = () => `/admin/parameter/vehicle/car/all`;
export const vehicleMotorcycleAll = () => `/admin/parameter/vehicle/motorcycle/all`;

export const realEstateElectricity = () => `/admin/parameter/real-estate/electricity`;
export const realEstateGas = () => `/admin/parameter/real-estate/gas`;
export const realEstatePlacement = () => `/admin/parameter/real-estate/placement`;
export const realEstateWaste = () => `/admin/parameter/real-estate/waste`;
export const realEstateWater = () => `/admin/parameter/real-estate/water`;
export const realEstateEnergyEfficiencyRating = () => `/admin/parameter/real-estate/energy-efficiency-rating`;
export const realEstateFurnished = () => `/admin/parameter/real-estate/furnished`;
export const realEstateProtection = () => `/admin/parameter/real-estate/protection`;
export const realEstateRoadType = () => `/admin/parameter/real-estate/road-type`;
export const realEstateSurroundingsType = () => `/admin/parameter/real-estate/surroundings-type`;
export const realEstateTelecommunication = () => `/admin/parameter/real-estate/telecommunication`;
export const realEstateTransport = () => `/admin/parameter/real-estate/transport`;
// Real Estate House params
export const realEstateHouseBuildingConstruction = () => `/admin/parameter/real-estate/building-construction`;
export const realEstateHouseContractType = () => `/admin/parameter/real-estate/contract-type`;
export const realEstateHouseHeating = () => `/admin/parameter/real-estate/heating`;
export const realEstateHouseLocationObject = () => `/admin/parameter/real-estate/house/location-object`;
export const realEstateHouseObjectStatus = () => `/admin/parameter/real-estate/object-status`;
export const realEstateHouseOwnership = () => `/admin/parameter/real-estate/ownership`;
export const realEstateHouseParking = () => `/admin/parameter/real-estate/parking`;
export const realEstateHouseType = (category: string) => `/admin/parameter/real-estate/house/type/${category}`;
export const realEstateHouseObjectType = () => `/admin/parameter/real-estate/house/object-type`;
export const realEstateCommercialObjectType = () => `/admin/parameter/real-estate/commercial/object-type`;
export const realEstateOtherObjectType = () => `/admin/parameter/real-estate/other/object-type`;
export const realEstateOtherCategories = () => `/admin/parameter/real-estate/other/category`;
export const realEstateHouseRoomCount = () => `/admin/parameter/real-estate/house/room-count`;
// Real Estate Land params
export const realEstateLandGroup = (category: string) => `/admin/parameter/real-estate/land/group/${category}`;
// Real Estate Flat params
export const realEstateFlatClass = () => `/admin/parameter/real-estate/flat/flat-class`;
export const realEstateFlatType = () => `/admin/parameter/real-estate/flat/type`;
// Vehicle Car params
export const vehicleCarBody = () => `/admin/parameter/vehicle/car/body`;
export const vehicleCarBrand = () => `/admin/parameter/vehicle/car/brand`;
export const vehicleCarModel = () => `/admin/parameter/vehicle/car/model`;
export const vehicleCarModelBrand = (brandId: number | string) => `/admin/parameter/vehicle/car/model/${brandId}`;
// Vehicle params
export const vehicleColor = () => `/admin/parameter/vehicle/color`;
export const vehicleFuel = () => `/admin/parameter/vehicle/fuel`;
export const vehicleOriginCountry = () => `/admin/parameter/vehicle/origin-country`;
export const vehicleState = (parameterVehicleCategoryId: string) =>
  `/admin/parameter/vehicle/state/${parameterVehicleCategoryId}`;
export const vehicleCategory = () => `/admin/parameter/vehicle/category`;
// Vehicle Motorcycle params
export const vehicleMotorcycleBody = () => `/admin/parameter/vehicle/motorcycle/body`;
export const vehicleMotorcycleBrand = () => `/admin/parameter/vehicle/motorcycle/brand`;
// Vehicle Other params
export const vehicleOtherBody = (groupId: number | string) => `/admin/parameter/vehicle/other/body/${groupId}`;
export const vehicleOtherBodyGroup = (groupId: number | string) => `/admin/parameter/vehicle/other/body/${groupId}`;
export const vehicleOtherBrand = () => `/admin/parameter/vehicle/other/brand`;
export const vehicleOtherBrandGroup = (groupId: number | string) => `/admin/parameter/vehicle/other/brand/${groupId}`;
export const vehicleOtherGroup = () => `/admin/parameter/vehicle/other/group`;
export const vehicleOtherModelBrand = (brandId: number | string) => `/admin/parameter/vehicle/other/model/${brandId}`;

export interface AuctionCategoriesAPI extends API {
  getRealEstateAll(): APIResponse<AuctionRealEstateAllParams>;
  getRealEstateFlatAll(): APIResponse<AuctionRealEstateFlatParams>;
  getRealEstateHouseAll(): APIResponse<AuctionRealEstateHouseParams>;
  getVehicleAll(): APIResponse<AuctionVehicleAllParams>;
  getVehicleCarAll(): APIResponse<AuctionVehicleCarParams>;
  getVehicleMotorcycleAll(): APIResponse<AuctionVehicleMotorcycleParams>;

  getRealEstateElectricity(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateGas(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstatePlacement(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateWaste(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateWater(): APIResponse<Array<AuctionParametersResponse>>;
  //
  getRealEstateEnergyEfficiencyRating(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateFurnished(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateProtection(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateRoadType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateSurroundingsType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateTelecommunication(): APIResponse<Array<AuctionParametersResponse>>;
  //
  getRealEstateBuildingConstruction(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateContractType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateHeating(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateHouseLocationObject(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateObjectStatus(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateOwnership(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateParking(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateTransport(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateType(category: string): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateObjectType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateCommercialObjectType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateOtherObjectType(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateOtherCategories(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateLandGroup(category: string): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateFlatClass(): APIResponse<Array<AuctionParametersResponse>>;
  getRealEstateFlatType(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleCarBody(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleCarBrand(): APIResponse<Array<AuctionParametersShortCutResponse>>;
  getVehicleCarModels(): APIResponse<Array<AuctionParametersShortCutResponse>>;
  getVehicleCarModel(groupId: string | number): APIResponse<Array<AuctionParametersShortCutResponse>>;
  getVehicleColor(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleFuel(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleOriginCountry(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleCategory(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleState(parameterVehicleCategoryId: string): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleMotorcycleBody(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleMotorcycleBrand(): APIResponse<Array<AuctionParametersShortCutResponse>>;
  getVehicleOtherBodies(groupId: string | number): APIResponse<Array<AuctionParametersShortCutGroupResponse>>;
  getVehicleOtherBody(groupId: string | number): APIResponse<AuctionParametersShortCutGroupResponse>;
  getVehicleOtherBrands(): APIResponse<Array<AuctionParametersShortCutGroupResponse>>;
  getVehicleOtherBrand(groupId: string | number): APIResponse<Array<AuctionParametersShortCutGroupResponse>>;
  getVehicleOtherGroup(): APIResponse<Array<AuctionParametersResponse>>;
  getVehicleOtherModels(brandId: string | number): APIResponse<Array<AuctionParametersShortCutResponse>>;
  //
  getRealEstateRoomCount(): APIResponse<Array<AuctionParametersResponse>>;
}

export const useAuctionParametersApi = (): AuctionCategoriesAPI => {
  const api = useApi();

  return {
    ...api,
    getRealEstateAll() {
      const cancelToken = api.prepareCancelToken('getRealEstateAll');
      return api.authRequest.get(realEstateAll(), { cancelToken });
    },
    getRealEstateFlatAll() {
      const cancelToken = api.prepareCancelToken('getRealEstateFlatAll');
      return api.authRequest.get(realEstateFlatAll(), { cancelToken });
    },
    getRealEstateHouseAll() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseAll');
      return api.authRequest.get(realEstateHouseAll(), { cancelToken });
    },
    getVehicleAll() {
      const cancelToken = api.prepareCancelToken('getVehicleAll');
      return api.authRequest.get(vehicleAll(), { cancelToken });
    },
    getVehicleCarAll() {
      const cancelToken = api.prepareCancelToken('getVehicleCarAll');
      return api.authRequest.get(vehicleCarAll(), { cancelToken });
    },
    getVehicleMotorcycleAll() {
      const cancelToken = api.prepareCancelToken('getVehicleMotorcycleAll');
      return api.authRequest.get(vehicleMotorcycleAll(), { cancelToken });
    },
    getRealEstateElectricity() {
      const cancelToken = api.prepareCancelToken('getRealEstateElectricity');
      return api.authRequest.get(realEstateElectricity(), {
        cancelToken,
      });
    },
    getRealEstateGas() {
      const cancelToken = api.prepareCancelToken('getRealEstateGas');
      return api.authRequest.get(realEstateGas(), {
        cancelToken,
      });
    },
    getRealEstatePlacement() {
      const cancelToken = api.prepareCancelToken('getRealEstatePlacement');
      return api.authRequest.get(realEstatePlacement(), {
        cancelToken,
      });
    },
    getRealEstateWaste() {
      const cancelToken = api.prepareCancelToken('getRealEstateWaste');
      return api.authRequest.get(realEstateWaste(), {
        cancelToken,
      });
    },
    getRealEstateWater() {
      const cancelToken = api.prepareCancelToken('getRealEstateWater');
      return api.authRequest.get(realEstateWater(), {
        cancelToken,
      });
    },
    //
    getRealEstateEnergyEfficiencyRating() {
      const cancelToken = api.prepareCancelToken('getRealEstateEnergyEfficiencyRating');
      return api.authRequest.get(realEstateEnergyEfficiencyRating(), {
        cancelToken,
      });
    },
    getRealEstateFurnished() {
      const cancelToken = api.prepareCancelToken('getRealEstateFurnished');
      return api.authRequest.get(realEstateFurnished(), {
        cancelToken,
      });
    },
    getRealEstateProtection() {
      const cancelToken = api.prepareCancelToken('getRealEstateProtection');
      return api.authRequest.get(realEstateProtection(), {
        cancelToken,
      });
    },
    getRealEstateRoadType() {
      const cancelToken = api.prepareCancelToken('getRealEstateRoadType');
      return api.authRequest.get(realEstateRoadType(), {
        cancelToken,
      });
    },
    getRealEstateSurroundingsType() {
      const cancelToken = api.prepareCancelToken('getRealEstateSurroundingsType');
      return api.authRequest.get(realEstateSurroundingsType(), {
        cancelToken,
      });
    },
    getRealEstateTelecommunication() {
      const cancelToken = api.prepareCancelToken('getRealEstateTelecommunication');
      return api.authRequest.get(realEstateTelecommunication(), {
        cancelToken,
      });
    },
    //
    getRealEstateBuildingConstruction() {
      const cancelToken = api.prepareCancelToken('getrealEstateHouseBuildingConstruction');
      return api.authRequest.get(realEstateHouseBuildingConstruction(), {
        cancelToken,
      });
    },
    getRealEstateContractType() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseContractType');
      return api.authRequest.get(realEstateHouseContractType(), {
        cancelToken,
      });
    },
    getRealEstateHeating() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseHeating');
      return api.authRequest.get(realEstateHouseHeating(), {
        cancelToken,
      });
    },
    getRealEstateHouseLocationObject() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseLocationObject');
      return api.authRequest.get(realEstateHouseLocationObject(), {
        cancelToken,
      });
    },
    getRealEstateObjectStatus() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseObjectStatus');
      return api.authRequest.get(realEstateHouseObjectStatus(), {
        cancelToken,
      });
    },

    getRealEstateOwnership() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseOwnership');
      return api.authRequest.get(realEstateHouseOwnership(), {
        cancelToken,
      });
    },
    getRealEstateParking() {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseParking');
      return api.authRequest.get(realEstateHouseParking(), {
        cancelToken,
      });
    },
    getRealEstateTransport() {
      const cancelToken = api.prepareCancelToken('getRealEstateTransport');
      return api.authRequest.get(realEstateTransport(), {
        cancelToken,
      });
    },
    getRealEstateType(category: string) {
      const cancelToken = api.prepareCancelToken('getRealEstateHouseType');
      return api.authRequest.get(realEstateHouseType(category), {
        cancelToken,
      });
    },
    getRealEstateObjectType() {
      const cancelToken = api.prepareCancelToken('getRealObjectType');
      return api.authRequest.get(realEstateHouseObjectType(), {
        cancelToken,
      });
    },
    getRealEstateCommercialObjectType() {
      const cancelToken = api.prepareCancelToken('getRealEstateCommercialObjectType');
      return api.authRequest.get(realEstateCommercialObjectType(), {
        cancelToken,
      });
    },
    getRealEstateOtherObjectType() {
      const cancelToken = api.prepareCancelToken('getRealEstateOtherObjectType');
      return api.authRequest.get(realEstateOtherObjectType(), {
        cancelToken,
      });
    },
    getRealEstateOtherCategories() {
      const cancelToken = api.prepareCancelToken('getRealEstateOtherCategories');
      return api.authRequest.get(realEstateOtherCategories(), {
        cancelToken,
      });
    },
    getRealEstateLandGroup(category: string) {
      const cancelToken = api.prepareCancelToken('getRealEstateLandGroup');
      return api.authRequest.get(realEstateLandGroup(category), {
        cancelToken,
      });
    },
    getRealEstateFlatClass() {
      const cancelToken = api.prepareCancelToken('getRealEstateFlatClass');
      return api.authRequest.get(realEstateFlatClass(), {
        cancelToken,
      });
    },
    getRealEstateFlatType() {
      const cancelToken = api.prepareCancelToken('getRealEstateFlatType');
      return api.authRequest.get(realEstateFlatType(), {
        cancelToken,
      });
    },
    getVehicleCarBody() {
      const cancelToken = api.prepareCancelToken('getVehicleCarBody');
      return api.authRequest.get(vehicleCarBody(), {
        cancelToken,
      });
    },
    getVehicleCarBrand() {
      const cancelToken = api.prepareCancelToken('getVehicleCarBrand');
      return api.authRequest.get(vehicleCarBrand(), {
        cancelToken,
      });
    },
    getVehicleCarModels() {
      const cancelToken = api.prepareCancelToken('getVehicleCarModel');
      return api.authRequest.get(vehicleCarModel(), {
        cancelToken,
      });
    },
    getVehicleCarModel(brandId: string | number) {
      const cancelToken = api.prepareCancelToken('getVehicleCarModelBrand');
      return api.authRequest.get(vehicleCarModelBrand(brandId), {
        cancelToken,
      });
    },
    getVehicleColor() {
      const cancelToken = api.prepareCancelToken('getVehicleColor');
      return api.authRequest.get(vehicleColor(), {
        cancelToken,
      });
    },
    getVehicleFuel() {
      const cancelToken = api.prepareCancelToken('getVehicleFuel');
      return api.authRequest.get(vehicleFuel(), {
        cancelToken,
      });
    },
    getVehicleOriginCountry() {
      const cancelToken = api.prepareCancelToken('getVehicleOriginCountry');
      return api.authRequest.get(vehicleOriginCountry(), {
        cancelToken,
      });
    },
    getVehicleCategory() {
      const cancelToken = api.prepareCancelToken('getVehicleCategory');
      return api.authRequest.get(vehicleCategory(), {
        cancelToken,
      });
    },
    getVehicleState(parameterVehicleCategoryId: string) {
      const cancelToken = api.prepareCancelToken('getVehicleState');
      return api.authRequest.get(vehicleState(parameterVehicleCategoryId), {
        cancelToken,
      });
    },
    getVehicleMotorcycleBody() {
      const cancelToken = api.prepareCancelToken('getVehicleMotorcycleBody');
      return api.authRequest.get(vehicleMotorcycleBody(), {
        cancelToken,
      });
    },
    getVehicleMotorcycleBrand() {
      const cancelToken = api.prepareCancelToken('getVehicleMotorcycleBrand');
      return api.authRequest.get(vehicleMotorcycleBrand(), {
        cancelToken,
      });
    },
    getVehicleOtherBodies(groupId: string | number) {
      const cancelToken = api.prepareCancelToken('getVehicleOtherBodies');
      return api.authRequest.get(vehicleOtherBody(groupId), {
        cancelToken,
      });
    },
    getVehicleOtherBody(groupId: string | number) {
      const cancelToken = api.prepareCancelToken('getVehicleOtherBody');
      return api.authRequest.get(vehicleOtherBodyGroup(groupId), {
        cancelToken,
      });
    },
    getVehicleOtherBrands() {
      const cancelToken = api.prepareCancelToken('getVehicleOtherBrands');
      return api.authRequest.get(vehicleOtherBrand(), {
        cancelToken,
      });
    },
    getVehicleOtherBrand(groupId: string | number) {
      const cancelToken = api.prepareCancelToken('getVehicleOtherBrand');
      return api.authRequest.get(vehicleOtherBrandGroup(groupId), {
        cancelToken,
      });
    },
    getVehicleOtherGroup() {
      const cancelToken = api.prepareCancelToken('getVehicleOtherGroup');
      return api.authRequest.get(vehicleOtherGroup(), {
        cancelToken,
      });
    },
    getVehicleOtherModels(brandId: string | number) {
      const cancelToken = api.prepareCancelToken('getVehicleOtherModels');
      return api.authRequest.get(vehicleOtherModelBrand(brandId), {
        cancelToken,
      });
    },
    getRealEstateRoomCount() {
      const cancelToken = api.prepareCancelToken('getRealEstateRoomCount');
      return this.authRequest.get(realEstateHouseRoomCount(), { cancelToken });
    },
  };
};
