import React from 'react';
import classNames from 'classnames';

import StepEmail from './StepEmail';
import StepBuyer from './StepBuyer';
import StepFinished from './StepFinished';

import stepUnfinishedIco from '@assets/images/step-unfinished.svg';
import stepFinishedIco from '@assets/images/step-finished.svg';
import stepCurrentIco from '@assets/images/step-current.svg';

interface Props {
  defaultEmail?: string;
  skipBasicRegistration?: boolean;
}

const RegistrationSteps: React.FC<Props> = (props) => {
  const [step, setStep] = React.useState(props.skipBasicRegistration ? 1 : 0);
  const [email, setEmail] = React.useState<string>(props.defaultEmail ? props.defaultEmail : '');
  const [isFinished, setIsFinished] = React.useState(false);
  const [isFinishedBuyer, setIsFinishedBuyer] = React.useState(false);
  const [aggreeValues, setAggreeValues] = React.useState({ tnc: false, privacyPolicy: false, marketing: false });

  const stepItems = [
    {
      step: 0,
      title: 'Základní registrace',
      onClick: () => setStep(0),
    },
    {
      step: 1,
      title: 'Registrace dražitele',
    },
  ];

  const handleBasicRegistration = (email: string) => {
    setEmail(email);
    setIsFinishedBuyer(false);
    setIsFinished(true);
    window.scrollTo(0, 0);
  };

  const handleBuyerRegistration = (email: string) => {
    setEmail(email);
    setStep(1);
  };

  const handleBuyerSuccess = (email: string) => {
    setEmail(email);
    setIsFinishedBuyer(true);
    setIsFinished(true);
    window.scrollTo(0, 0);
  };

  const handleAggreeValueChange = (prop: string, value: boolean) => {
    setAggreeValues({ ...aggreeValues, [prop]: value });
  };

  const renderStepItems = () => {
    return (
      <div className="step-items">
        {stepItems.map((item, index) => {
          let itemIcon = stepUnfinishedIco;
          if (item.step === step) {
            itemIcon = stepCurrentIco;
          } else if (item.step < step) {
            itemIcon = stepFinishedIco;
          }

          return (
            <>
              {index > 0 && <div className="step-divider" />}
              <div
                className={classNames([
                  'step-item',
                  { 'cursor-pointer': !props.skipBasicRegistration && !!item.onClick && item.step !== step },
                ])}
                onClick={
                  !props.skipBasicRegistration && !!item.onClick && item.step !== step
                    ? () => item.onClick()
                    : undefined
                }
              >
                <img className="step-item-icon" src={itemIcon} alt="ico" />
                <div
                  className={classNames([
                    'step-item-title',
                    {
                      'text-underline': !!item.onClick && item.step !== step,
                    },
                  ])}
                >
                  {item.title}
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div className="registration-steps">
      {isFinished ? (
        <StepFinished isBuyer={isFinishedBuyer} />
      ) : (
        <>
          {renderStepItems()}
          {step === 0 && (
            <StepEmail
              email={email}
              aggreeValues={aggreeValues}
              onAggreeValueChange={handleAggreeValueChange}
              onBasicRegistration={handleBasicRegistration}
              onBuyerRegistration={handleBuyerRegistration}
            />
          )}
          {step === 1 && (
            <StepBuyer
              email={email}
              aggreeValues={aggreeValues}
              onSuccess={handleBuyerSuccess}
              isRegistration={!props.skipBasicRegistration}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RegistrationSteps;
