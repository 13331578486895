import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useVisibility, Visible } from '@components';
import { PermissionGroup, User, UserPermission } from '@types';
import { UserSections } from '../../UserUpdatePage/UserUpdatePage';
import { useCurrentUserRoleChecks, useUserRegistrationStatus, useUserTypes } from '@stores/users';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoRestore from '@assets/images/color-ico-restore.svg';
import colorIcoDocument from '@assets/images/color-ico-document.svg';
import { getDateTimeFormat } from '@helpers/datetime';

interface Props {
  data: Array<User>;
  onDeleteClick: (item: User) => void;
  onRestoreClick: (item: User) => void;
}

const ItemsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const { shouldBeVisible } = useVisibility();
  const currentUserRoleChecks = useCurrentUserRoleChecks();

  const { userTypes } = useUserTypes();
  const { resolveRegistrationStatus } = useUserRegistrationStatus();

  const handleDeleteClick = (e: React.MouseEvent, item: User) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleRestoreClick = (e: React.MouseEvent, item: User) => {
    e.preventDefault();
    props.onRestoreClick(item);
  };

  const resolveUserType = (userType: string) => {
    for (const index in userTypes) {
      if (userTypes[index].value === userType) {
        return userTypes[index].label;
      }
    }
    return '-';
  };

  const renderDeleteItem = (item: User): React.ReactNode => {
    if (!shouldBeVisible({ permissionGroupName: PermissionGroup.buyers, permissionName: UserPermission.canDelete })) {
      return null;
    }
    if (item.state !== 'deleted') {
      return (
        <Link
          className="d-inline-flex align-items-center ml-4"
          to="/"
          onClick={(e) => handleDeleteClick(e, item)}
          data-test-id="admin-buyers-table-column-actions-delete"
        >
          <img src={colorIcoDel} alt="ico" className="mr-2" />
          Smazat
        </Link>
      );
    }
    if (item.state === 'deleted' && currentUserRoleChecks.isOfRoleSuperAdmin()) {
      return (
        <Link
          className="d-inline-flex align-items-center ml-4"
          to="/"
          onClick={(e) => handleRestoreClick(e, item)}
          data-test-id="admin-buyers-table-column-actions-restore"
        >
          <img src={colorIcoRestore} alt="ico" className="mr-2" />
          Obnovit
        </Link>
      );
    }
    return null;
  };

  return (
    <div className="responsive-table-content">
      <Table striped className="table-middle" data-test-id="admin-buyers-table">
        <thead>
          <tr>
            <th>Identifikační číslo</th>
            <th>Registrace</th>
            <th>Kontakt</th>
            <th>Tel.</th>
            <th>E-mail</th>
            <th>Druh dražitele</th>
            <th>Úroveň registrace</th>
            <th>Stav</th>
            <th className="text-center">Dokumenty</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`} data-test-id={`admin-buyers-table-row-${index}`}>
              <td data-test-id="admin-buyers-table-column-id">{item.id}</td>
              <td data-test-id="admin-buyers-table-column-created">{getDateTimeFormat(item.timeCreated)}</td>
              <td data-test-id="admin-buyers-table-column-name">{item.subjectName || '-'}</td>
              <td data-test-id="admin-buyers-table-column-phone">{item.phone || '-'}</td>
              <td data-test-id="admin-buyers-table-column-email">{item.email || '-'}</td>
              <td data-test-id="admin-buyers-table-column-type">{resolveUserType(item.buyerType as string)}</td>
              <td data-test-id="admin-buyers-table-column-status">{resolveRegistrationStatus(item)}</td>
              <td data-test-id="admin-buyers-table-column-state">{item.stateTranslation}</td>
              <td className="text-center" data-test-id="admin-buyers-table-column-actions">
                {item.userDocuments && item.userDocuments.length > 0 ? (
                  <Link
                    type="button"
                    className="btn btn-sm btn-light"
                    data-test-id="admin-buyers-table-column-actions-documents"
                    to={`${pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': item.id.toString() })}?section=${
                      UserSections.documents
                    }`}
                  >
                    <img src={colorIcoDocument} alt="ico" />
                  </Link>
                ) : (
                  '-'
                )}
              </td>
              <td className="text-right">
                <Visible permissionName={UserPermission.canView}>
                  <Link
                    className="d-inline-flex align-items-center"
                    to={pageState.getPagePath(routes.admin.USER_DETAIL, { ':id': item.id.toString() })}
                    data-test-id="admin-buyers-table-column-actions-detail"
                  >
                    <img src={colorIcoEye} alt="ico" className="mr-2" />
                    Detail
                  </Link>
                </Visible>
                <Visible permissionName={UserPermission.canEdit}>
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    to={pageState.getPagePath(routes.admin.USER_UPDATE, { ':id': item.id.toString() })}
                    data-test-id="admin-buyers-table-column-actions-update"
                  >
                    <img src={colorIcoConf} alt="ico" className="mr-2" />
                    Upravit
                  </Link>
                </Visible>
                {renderDeleteItem(item)}
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr data-test-id="admin-buyers-table-empty">
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;
