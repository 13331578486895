import { AxiosResponse } from 'axios';
import { API, APIResponse, useApi } from '@api/api';
import { useGridFilter } from '@stores/gridFilters';
import { DocumentsFilters, UserDocument } from '@types';

export const documentsPath = () => `/admin/user/document/list`;
export const userDocumentsPath = (userId: string | number) => `/admin/user/${userId}/active-document`;
export const approveDocumentsPath = (documentId: string | number) => `/admin/user/document/${documentId}/approved`;
export const rejectDocumentsPath = (documentId: string | number) => `/admin/user/document/${documentId}/rejection`;
export const getDocumentPath = (documentId: string | number, userId: string | number) =>
  `/admin/user/${userId}/document/${documentId}`;

export interface DocumentsAPI extends API {
  list(filters: DocumentsFilters): APIResponse<Array<UserDocument>>;
  userDocumentsList(userId: string | number): APIResponse<Array<UserDocument>>;
  approve(documentId: string | number, validity: string): APIResponse;
  reject(documentId: string | number, reason?: string): APIResponse;
  getUserDocument(documentId: string | number, userId: string | number): Promise<AxiosResponse>;
}

export const useDocumentsApi = (): DocumentsAPI => {
  const api = useApi();
  const documentsFilter = useGridFilter<DocumentsFilters>([
    {
      key: 'state',
      property: 'userDocument.state',
    },
    {
      group: 'alpha',
      key: 'name',
      operator: 'like',
      property: 'user.firstName',
      splitKeyWords: true,
    },
    {
      group: 'alpha',
      key: 'name',
      operator: 'like',
      property: 'user.lastName',
      splitKeyWords: true,
    },
    {
      group: 'alpha',
      key: 'userId',
      operator: 'eq',
      property: 'user.id',
    },
    {
      group: 'alpha',
      key: 'email',
      operator: 'like',
      property: 'user.email',
    },
  ]);

  return {
    ...api,
    list(params: DocumentsFilters) {
      const cancelToken = api.prepareCancelToken('list');
      return api.authRequest.get(documentsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          filter: documentsFilter.isEmpty(params) ? undefined : documentsFilter.toString(params),
        },
      });
    },
    userDocumentsList(userId: string | number) {
      const cancelToken = api.prepareCancelToken('userDocumentsList');
      return api.authRequest.get(userDocumentsPath(userId), { cancelToken });
    },
    approve(documentId: string | number, validity: string) {
      const cancelToken = api.prepareCancelToken('approve');
      return api.authRequest.put(approveDocumentsPath(documentId), { validity }, { cancelToken });
    },
    reject(documentId: string | number, reason?: string) {
      const cancelToken = api.prepareCancelToken('reject');
      return api.authRequest.put(rejectDocumentsPath(documentId), { reason }, { cancelToken });
    },
    getUserDocument(documentId: string | number, userId: string | number) {
      const cancelToken = api.prepareCancelToken('getUserDocument');
      return api.authRequest.get(getDocumentPath(documentId, userId), {
        cancelToken,
        params: { view: true },
        responseType: 'blob',
      });
    },
  };
};
