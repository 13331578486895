import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useAuctionsApi } from '@api/auctions';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { getRequestDateFormat } from '@helpers/datetime';
import { BasePreloader, Button, ControlFeedback, DatePickerInput, FormGroup } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import { AuctionFieldsEnabled } from '@types';

interface Props {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  fieldsEnabled?: AuctionFieldsEnabled;
  auctionType: 'auction' | 'auction_public';
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const DetailsSection: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();

  React.useEffect(() => {
    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleMaxNumberChange = (e: React.ChangeEvent<HTMLInputElement>, max: number) => {
    if (!!e.target.value && parseInt(e.target.value) > max) {
      e.target.value = max.toString();
    }
    props.handleChange(e);
  };

  const handleTimeFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (String(props.values[fieldName]) === '0') {
      props.setFieldValue(fieldName, '');
    }
  };

  const handleTimeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (/^\s*$/.test(props.values[fieldName])) {
      props.setFieldValue(fieldName, '0');
    }
  };

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_detail_title')}</h2>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-40">
          <div className="responsive-table-content">
            <div className="pt-2">
              {props.values.status !== 'adjourned' && (
                <>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label">
                      {props.getTranslation('tab_detail_label_start_dt')}
                    </Form.Label>
                    <div className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                      <div className="position-relative">
                        <DatePickerInput
                          readOnly={!_.get(props.fieldsEnabled, 'startDt', true)}
                          name="startDt"
                          className={classNames([
                            'form-control',
                            'w-max-140',
                            { 'is-invalid': !!props.errors.startDt },
                          ])}
                          calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                          clearIcon={null}
                          onChange={(val) => handleDateChange('startDt', val)}
                          value={!!props.values.startDt ? moment(props.values.startDt || '').toDate() : undefined}
                        />
                        {!!props.errors.startDt && (
                          <div className="auction-date-error">
                            <ControlFeedback type="invalid">{props.errors.startDt}</ControlFeedback>
                          </div>
                        )}
                      </div>
                      <div className="auction-time-picker">
                        <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                        <FormGroup
                          type="number"
                          name="startDtHour"
                          readOnly={!_.get(props.fieldsEnabled, 'startDt', true)}
                          controlOnly={true}
                          controlClassName="w-max-70 text-center"
                          value={props.values.startDtHour}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 23)}
                          onFocus={handleTimeFocus}
                          onBlur={handleTimeBlur}
                        />
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                        <FormGroup
                          type="number"
                          name="startDtMinutes"
                          controlOnly={true}
                          readOnly={!_.get(props.fieldsEnabled, 'startDt', true)}
                          controlClassName="w-max-70 text-center"
                          value={props.values.startDtMinutes}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 59)}
                          onFocus={handleTimeFocus}
                          onBlur={handleTimeBlur}
                        />
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_detail_label_end_dt')}
                    </Form.Label>
                    <div className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                      <div className="position-relative">
                        <DatePickerInput
                          name="endDt"
                          readOnly={!_.get(props.fieldsEnabled, 'endDt', true)}
                          className={classNames(['form-control', 'w-max-140', { 'is-invalid': !!props.errors.endDt }])}
                          calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                          clearIcon={null}
                          onChange={(val) => handleDateChange('endDt', val)}
                          value={!!props.values.endDt ? moment(props.values.endDt || '').toDate() : undefined}
                        />
                        {!!props.errors.endDt && (
                          <div className="auction-date-error">
                            <ControlFeedback type="invalid">{props.errors.endDt}</ControlFeedback>
                          </div>
                        )}
                      </div>
                      <div className="auction-time-picker">
                        <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                        <FormGroup
                          type="number"
                          name="endDtHour"
                          controlOnly={true}
                          controlClassName="w-max-70 text-center"
                          value={props.values.endDtHour}
                          readOnly={!_.get(props.fieldsEnabled, 'endDt', true)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 23)}
                          onFocus={handleTimeFocus}
                          onBlur={handleTimeBlur}
                        />
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                        <FormGroup
                          type="number"
                          name="endDtMinutes"
                          controlOnly={true}
                          controlClassName="w-max-70 text-center"
                          value={props.values.endDtMinutes}
                          readOnly={!_.get(props.fieldsEnabled, 'endDt', true)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 59)}
                          onFocus={handleTimeFocus}
                          onBlur={handleTimeBlur}
                        />
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                      </div>
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  name="minimalBid"
                  label={props.getTranslation('tab_detail_label_minimal_bid')}
                  className="mt-0 mb-0"
                  labelClassName="text-left"
                  controlClassName="w-max-205"
                  readOnly={!_.get(props.fieldsEnabled, 'minimalBid', true)}
                  error={props.errors.minimalBid as string}
                  value={props.values.minimalBid}
                  onValueChange={(val) => props.setFieldValue('minimalBid', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  name="minimalThrow"
                  label={props.getTranslation('tab_detail_label_minimal_throw')}
                  className="mt-0 mb-0"
                  labelClassName="text-left"
                  controlClassName="w-max-205"
                  error={props.errors.minimalThrow as string}
                  value={props.values.minimalThrow}
                  readOnly={!_.get(props.fieldsEnabled, 'minimalThrow', true)}
                  onValueChange={(val) => props.setFieldValue('minimalThrow', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              {props.values.auctionType === 'auction' && (
                <div className="d-flex align-items-center mb-35">
                  <FormGroup
                    thousandSeparator
                    name="minPurchasePrice"
                    label={props.getTranslation('tab_detail_label_min_purchase_price')}
                    className="mt-0 mb-0"
                    labelClassName="text-left"
                    controlClassName="w-max-205"
                    error={props.errors.minPurchasePrice as string}
                    value={props.values.minPurchasePrice}
                    readOnly={!_.get(props.fieldsEnabled, 'minPurchasePrice', true)}
                    onValueChange={(val) => props.setFieldValue('minPurchasePrice', val.value)}
                  />
                  <span className="f-size-12 f-weight-400 ml-2">Kč</span>
                </div>
              )}
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  type="number"
                  name="estimatedPrice"
                  label={props.getTranslation('tab_detail_label_estimated_price')}
                  className="mt-0 mb-0"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-205"
                  error={props.errors.estimatedPrice as string}
                  value={props.values.estimatedPrice}
                  readOnly={!_.get(props.fieldsEnabled, 'estimatedPrice', true)}
                  onValueChange={(val) => props.setFieldValue('estimatedPrice', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  thousandSeparator
                  type="number"
                  name="cautionDeposit"
                  label={props.getTranslation('tab_detail_label_caution_deposit')}
                  className="mt-0 mb-0"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-205"
                  error={props.errors.cautionDeposit as string}
                  value={props.values.cautionDeposit}
                  onChange={props.handleChange}
                  readOnly={!_.get(props.fieldsEnabled, 'cautionDeposit', true)}
                  onValueChange={(val) => props.setFieldValue('cautionDeposit', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <FormGroup
                name="webUrl"
                label={props.getTranslation('tab_detail_label_web_url')}
                labelClassName="text-left"
                error={props.errors.webUrl as string}
                value={props.values.webUrl}
                readOnly={!_.get(props.fieldsEnabled, 'webUrl', true)}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div>
            <p className="f-size-12 w-weight-400 text-color-gray-2">{props.getTranslation('tab_detail_help_text')}</p>
          </div>
          {!props.id && (
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!props.isSubmitting ? (
                  <Button type="submit">
                    {!!props.id
                      ? props.getTranslation('tab_detail_btn_save')
                      : props.getTranslation('tab_detail_btn_next')}
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default DetailsSection;
