import React, { useEffect, useState } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';

import { EnumType } from '@types';
import { useEnumApi } from '@api/enum';
import { useAuctionsApi } from '@api/auctions';
import { BasePreloader, Button, Modal, Select } from '@components';

type Props = {
  isOpen: boolean;
  auctionId: string | number;
  onClose: () => void;
  onSuccess: () => void;
};

export const ExportInstagramModal: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const auctionsApi = useAuctionsApi();
  const [saved, setSaved] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [previews, setPreviews] = useState<EnumType[]>([]);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [currentPreview, setCurrentPreview] = useState<string>();
  const [previewImage, setPreviewImage] = useState<string>();
  const previewOptions = previews.map((p) => ({
    value: p.type,
    label: p.translated,
  }));

  useEffect(() => {
    loadEnum();
    return () => {
      enumApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnum = async () => {
    try {
      setPreviews((await enumApi.getExportPreviewImages()).data.data);
      setIsLoaded(true);
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handlePreviewChange = async (val?: string) => {
    setCurrentPreview(val);
    if (!val) {
      setPreviewImage(undefined);
      return;
    }

    setPreviewLoaded(false);
    try {
      const res = await auctionsApi.auctionExportPreview(props.auctionId, val);
      setPreviewImage(res.data);
      setPreviewLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setPreviewImage(undefined);
      setPreviewLoaded(true);
    }
  };

  const handleSubmit = async () => {
    if (!currentPreview) {
      return;
    }
    setSaved(false);
    try {
      await auctionsApi.exportAuction(
        props.auctionId,
        'instagram',
        undefined,
        undefined,
        undefined,
        currentPreview
      );
      props.onSuccess();
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setSaved(true);
    }
  };

  const renderSection = () => {
    if (!isLoaded) {
      return (
        <div className="py-3 d-flex align-items-center">
          <BasePreloader />
        </div>
      );
    }
    return (
      <div>
        <FormGroup>
          <FormLabel>Šablona *</FormLabel>
          <Select
            options={previewOptions}
            value={previewOptions.find((v) => v.value === currentPreview) || null}
            onChange={(val) => handlePreviewChange(val?.value)}
          />
        </FormGroup>

        <div className="d-flex justify-content-end">
          {saved ? (
            <Button disabled={!currentPreview} onClick={handleSubmit}>
              Uložit
            </Button>
          ) : (
            <BasePreloader size={34} />
          )}
        </div>

        {currentPreview && !previewLoaded && (
          <div>
            <BasePreloader size={25} />
          </div>
        )}

        {previewImage && previewLoaded && (
          <div>
            <img src={`data:image/png;base64,${previewImage}`} style={{ maxWidth: '100%' }} alt={`auction-${props.auctionId}`} />
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal noPadding title="Instagram" isOpen={props.isOpen} onRequestClose={() => props.onClose()}>
      <div className="p-4">{renderSection()}</div>
    </Modal>
  );
};
