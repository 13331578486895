import { createStore, useStoreState } from './store';

export interface UrlSearchState {
  adminAuctioneerList: string;
  adminAuctionsList: string;
  adminUsersList: string;
  adminAuctionsDocuments: string;
  adminAuctionsSecurity: string;
  frontAccountAuctions: string;
  frontAuctioneerList: string;
}

export const urlSearchStore = createStore<UrlSearchState>({
  adminAuctioneerList: '',
  adminAuctionsList: '',
  adminUsersList: '',
  adminAuctionsDocuments: '',
  adminAuctionsSecurity: '',
  frontAccountAuctions: '',
  frontAuctioneerList: '',
});

export const useUrlSearchStore = () => {
  const urlSearchState = useStoreState(urlSearchStore)

  const clearAdmin = () => {
    urlSearchStore.setState({
      adminAuctioneerList: '',
      adminAuctionsList: '',
      adminUsersList: '',
      adminAuctionsDocuments: '',
      adminAuctionsSecurity: '',
    });
  };

  return {
    ...urlSearchState,
    clearAdmin
  };
};
