import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';
import React, { FC, useState } from 'react';

import { BaseType, AuctionParametersResponse } from '@types';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, FormGroup, Select } from '@components';

import { AuctionParameterForm } from './AuctionParameterForm';

interface SelectOptionType {
  value: string;
  label: string;
}

export const RealEstateCommercialForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [commercialObjectTypes, setCommercialObjectTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingTypes, setHeatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);

  const yesNoOptions = [
    { value: 'no', label: 'NE' },
    { value: 'yes', label: 'ANO' },
  ];

  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const heatingTypeOptions = heatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectTypeOptions = commercialObjectTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  React.useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const commercialObjectTypesResponse = await auctionParameterApi.getRealEstateCommercialObjectType();
      setCommercialObjectTypes(commercialObjectTypesResponse.data.data);

      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingTypes(realEstateRes.data.data.parameterRealEstateHeating);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
    } catch (e) {
      if (!e.response) {
        return;
      }
    }
  };

  const handleCommercialObjectTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateCommercialObjectType',
      itemValue?.value
    );
  };

  const handlePlacementChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleCommercialBuildingConstructionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateBuildingConstruction',
      itemValue?.value
    );
  };

  const handleCommercialObjectStatusChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRealEstateCommercialHeatingChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialHeatings || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatings',
      itemValue.map((i) => ({
        parameterRealEstateHeating: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeating.id || f.parameterRealEstateHeating) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWaterChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateElectricityChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateWasteChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWastes',
      itemValue.map((i) => ({
        parameterRealEstateWaste: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value
        )?.id,
      }))
    );
  };

  const handleRealEstateGasChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value
        )?.id,
      }))
    );
  };

  const handleCommercialParkingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateParking', itemValue?.value);
  };

  const handleTransportChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value
        )?.id,
      }))
    );
  };

  const handleFurnishedChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateFurnished', itemValue?.value);
  };

  const handleProtectionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value
        )?.id,
      }))
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateEnergyEfficiencyRating',
      itemValue?.value
    );
  };

  const handleTelecommucationChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues = props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value
        )?.id,
      }))
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Budova *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleCommercialBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateBuildingConstruction
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Stav *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleCommercialObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateObjectStatus)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Typ stavby *</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCommercialObjectType">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCommercialObjectType"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType}
              options={objectTypeOptions}
              onChange={handleCommercialObjectTypeChange}
              value={
                objectTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateCommercialObjectType
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="number"
        label="Počet podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateCommercial_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.numberOfFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateCommercial.numberOfFloors}
        dataTestId="auctionParameterRealEstateCommercial.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateCommercial_undergroundFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.undergroundFloors"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateCommercial.undergroundFloors}
        dataTestId="auctionParameterRealEstateCommercial.undergroundFloors"
      />

      <FormGroup
        type="number"
        label="Umístění podlaží"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.floorNumber"
        value={props.values.auctionParameterRealEstateCommercial.floorNumber}
        error={props.errors.auctionParameterRealEstateCommercial_floorNumber as string}
        onChange={props.handleChange}
        dataTestId="auctionParameterRealEstateCommercial.floorNumber"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Výtah</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercial.elevator">
            <Select
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateCommercial.elevator"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_elevator}
              options={yesNoOptions}
              value={yesNoOptions.find(
                (v) => v.value === (props.values.auctionParameterRealEstateCommercial?.elevator ? 'yes' : 'no')
              )}
              onChange={(val) => {
                props.setFieldValue('auctionParameterRealEstateCommercial.elevator', val?.value === 'yes');
              }}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_elevator && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_elevator}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Bezbariérový přístup</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercial.easyAccess">
            <Select
              isDisabled={props.readOnly}
              size="md"
              name="auctionParameterRealEstateCommercial.easyAccess"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_easyAccess}
              options={yesNoOptions}
              value={yesNoOptions.find(
                (v) => v.value === (props.values.auctionParameterRealEstateCommercial?.easyAccess ? 'yes' : 'no')
              )}
              onChange={(val) => {
                props.setFieldValue('auctionParameterRealEstateCommercial.easyAccess', val?.value === 'yes');
              }}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_easyAccess && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_easyAccess}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Umístění</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstatePlacement)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Okolní zástavba</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateSurroundingsType
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Ochrana</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateProtection)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          required
          name="auctionParameterRealEstateCommercial.usableArea"
          thousandSeparator
          label="Plocha užitná"
          error={props.errors.auctionParameterRealEstateCommercial_usableArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          value={props.values.auctionParameterRealEstateCommercial.usableArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.usableArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.builtUpArea"
          thousandSeparator
          label="Plocha zastavěná"
          error={props.errors.auctionParameterRealEstateCommercial_builtUpArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          value={props.values.auctionParameterRealEstateCommercial.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.gardenArea"
          error={props.errors.auctionParameterRealEstateCommercial_gardenArea as string}
          thousandSeparator
          label="Plocha zahrady"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          value={props.values.auctionParameterRealEstateCommercial.gardenArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.gardenArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.floorArea"
          error={props.errors.auctionParameterRealEstateCommercial_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          value={props.values.auctionParameterRealEstateCommercial.floorArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.floorArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <Form.Group className="d-flex align-items-center">
        <div className="mr-2" data-test-id="auctionParameterRealEstateCommercial.parkingExists">
          <Form.Check
            disabled={props.readOnly}
            custom
            type="checkbox"
            label="Parkování"
            className="mr-4"
            id="parkingExists"
            name="auctionParameterRealEstateCommercial.parkingExists"
            checked={props.values.auctionParameterRealEstateCommercial.parkingExists}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setFieldValue('auctionParameterRealEstateCommercial.parkingExists', e.target.checked);
            }}
          />
        </div>
      </Form.Group>

      {props.values.auctionParameterRealEstateCommercial.parkingExists && (
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
          <div className="f-inline-control">
            <div className="w-max-500" data-test-id="parameterRealEstateParking">
              <Select
                isClearable
                size="md"
                isDisabled={props.readOnly}
                name="parameterRealEstateParking"
                isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking}
                options={parkingTypeOptions}
                onChange={handleCommercialParkingChange}
                value={
                  parkingTypeOptions.find(
                    (i) =>
                      i.value ===
                      getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateParking)
                  ) || null
                }
              />
              {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking && (
                <ControlFeedback type="invalid">
                  {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking}
                </ControlFeedback>
              )}
            </div>
          </div>
        </Form.Group>
      )}

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Termíny</h3>
      </div>

      <FormGroup
        type="number"
        label="Rok výstavby"
        readOnly={props.readOnly}
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateCommercial_objectYear as string}
        name="auctionParameterRealEstateCommercial.objectYear"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateCommercial.objectYear}
        dataTestId="auctionParameterRealEstateCommercial.objectYear"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Třída energetické náročnosti</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateEnergyEfficiencyRating
                    )
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Zdroj vody</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWater">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWater"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaters || []
              ).map((v: { parameterRealEstateWater: string | number }) =>
                waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Rozvod topení</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeating">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateHeating"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatings
              }
              options={heatingTypeOptions}
              onChange={(val: BaseType) => handleRealEstateCommercialHeatingChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatings || []
              ).map((v: { parameterRealEstateHeating: string | number }) =>
                heatingTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeating))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatings && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatings}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Rozvod plynu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialGases"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialGases || []
              ).map((v: { parameterRealEstateGas: string | number }) =>
                gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Typ odpadu</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaste">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWaste"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWastes || []
              ).map((v: { parameterRealEstateWaste: string | number }) =>
                wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Příjezdová komunikace</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateRoadType"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes
              }
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialRoadTypes || []
              ).map((v: { parameterRealEstateRoadType: string | number }) =>
                roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Telekomunikace</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialTelecommunications">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialTelecommunications"
              isInvalid={
                !!props.errors
                  .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications
              }
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial
                  .auctionParameterRealEstateCommercialTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication)
                )
              )}
            />
            {!!props.errors
              .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Elektřina</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateElectricity">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateElectricity"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities
              }
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialElectricities ||
                []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Doprava</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialTransports"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports
              }
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTransports || []
              ).map((v: { parameterRealEstateTransport: string | number }) =>
                transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport))
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateCommercial_costOfLiving as string}
        name="auctionParameterRealEstateCommercial.costOfLiving"
        onChange={props.handleChange}
        value={props.values.auctionParameterRealEstateCommercial.costOfLiving}
        dataTestId="auctionParameterRealEstateCommercial.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">Zařízeno</Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateFurnished)
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
