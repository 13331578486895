import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { useAdministratorApi } from '@api/administrator';
import { useAllowedUserRoles, useAuthStore, usePageStore } from '@stores';
import * as routes from '@routes';
import { Select, BasePreloader, ControlFeedback, FormGroup, Button, useVisibility } from '@components';
import { AuctioneerType, PermissionGroup, UserPermission } from '@types';
import { useAuthApi } from '@api/auth';
import { useAuctioneersApi } from '@api/auctioneers';

interface Props {
  id?: number;
}

interface TypeMultiSelectOptionType {
  value: string;
  label: string;
}

const AdministratorForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const readOnly = !useVisibility().shouldBeVisible({
    permissionGroupName: PermissionGroup.administrator,
    permissionName: props.id ? UserPermission.canEdit : UserPermission.canCreate,
  });
  const authApi = useAuthApi();
  const pageState = usePageStore();
  const authState = useAuthStore();
  const administratorApi = useAdministratorApi();
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(true);
  const [auctioneerTypes, setAuctioneerTypes] = useState([] as Array<AuctioneerType>);
  const { allowedUserRoles } = useAllowedUserRoles('admin');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      degreePre: '',
      degreePost: '',
      email: '',
      role: '',
      contact: false,
      buyerDocumentNotification: false,
      userContactTypes: [] as string[],
      phone: '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    if (props.id) {
      loadAdministrator(props.id);
    }
    return () => {
      administratorApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  React.useEffect(() => {
    if (/^\s*$/.test(formik.values.role) && allowedUserRoles.length > 0) {
      formik.setFieldValue('role', allowedUserRoles[0].type);
    }
  }, [formik, allowedUserRoles]);

  React.useEffect(() => {
    if (formik.values.contact) {
      loadAuctioneerTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.contact]);

  const loadAdministrator = (id: number) => {
    setLoaded(false);
    administratorApi
      .detail(id)
      .then((res) => {
        const data = _.pick(res.data.data, [
          'firstName',
          'lastName',
          'email',
          'role',
          'degreePre',
          'degreePost',
          'userContactTypes',
          'buyerDocumentNotification',
          'phone',
          'contact',
        ]);
        const contactTypes = data.userContactTypes.map((type) => type.type);
        formik.setValues({ ...data, userContactTypes: contactTypes });
        setLoaded(true);
      })
      .catch((err) => {
        if (administratorApi.isCancel(err)) {
          return;
        }
      });
  };

  const loadAuctioneerTypes = async () => {
    try {
      const response = await auctioneersApi.types();
      setAuctioneerTypes(response.data.data);
    } catch (err: any) {
      if (!err.response) {
        return;
      }
    }
  };

  const getAdministratorRolesOptions = () => {
    return allowedUserRoles.map((i) => ({ label: i.translated, value: i.type }));
  };

  const handleRolesChange = (value: TypeMultiSelectOptionType) => {
    if (value?.value) {
      formik.setFieldValue('role', value.value);
      return;
    }

    formik.setFieldValue('role', null);
  };

  const handleSubmit = (values: FormikValues) => {
    values.phone = values.phone?.replaceAll(' ', '');
    let inputs = values;
    if (!inputs.contact) {
      delete inputs.callCenterType;
    }
    if (inputs.contact && inputs.userContactTypes.length > 0) {
      const contactTypes = inputs.userContactTypes.map((type: string) => ({ type: type }));
      inputs = { ...inputs, userContactTypes: contactTypes };
    } else {
      inputs = { ...inputs, userContactTypes: undefined };
    }
    (!!props.id ? administratorApi.update(props.id as number, inputs) : administratorApi.create(inputs))
      .then(async () => {
        formik.setSubmitting(false);
        try {
          await authApi.logged();
          if (!props.id) {
            history.push(pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS));
          }
        } catch (err: any) {
          authState.unsetUser();
          history.push(pageState.getPagePath(routes.auth.LOGIN));
        }
      })
      .catch((err) => {
        if (administratorApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      {loaded ? (
        <>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-0">
            <div className="responsive-table-content">
              <div>
                <FormGroup
                  type="text"
                  name="firstName"
                  label="Jméno"
                  value={formik.values.firstName}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.firstName}
                  readOnly={readOnly}
                  required
                />
                <FormGroup
                  type="text"
                  name="lastName"
                  label="Příjmení"
                  value={formik.values.lastName}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.lastName}
                  readOnly={readOnly}
                  required
                />
                <FormGroup
                  type="text"
                  name="degreePre"
                  label="Titul před jménem"
                  value={formik.values.degreePre}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.degreePre}
                  readOnly={readOnly}
                />
                <FormGroup
                  type="text"
                  name="degreePost"
                  label="Titul za jménem"
                  value={formik.values.degreePost}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.degreePost}
                  readOnly={readOnly}
                />
                <FormGroup
                  type="text"
                  name="email"
                  label="E-mail"
                  value={formik.values.email}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  readOnly={readOnly}
                  required
                />
                <FormGroup
                  type="text"
                  name="phone"
                  label="Telefon"
                  value={formik.values.phone}
                  labelClassName="text-left"
                  onChange={formik.handleChange}
                  error={formik.errors.phone}
                  readOnly={readOnly}
                  required={formik.values.role === 'ROLE_CALL_CENTER' || formik.values.contact}
                />
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">Oprávnění *</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500" data-test-id="admin-settings-administrator-form-role">
                      <Select
                        isInvalid={!!formik.errors.role}
                        size="md"
                        options={getAdministratorRolesOptions()}
                        value={getAdministratorRolesOptions().find(({ value }) => formik.values.role === value) || null}
                        onChange={handleRolesChange as any}
                        readOnly={readOnly}
                      />
                      {!!formik.errors.role && <ControlFeedback type="invalid">{formik.errors.role}</ControlFeedback>}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group mt-4 mb-4">
                  <Form.Label className="f-inline-label text-left w-auto">
                    Kontakt pro schválení dokladů
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="buyerDocumentNotification"
                    name="buyerDocumentNotification"
                    className="auction-switch"
                    checked={formik.values.buyerDocumentNotification}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                <Form.Group className="f-inline-group mt-4 mb-4">
                  <Form.Label className="f-inline-label text-left w-auto">Kontakní osoba</Form.Label>
                  <Form.Check
                    type="switch"
                    id="contact"
                    name="contact"
                    className="auction-switch"
                    checked={formik.values.contact}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
                {formik.values.contact && (
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">Typ administrátora *</Form.Label>
                    {auctioneerTypes.map((auctioneerType) => (
                      <Form.Check
                        key={auctioneerType.type}
                        custom
                        type="checkbox"
                        label={auctioneerType.translated}
                        className="mt-checkbox mr-4 mb-3"
                        id={auctioneerType.type}
                        name="[userContactTypes]"
                        value={auctioneerType.type}
                        checked={formik.values.userContactTypes.includes(auctioneerType.type) || false}
                        onChange={formik.handleChange}
                      />
                    ))}
                  </Form.Group>
                )}
              </div>
            </div>
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!formik.isSubmitting ? (
                  <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                    Uložit
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default AdministratorForm;
