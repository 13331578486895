import React from 'react';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import SidebarMenuItem from './SidebarMenuItem';

import auctioneerIco from '@assets/images/admin-sidebar/auctioneer.svg';
import auctioneerIcoActive from '@assets/images/admin-sidebar/auctioneer-active.svg';
import companyIco from '@assets/images/admin-sidebar/company.svg';
import companyIcoActive from '@assets/images/admin-sidebar/company-active.svg';
import gavelIco from '@assets/images/admin-sidebar/gavel.svg';
import gavelIcoActive from '@assets/images/admin-sidebar/gavel-active.svg';
import invoiceIco from '@assets/images/admin-sidebar/invoice.svg';
import invoiceIcoActive from '@assets/images/admin-sidebar/invoice-active.svg';
import almostDoneIco from '@assets/images/admin-sidebar/almost-done.svg';
import almostDoneIcoActive from '@assets/images/admin-sidebar/almost-done-active.svg';
import confIco from '@assets/images/admin-sidebar/conf.svg';
import confIcoActive from '@assets/images/admin-sidebar/conf-active.svg';
import documentIco from '@assets/images/admin-sidebar/document.svg';
import documentIcoActive from '@assets/images/admin-sidebar/document-active.svg';
import logIco from '@assets/images/admin-sidebar/log.svg';
import logIcoActive from '@assets/images/admin-sidebar/log-active.svg';
import statisticIco from '@assets/images/admin-sidebar/statistic.svg';
import statisticIcoActive from '@assets/images/admin-sidebar/statistic-active.svg';
import usersIco from '@assets/images/admin-sidebar/users.svg';
import usersIcoActive from '@assets/images/admin-sidebar/users-active.svg';

const SidebarMenu: React.FC = () => {
  const pageState = usePageStore();

  const getIco = (route: string, active?: boolean): string => {
    switch (route) {
      case routes.admin.AUCTIONEER_LIST:
        return active ? auctioneerIcoActive : auctioneerIco;
      case routes.admin.MY_COMPANY_LIST:
        return active ? auctioneerIcoActive : auctioneerIco;
      case routes.admin.AUCTIONS_LIST:
        return active ? gavelIcoActive : gavelIco;
      case routes.admin.USER_LIST:
        return active ? companyIcoActive : companyIco;
      case routes.admin.INVOICES:
        return active ? invoiceIcoActive : invoiceIco;
      case routes.admin.CASES:
        return active ? almostDoneIcoActive : almostDoneIco;
      case routes.admin.DOCUMENTS:
        return active ? documentIcoActive : documentIco;
      case routes.admin.SETTINGS:
        return active ? confIcoActive : confIco;
      case routes.admin.LOG:
        return active ? logIcoActive : logIco;
      case routes.admin.STATISTICS:
        return active ? statisticIcoActive : statisticIco;
      case routes.admin.DOCUMENTS_AND_SECURITY:
        return active ? usersIcoActive : usersIco;
      default:
        return auctioneerIco;
    }
  };

  return (
    <ul className="sidebar-menu" data-test-id="admin-sidebar-menu">
      {pageState.getPagesByParentPageId(pageState.getPage(routes.admin.BASE)?.id || 0, true).map((page, index) => (
        <SidebarMenuItem
          key={`menu-item-${index}`}
          icon={getIco(page.routeName)}
          iconActive={getIco(page.routeName, true)}
          link={pageState.getPagePath(page.routeName)}
          active={pageState.page === page.routeName}
          testId={page.routeName}
        >
          {page.name}
        </SidebarMenuItem>
      ))}
    </ul>
  );
};

export default SidebarMenu;
