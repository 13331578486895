import React from 'react';
import classNames from 'classnames';

import { FrontTabsMenuItemType } from '@types';

interface Props {
  items: Array<FrontTabsMenuItemType>;
  onClick: (index: number) => void;
}

const FrontTabsMenu: React.FC<Props> = (props) => {
  const handleItemClick = (item: FrontTabsMenuItemType, index: number) => {
    if (!item.disabled) {
      props.onClick(index);
    }
  };

  return (
    <div className="component-front-tabs-menu">
      <ul>
        {props.items.map((item, index) => (
          <React.Fragment key={`menu-item-${index}`}>
            {!item.hide && (
              <li className={classNames({ 'is-active': item.isActive })}>
              <span
                onClick={() => handleItemClick(item, index)}
                className={classNames({ 'cursor-pointer': !item.disabled })}
              >
                {item.label}
                {item.count !== undefined && <small>({item.count})</small>}
              </span>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default FrontTabsMenu;
