import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { getName } from '@helpers/string';
import RejectedModal from './RejectedModal';
import { useDocumentsApi } from '@api/documents';
import { DocumentState, UserDocument } from '@types';

import infoColorIco from '@assets/images/info-color.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorDownload from '@assets/images/color-download.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';

interface Props {
  data: Array<UserDocument>;
  onApproveClick: (item: UserDocument) => void;
  onRejectClick: (item: UserDocument) => void;
}

const ItemsTable: React.FC<Props> = (props) => {
  const documentsApi = useDocumentsApi();
  const [reason, setReason] = React.useState<React.ReactNode | null>(null);

  const handleFileDownload = (e: React.MouseEvent<HTMLAnchorElement>, item: UserDocument) => {
    e.preventDefault();
    documentsApi
      .getUserDocument(item.id || '', item.user?.id || '')
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (documentsApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleApproveClick = (e: React.MouseEvent, item: UserDocument) => {
    e.preventDefault();
    props.onApproveClick(item);
  };

  const handleRejectClick = (e: React.MouseEvent, item: UserDocument) => {
    e.preventDefault();
    props.onRejectClick(item);
  };

  const renderStatus = (item: UserDocument) => {
    if (item.state === DocumentState.rejected) {
      return (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setReason(item.reason ? item.reason : <i>Bez udání důvodu</i>);
          }}
        >
          {item.translatedState}
          <img src={infoColorIco} alt="rejected" style={{ marginLeft: '8px' }} />
        </a>
      );
    }

    return item.translatedState;
  };

  return (
    <div className="responsive-table-content">
      <RejectedModal title="Důvod zamítnutí" isOpen={reason !== null} reason={reason} onClose={() => setReason(null)} />
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Jméno</th>
            <th>E-mail</th>
            <th>Platnost</th>
            <th>Stav</th>
            <th>Typ dokumentu</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`}>
              <td>{item.id}</td>
              <td>{getName(item.user) || '-'}</td>
              <td>{item.user?.email || '-'}</td>
              <td>{item.validity ? moment(item.validity).format('DD.MM.YYYY') : '-'}</td>
              <td>{renderStatus(item)}</td>
              <td>{item.translatedType || '-'}</td>
              <td className="text-right">
                <Link
                  to="#"
                  className="d-inline-flex align-items-center"
                  onClick={(event) => {
                    handleFileDownload(event, item);
                  }}
                >
                  <img src={colorDownload} alt="ico" className="mr-2" />
                  Stáhnout
                </Link>
                <Link
                  to="#"
                  className="d-inline-flex align-items-center ml-4"
                  onClick={(event) => {
                    handleApproveClick(event, item);
                  }}
                >
                  <img src={colorIcoCheck} alt="ico" className="mr-2" />
                  Schválit
                </Link>
                <Link
                  to="#"
                  className="d-inline-flex align-items-center ml-4"
                  onClick={(event) => {
                    handleRejectClick(event, item);
                  }}
                >
                  <img src={colorIcoDel} alt="ico" className="mr-2" />
                  Zamítnout
                </Link>
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;
