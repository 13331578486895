import React from 'react';
import Helmet from 'react-helmet';

type Props = {
  title?: string;
};

const SEO: React.FC<Props> = (props) => {
  const defaultDescription = "Exdrazby.cz je portál, který nabízí dražby a aukce nemovitostí a movitých věcí z celé České republiky.";

  return (
    <Helmet>
      <title>{props.title ? `Exdražby - ${props.title}` : 'Exdražby'}</title>
      <meta name="description" content={defaultDescription} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={window?.location.href} />
      <meta property="og:title" content={props.title || 'Exdražby'} />
      <meta property="og:description" content={`Exdražby - ${props.title}`} />
    </Helmet>
  );
};

export default SEO;
