import React from 'react';
import { ValueType } from 'react-select';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row, Table } from 'react-bootstrap';

import {
  AuctioneerUser,
  AuctioneerUserResponse,
  PermissionGroup,
  UserPermission,
  UserRoleInput,
} from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAllowedUserRoles } from '@stores/auth';
import { Select, BasePreloader, ControlFeedback, FormGroup, Button, Visible } from '@components';

import icoSend from '@assets/images/ico-send.svg';

interface Props {
  id: string;
  data?: AuctioneerUserResponse;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

interface RoleOptionType {
  value: string;
  label: string;
}

const UsersForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [restoreSaved, setRestoreSaved] = React.useState(true);
  const { allowedUserRoles } = useAllowedUserRoles('auctioneer');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: props.data?.user.firstName || '',
      lastName: props.data?.user.lastName || '',
      degreePre: props.data?.user.degreePre || '',
      degreePost: props.data?.user.degreePost || '',
      userFunction: props.data?.user.userFunction || '',
      phone: props.data?.user.phone || '',
      mobile: props.data?.user.mobile || '',
      email: props.data?.user.email || '',
      role: props.data?.user.role || '',
      mainEmail: props.data?.user.mainEmail || false,
      reportEmailWeekly: props.data?.user.reportEmailWeekly || false,
      reportEmailPortalDrazeb: props.data?.user.reportEmailPortalDrazeb || false,
      reportEmailSubscribe: props.data?.user.reportEmailSubscribe || false,
      reportEmailInvoice: props.data?.user.reportEmailInvoice || false,
      reportEmailHelpRequest: props.data?.user.reportEmailHelpRequest || false,
    },
    onSubmit: (values) => handleSubmit(values),
  });
  const permissions: Array<UserRoleInput> = [
    { label: 'Kontakt pro komunikaci', name: 'mainEmail', checked: formik.values.mainEmail },
    {
      label: 'E-mail pro zasílání kdo se přihlásil k dražbě s konvertovaným dokladem',
      name: 'reportEmailSubscribe',
      checked: formik.values.reportEmailSubscribe,
    },
    {
      label: 'E-mail pro zasílání faktur a vyúčtování',
      name: 'reportEmailInvoice',
      checked: formik.values.reportEmailInvoice,
    },
    {
      label: 'E-mail pro zasílání dotazu z portálu',
      name: 'reportEmailHelpRequest',
      checked: formik.values.reportEmailHelpRequest,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => auctioneersApi.cancelAllRequests(), []);

  const handleSubmit = (values: FormikValues) => {
    (!!props.data
      ? auctioneersApi.usersUpdate(props.id, props.data?.user.id.toString() || '', values as AuctioneerUser)
      : auctioneersApi.usersCreate(props.id, values as AuctioneerUser)
    )
      .then(() => {
        props.onSave();
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const getRoleOptions = () => {
    return allowedUserRoles.map((i) => ({ label: i.translated, value: i.type }));
  };

  const handleRoleChange = (value: ValueType<RoleOptionType, boolean>) => {
    const itemValue = value as RoleOptionType;
    formik.setFieldValue('role', itemValue?.value || '');
  };

  const handleRestoreClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setRestoreSaved(false);
    auctioneersApi
      .userRestore(props.id, (props.data?.user.id || 0).toString())
      .then(() => {
        setRestoreSaved(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        setRestoreSaved(true);
      });
  };

  return (
    <div>
      <Row>
        <Col xs={12} lg={6}>
          <h2>{props.data?.user.id ? 'Upravit uživatele' : 'Přidat uživatele'}</h2>
        </Col>
        <Visible
          or={[
            {
              permissionGroupName: PermissionGroup.auctioneerEmployees,
              permissionName: UserPermission.canCreate,
            },
            {
              permissionGroupName: PermissionGroup.myCompanyEmployees,
              permissionName: UserPermission.canCreate,
            },
          ]}
          disableSuperAdminOverride
        >
          {props.data?.user.id && props.data?.user.role !== 'ROLE_AUCTIONEER_CONTACT' && (
            <Col xs={12} lg={6} className="mt-3 mt-lg-0 text-left text-lg-right">
              {restoreSaved ? (
                <a href="/" onClick={handleRestoreClick}>
                  <img src={icoSend} alt="ico" className="mr-2" />
                  Znovu zaslat aktivační e-mail
                </a>
              ) : (
                <BasePreloader size={20} className="d-inline-block" />
              )}
            </Col>
          )}
        </Visible>
      </Row>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div className="responsive-table-content">
            <div>
              <FormGroup
                required
                type="text"
                name="firstName"
                label="Jméno"
                error={formik.errors.firstName}
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              <FormGroup
                required
                type="text"
                label="Příjmení"
                name="lastName"
                error={formik.errors.lastName}
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              <FormGroup
                type="text"
                label="Titul před jménem"
                name="degreePre"
                error={formik.errors.degreePre}
                value={formik.values.degreePre}
                onChange={formik.handleChange}
              />
              <FormGroup
                type="text"
                label="Titul za jménem"
                name="degreePost"
                error={formik.errors.degreePost}
                value={formik.values.degreePost}
                onChange={formik.handleChange}
              />
              <FormGroup
                type="text"
                label="Funkce"
                name="userFunction"
                error={formik.errors.userFunction}
                value={formik.values.userFunction}
                onChange={formik.handleChange}
              />
              <FormGroup
                type="text"
                label="Tel."
                name="phone"
                controlClassName="w-max-220"
                error={formik.errors.phone}
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <FormGroup
                type="text"
                label="Mobil"
                name="mobile"
                controlClassName="w-max-220"
                error={formik.errors.mobile}
                value={formik.values.mobile}
                onChange={formik.handleChange}
              />
              <FormGroup
                required
                type="email"
                label="E-mail"
                name="email"
                error={formik.errors.email}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Oprávnění *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500" data-test-id="admin-auctioneer-users-form-role">
                    <Select
                      isInvalid={!!formik.errors.role}
                      size="md"
                      options={getRoleOptions()}
                      value={getRoleOptions().find((i) => i.value === formik.values.role) || null}
                      onChange={handleRoleChange}
                    />
                    {!!formik.errors.role && <ControlFeedback type="invalid">{formik.errors.role}</ControlFeedback>}
                  </div>
                </div>
              </Form.Group>

              <div className="mt-4">
                <Table className="table-middle border-bottom-0" striped responsive>
                  <thead>
                    <tr className="b-bottom-1">
                      <th className="text-left">Adresát pro</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {permissions.map((permission, index) => (
                      <tr key={`permission-${index}`}>
                        <td>{permission.label}</td>
                        <td className="text-right">
                          <label className="d-inline-flex" data-test-id={`admin-auctioneer-users-form-permission-${permission.name}`}>
                            <span>Vybrat:</span>
                            <Form.Check
                              custom
                              type="checkbox"
                              label=""
                              className="ml-3"
                              id={permission.name}
                              name={permission.name}
                              checked={permission.checked}
                              onChange={formik.handleChange}
                            />
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit uživatele
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UsersForm;
