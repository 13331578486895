import * as React from 'react';
import fileDownload from 'js-file-download';
import { Table, Form } from 'react-bootstrap';

import icoSend2Color from '@assets/images/ico-send-2-color.svg';
import icoSend2White from '@assets/images/ico-send-2-white.svg';

import { useBuyerApi } from '@api/buyer';
import { useMediaApi } from '@api/media';
import { useAuctionsApi } from '@api/auctions';
import { getDateFormat } from '@helpers/datetime';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { BasePreloader, Button } from '@components';
import { UserDocument, UserAuctionAuthState, BuyerAuctionResponse, AuctionAuthorizationType } from '@types';

interface Props {
  setBuyer: (buyer: BuyerAuctionResponse) => void;
  buyerAuction: BuyerAuctionResponse;
  buyerId: string;
  auctionId: string;
  documents: UserDocument[];
  getTranslation: (key: string) => string;
}

const SecurityPanel: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const buyerApi = useBuyerApi();
  const mediaApi = useMediaApi();
  const [authState, setAuthState] = React.useState<UserAuctionAuthState>(UserAuctionAuthState.unauthorized);
  const [authTypes, setAuthTypes] = React.useState<AuctionAuthorizationType[]>([]);
  const [authTypesLoaded, setAuthTypesLoaded] = React.useState(false);
  const [rejectedReason, setRejectedReason] = React.useState('');
  const [rejectedReasonError, setRejectedReasonError] = React.useState('');
  const [authChangeSaved, setAuthChangeSaved] = React.useState(true);

  React.useEffect(() => {
    loadAuthTypes();
    setAuthState(props.buyerAuction.authorizationState as UserAuctionAuthState);
    setRejectedReason(props.buyerAuction.authorizationStateReason || '');
    return () => {
      buyerApi.cancelAllRequests();
      mediaApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuthTypes = async () => {
    try {
      const response = await auctionsApi.getAuthorizationType(props.auctionId);
      setAuthTypes(response.data.data);
      setAuthTypesLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleFileDownload = (e: React.MouseEvent<HTMLAnchorElement>, item: UserDocument) => {
    e.preventDefault();
    mediaApi
      .detail(item.media.hash, true)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (mediaApi.isCancel(err)) {
          return;
        }
      });
  };
  const handleAuthStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as UserAuctionAuthState;
    setAuthState(value);
    if (value === UserAuctionAuthState.rejected) {
      setTimeout(() => {
        document.getElementById('rejectedReason')?.focus();
      }, 200);
    }
  };

  const handleChangeAuthClick = async () => {
    setAuthChangeSaved(false);
    try {
      await buyerApi.changeAuctionAuthorization(props.buyerId, props.auctionId, {
        authorizationState: authState,
        authorizationStateReason: authState === UserAuctionAuthState.rejected ? rejectedReason : undefined,
      });
      props.setBuyer({ ...props.buyerAuction, authorizationState: authState });
      setRejectedReasonError('');
      if (authState !== UserAuctionAuthState.rejected) {
        setRejectedReason('');
      }
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      const reasonError = err.response?.data?.errors?.authorizationStateReason;
      setRejectedReasonError(!!reasonError ? reasonError[0] : '');
    } finally {
      setAuthChangeSaved(true);
    }
  };

  return (
    <div className="auction-panel-content pb-5">
      <div className="responsive-table-content">
        <Table style={{ borderBottom: 'none' }}>
          <thead style={{ borderBottom: '1px solid #E3E3E3' }}>
            <tr>
              <th className="text-left">No.</th>
              <th className="text-left">Typ dokumentu</th>
              <th className="text-left">Stav</th>
              <th className="text-center">Platnost</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.documents.map((item, index) => (
              <tr key={`item-${index}`}>
                <td className="text-left">{item.id}</td>
                <td className="text-left">{item.translatedType}</td>
                <td className="text-left">{item.translatedState}</td>
                <td className="text-center">{!!item.validity ? getDateFormat(item.validity) : '-'}</td>
                <td className="text-right">
                  <a
                    href="/"
                    className="f-size-12 f-weight-bold text-color-green"
                    onClick={(e) => handleFileDownload(e, item)}
                  >
                    stáhnout soubor
                  </a>
                </td>
              </tr>
            ))}
            {props.documents.length < 1 && (
              <tr>
                <td colSpan={100}>Nebyly nalezeny žádné dokumenty.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* TODO: Send email */}
      {false && (
        <div className="mt-4 d-flex flex-wrap align-items-center">
          <Button className="f-size-12 mr-4">
            <img src={icoSend2Color} alt="ico" className="mr-2 hover-hide" />
            <img src={icoSend2White} alt="ico" className="mr-2 hover-show" />
            Zaslat doklad exekutorovi
          </Button>
          {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
        </div>
      )}

      <div className="responsive-table-content">
        <div className="mt-3 mt-md-5">
          <h3 className="f-size-16 f-weight-600">{props.getTranslation('tab_security_title')}</h3>
          {authTypesLoaded ? (
            <div className="pl-1">
              {authTypes.map((item, index) => (
                <div className="mt-3" key={`auth-type-${index}`}>
                  <Form.Check
                    custom
                    type="radio"
                    id={`authorization${index}`}
                    name="authorization"
                    className="radio-point"
                    value={item.type}
                    label={item.translated}
                    checked={authState === item.type}
                    onChange={handleAuthStateChange}
                  />
                </div>
              ))}
              {authState === UserAuctionAuthState.rejected && (
                <div className="mt-2">
                  <Wysiwyg
                    type="normal"
                    name="rejectedReason"
                    value={rejectedReason}
                    error={rejectedReasonError}
                    placeholder="Důvod zamítnutí"
                    onChange={(val) => setRejectedReason(val)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="mt-3">
              <BasePreloader size={30} />
            </div>
          )}
        </div>

        {authTypesLoaded && (
          <div className="mt-30 d-flex flex-wrap align-items-center">
            {authChangeSaved ? (
              <>
                <Button type="button" className="f-size-12 mr-4" onClick={handleChangeAuthClick}>
                  <img src={icoSend2Color} alt="ico" className="mr-2 hover-hide" />
                  <img src={icoSend2White} alt="ico" className="mr-2 hover-show" />
                  Uložit a zaslat dražiteli informace o prokázání totožnosti
                </Button>
                {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
              </>
            ) : (
              <BasePreloader size={34} className="m-0" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecurityPanel;
